import React from 'react'
import { InputLabel, ModalButton, ModalContent, ModalHeader, ModalOverlay } from './Style'
import CircularProgress from "@mui/material/CircularProgress";

const PaymentDeductionModal = ({popup, handleAddClient, loading}) => {
  return (
    <ModalOverlay>
        <ModalContent>
            <ModalHeader>
                Payment Confirmation
            </ModalHeader>
            <InputLabel>
             You will be charged 15€ to add new user.
            </InputLabel>
            <div style={{display:'flex', alignItems:'center',justifyContent:'center', width:'100%', gap:'0.5rem'}}>
            <ModalButton onClick={()=>popup(false)}>
                Cancel
            </ModalButton>
            <ModalButton
                style={{ background: "#FF8900", color: "#FFF" }}        
                onClick={handleAddClient}
            >
            {loading ? (
              <CircularProgress size={21} color="inherit" />
            ) :(
              "Add"
            )}                
            </ModalButton>
            </div>
        </ModalContent>
    </ModalOverlay>
  )
}

export default PaymentDeductionModal
