import styled from "styled-components";

export const Container = styled.div`
  padding: 2rem;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  max-width: 2000px;
  width: 100%;
  border-radius: 1.5rem;
  background: #fff;
  margin-bottom: 0.5rem;
  scrollbar-width: none;
  @media (max-width: 600px) {
    padding: 0.4rem;
  }
`;

export const Header = styled.div`
margin-left: 
  margin-top: 5.12rem;
  align-items: center;
  @media (max-width: 840px) {
    margin-left: 0rem;
  }
`;

export const Title = styled.div`
  color: #202020;
  font-family: Poppins;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.5rem; /* 133.333% */
`;

export const ProfileCard = styled.div`
  display: flex;
  border-radius: 0.5rem;
  border: 1px solid #dcdbdd;
  padding: 1.75rem;
`;

export const SectionTitle = styled.div`
  color: #171725;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem; /* 137.5% */
`;

export const Label = styled.div`
  color: #696974;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
`;

export const Value = styled.div`
  color: #696974;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
`;

export const EditButton = styled.div`
  display: flex;
  width: 5rem;
  padding: 0.6875rem 0.5625rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.25rem;
  border: 1px solid #dcdbdd;
  background: #fff;

  color: #696974;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem; /* 150% */
`;

export const InputField = styled.input`
border-radius: 0.75rem;
border: 1px solid #DCDBDD;
background: #FFF;
padding: 0.94rem;
color: #84818A;
leading-trim: both;
text-edge: cap;
font-family: Roboto;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
width: 80%;
`;

