import React, { useState, useEffect } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { Container, AddButton, Header, CardInfo, CardDiv, PageHeader, Title } from "./Style";
import CircularProgress from "@mui/material/CircularProgress"; // Assuming you're using Material-UI for the loading spinner
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import VisaCard from "../../assets/images/visaCard-icon.svg";
import MasterCard from "../../assets/images/MasterCard.svg";
import Divider from "../../assets/images/divider.svg";
import SnackbarAlert from "../../components/Alerts/SnackbarAlert";
import { CardImgs } from "../../components/Constants/Constants";

// Initialize Stripe with your public key
const stripePromise = loadStripe(
  "pk_live_51NgPyEJ41ABvy6vn5DbNZUb6k9qYNS6dgCo820lzfqzrOWNqSliTfzoWc96VPvSYVp1s4XQVYYD6KTxhsKBijxqy00ly2MqgAr"
);

const AddCardWrapper = () => {
  const [key, setKey] = useState(0);

  return (
    <Elements stripe={stripePromise} key={key}>
      <AddCard setKey={setKey} />
    </Elements>
  );
};

const AddCard = ({ setKey }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [cardBrandImg, setCardBrandImg] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loadingCards, setLoadingCards] = useState(true);
  const [cardInfo, setCardInfo] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const fetchCards = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const response = await axios.get(
          "https://admin.dialect-ai.com/api/listcards",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (
          response.data &&
          response.data.information &&
          response.data.information.data.length > 0
        ) {
          setCardInfo(response.data.information.data[0]);
          const card = response.data.information.data[0];
          setCardBrandImg(CardImgs[card.card.brand]);
        }
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    } else {
      console.error("No token found in local storage.");
    }
    setLoadingCards(false);
  };

  useEffect(() => {
    fetchCards();
  }, []);

  const handlePayment = async () => {
    setLoading(true);
    const cardElement = elements.getElement(CardElement);
    const { token, error } = await stripe.createToken(cardElement);
    if (error) {
      console.error("Error creating token:", error);
      setSnackbarMessage("Failed to add card");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setLoading(false);
      return;
    } else {

      try {
        const response = await axios.post(
          "https://admin.dialect-ai.com/api/addcard",
          {
            stripeToken: token.id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response && response.data && response.data.data) {
          setSnackbarMessage("Card Added Successfully");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          setCardInfo(response.data.data);
          setKey((prevKey) => prevKey + 1); 
        } else {
          setSnackbarMessage("Failed to add card");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      } catch (error) {
        setSnackbarMessage("Failed to add card");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
      setLoading(false);
    }
  };

  return (
    <Container>
      <PageHeader>
        <Title>Payment</Title>
      </PageHeader>
      <div
        style={{
          width: "610px", 
          margin: "auto",
          borderRadius: "20px",
          boxShadow: "0px 10px 15px 0px rgba(209, 213, 223, 0.5)",
          padding: "32px 28px",
          inset: "40px",
          zIndex: 1000,
        }}
      >
        <div style={{ display: "flex" }}>
          <Header>{editMode ? "Enter Card Details" : "Card Details"}</Header>
          <div style={{ marginLeft: "auto" }}>
            <img src={VisaCard} alt="visa" style={{ height: "30px" }} />
            <img src={MasterCard} alt="Master" style={{ height: "30px" }} />
          </div>
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <img src={Divider} alt="divider" style={{ width: "100%" }} />
        </div>
        {loadingCards ? (
          <CircularProgress />
        ) : cardInfo && !editMode ? (
          <CardDiv>
            <img
              src={cardBrandImg}
              alt={cardInfo.card.brand}
              style={{ width: "4.625rem", height: "2.8125rem" }}
            />
            <CardInfo>**** **** **** {cardInfo.card.last4}</CardInfo>
            <CardInfo style={{ marginLeft: "auto" }}>
              {cardInfo.card.exp_month} / {cardInfo.card.exp_year}
            </CardInfo>
          </CardDiv>
        ) : (
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "27px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
        )}
        <div
          style={{
            display: "flex",
            marginLeft: "auto",
            gap: "0.5rem",
            justifyContent: "end",
            marginTop: "2rem",
          }}
        >
          {(!cardInfo || editMode) && (
            <AddButton onClick={handlePayment}>
              {!loading ? (
                "Add Card"
              ) : (
                <CircularProgress size={21} color="inherit" />
              )}
            </AddButton>
          )}
          {cardInfo && (
            <AddButton onClick={() => setEditMode(!editMode)}>
              {editMode ? "Cancel" : "Edit"}
            </AddButton>
          )}
        </div>
      </div>
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Container>
  );
};

export default AddCardWrapper;
