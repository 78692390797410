import React, { useState } from "react";
import axios from "axios";
import { ModalOverlay, ModalContent, ModalHeader, ModalButton } from "./Style";
import CircularProgress from "@mui/material/CircularProgress";
import { useUsers } from "../../Pages/LandingPage/LandingPage";


const DeleteModal = ({ closeModal, closeSettingModal, user }) => {
  const [loading, setLoading] = useState(false);
  const {fetchUsers} = useUsers();

  const handleDelete = async () => {
    const token = localStorage.getItem("token");
    const user_id = user.id;
    setLoading(true); // Start loading

    try {
      await axios.get(
        `https://admin.dialect-ai.com/api/labeldeleteuser?user_id=${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      closeModal();
      closeSettingModal();
      fetchUsers();
      // Add any additional actions like removing the user from the UI
    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <ModalOverlay>
      <ModalContent style={{ width: "35rem", alignItems: "center" }}>
        <ModalHeader>Are you sure you want to delete this Client?</ModalHeader>
        <div style={{ display: "flex", gap: "1rem" }}>
          <ModalButton onClick={closeModal} disabled={loading}>
            Cancel
          </ModalButton>
          <ModalButton
            onClick={handleDelete}
            style={{ background: "#1674FE", color: "#FFF" }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={21} color="inherit" />
            ) : (
              "Delete"
            )}
          </ModalButton>
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};

export default DeleteModal;
