import React from 'react'
import { CardOuterDiv, NotesDescription, NotesHeader, NotesImage } from './Style'
import Image from '../../assets/images/NotesCardImage.svg'
import { useNavigate } from "react-router-dom";

const NotesCard = ({id ,heading = 'Lorem Ipsum', desc = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus, elit nibh et nisl, pellentesque scelerisque faucibus facilisis at. Placerat morbi sem viverr'}) => {
  const navigate = useNavigate();

  const stripHtmlAndTruncate = (htmlString, maxLength = 35) => {
    const plainText = htmlString.replace(/<[^>]+>/g, '');  // Remove HTML tags
    return plainText.length > maxLength ? `${plainText.slice(0, maxLength)}...` : plainText;
  };

  return (
    <CardOuterDiv onClick={()=> navigate(`/show-notes/${id}`)}>
      <NotesImage alt='' src={Image} />
      <NotesHeader>{heading}</NotesHeader>
      <NotesDescription>{stripHtmlAndTruncate(desc)}</NotesDescription>
    </CardOuterDiv>
  );
}

export default NotesCard