import React, { useState } from "react";
import MeetingModal from "../Modals/MeetingModal";
import { Container, Header, Title } from "../../components/Agents/Style";
import { months, daysInMonth } from "../Constants/Calendar";
import { CalendarGrid, DateBox, DayTxt, MeetingItem, Ellipsis, MeetingTxt } from './Style';
import PrevIcon from "../../assets/images/prev.svg";
import NextIcon from "../../assets/images/next.svg";
import CommentIcon from "../../assets/images/comment.svg";

const Calendar = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [expandedDays, setExpandedDays] = useState({}); // To track expanded dates for meetings
  const [isMeetingOpen, setIsMeetingOpen] = useState(false);
  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  // Sample meetings data: Mapping dates to meeting arrays
  const meetings = {
    5: ["Meeting 1", "Meeting 2", "Meeting 3", "Meeting 4", "Meeting 4", "Meeting 4", "Meeting 4"],
    10: ["Meeting 1", "Meeting 2"],
    15: ["Meeting 1", "Meeting 2", "Meeting 3", "Meeting 4"],
  };

  const nextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const prevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const toggleExpand = (day) => {
    setExpandedDays((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };

  const getDaysArray = (month, year) => {
    const daysInCurrentMonth = daysInMonth[month];
    const firstDay = new Date(year, month, 1).getDay();
    const daysArray = [];

    // Fill the first row with day names and corresponding dates (if any)
    for (let i = 0; i < 7; i++) {
      const date = i >= firstDay ? i - firstDay + 1 : null;
      daysArray.push({ dayName: dayNames[i], date: date });
    }

    // Fill the rest of the calendar grid with remaining dates
    for (let day = 8 - firstDay; day <= daysInCurrentMonth; day++) {
      daysArray.push({ dayName: null, date: day });
    }

    // Fill the remaining empty boxes to keep it a 7x5 grid
    while (daysArray.length < 35) {
      daysArray.push({ dayName: null, date: null });
    }

    return daysArray;
  };
  const daysArray = getDaysArray(currentMonth, currentYear);
  const closeMeetingModal = () => setIsMeetingOpen(false);
  const openMeetingModal = () => setIsMeetingOpen(true);

  return (
    <Container style={{ maxHeight: "calc(100vh - 78px)", overflowY: "auto" }} >
      <Header style={{ marginBottom: "1.25rem"}}>
        <div style={{display: "flex", gap: "1.13rem"}}>
          <img src={PrevIcon} alt="previous" onClick={prevMonth} />
          <img src={NextIcon} alt="next" onClick={nextMonth} />
          <Title>{months[currentMonth]} {currentYear}</Title>
        </div>
      </Header>
      <CalendarGrid>
        {/* Render the days with both day names and dates */}
        {daysArray.map((dayObj, index) => {
          const dayMeetings = meetings[dayObj.date] || [];
          const isExpanded = expandedDays[dayObj.date] || false;
          const visibleMeetings = isExpanded ? dayMeetings : dayMeetings.slice(0, 3);

          return (
            <DateBox key={index}>
              {dayObj.dayName && <span><DayTxt>{dayObj.dayName}</DayTxt></span>}
              {dayObj.date && <span>{dayObj.date}</span>}
              {visibleMeetings.map((meeting, idx) => (
                <MeetingItem key={idx}  onClick={() => openMeetingModal()} style={{cursor: "pointer"}}>{meeting}
                  <div style={{display: "flex", marginLeft: "auto", gap: "0.25rem"}}>
                    <img src={CommentIcon} alt="comments" />
                    <MeetingTxt>6</MeetingTxt>
                  </div>
                </MeetingItem>
              ))}
              {dayMeetings.length > 3 && !isExpanded && (
                <Ellipsis onClick={() => toggleExpand(dayObj.date)}>...</Ellipsis>
              )}
              {isExpanded && dayMeetings.length > 3 && (
                <Ellipsis onClick={() => toggleExpand(dayObj.date)}>Show less</Ellipsis>
              )}
            </DateBox>
          );
        })}
      </CalendarGrid>
      {isMeetingOpen && (
        <MeetingModal closeModal={closeMeetingModal} />
      )}
    </Container>
  );
};

export default Calendar;
