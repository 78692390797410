import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ModalOverlay,
  ModalContent,
  Title,
  SubText,
  SubTitle,
  SettingInput,
  SettingButton,
} from "./Style";
import CloseX from "../../assets/images/x-close.svg";
import DeleteModal from "../Modals/DeleteModal";
import CircularProgress from "@mui/material/CircularProgress";
import SnackbarAlert from "../../components/Alerts/SnackbarAlert";
import { useUsers } from "../../Pages/LandingPage/LandingPage";

const SettingsModal = ({ user, closeSettingModal }) => {
  const [userToDelete, setUserToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [initialName, setInitialName] = useState('');
  const [initialCompanyName, setInitialCompanyName] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const {fetchUsers} = useUsers();

  useEffect(() => {
    if (user) {
      setName(user.name);
      setCompanyName(user.company_name);
      setInitialName(user.name);
      setInitialCompanyName(user.company_name);
    }
  }, [user]);

  const openDeleteModal = (user) => {
    setUserToDelete(user);
    setIsDeleteModalOpen(true);
  };
  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  const handleSave = async () => {
    setIsLoading(true);
    const token = localStorage.getItem('token');
    let isUpdated = false;

    try {
      // Update personal info if name or companyName has changed
      if (name !== initialName || companyName !== initialCompanyName) {
        const payload = {
          personal_info_json: user.personal_info_json,
          name,
          company_name: companyName,
          user_id: user.id,
        };

        const response = await axios.post(
          'https://admin.dialect-ai.com/api/labelsetUserPersonalInfoJson',
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log('Personal Info API Response:', response.data);
        isUpdated = true;
      }

      // Update password if newPassword and confirmPassword have been entered
      if (newPassword && confirmPassword) {
        if (newPassword !== confirmPassword) {
          setSnackbarMessage("New and Confirm Passwords do not match");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setIsLoading(false);
          return;
        }

        const passwordPayload = {
          user_id: user.id,
          password: newPassword,
        };

        const passwordResponse = await axios.get(
          'https://admin.dialect-ai.com/api/labelchangeuserpassword',
          {
            params: passwordPayload,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log('Password API Response:', passwordResponse.data);
        isUpdated = true;
      }

      if (isUpdated) {
        setSnackbarMessage("Information Updated");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setIsLoading(false);
        setTimeout(() => {
          closeSettingModal();
          fetchUsers();
        }, 2000);
      } else {
        setSnackbarMessage("Nothing to save");
        setSnackbarSeverity("info");
        setSnackbarOpen(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error updating user info:', error);
      setSnackbarMessage(error.response?.data?.message || "Error updating information");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setIsLoading(false);
    }
  };

  return (
    <ModalOverlay>
      <ModalContent style={{ width: "38rem", padding: "1.5rem", gap: "0rem" }}>
        <div style={{ display: "flex", width: "100%" }}>
          <Title>Settings</Title>
          <img src={CloseX} alt="close" style={{ marginLeft: "auto", cursor:'pointer' }} onClick={closeSettingModal}/>
        </div>
        <SubText>Access all your settings here</SubText>
        <SubTitle style={{ marginTop: "1.5rem" }}>Personal Info</SubTitle>
        <div style={{ display: "flex", marginTop: "0.75rem", width: '100%', gap: '1rem'}}>
          <div style={{ display: "flex", flexDirection: "column", gap: '0.375rem', width: '50%'}}>
            <SubTitle style={{ lineHeight: "1.5rem", fontSize: "0.875rem" }}>Name</SubTitle>
            <SettingInput 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
              type="text" 
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: '0.375rem', width: '50%'}}>
            <SubTitle style={{ lineHeight: "1.5rem", fontSize: "0.875rem" }}>Company</SubTitle>
            <SettingInput 
              value={companyName} 
              onChange={(e) => setCompanyName(e.target.value)} 
              type="text" 
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: '0.375rem', width: '100%', marginTop:'0.75rem'}}>
          <SubTitle style={{ lineHeight: "1.5rem", fontSize: "0.875rem" }}>Email</SubTitle>
          <SettingInput 
            value={user.email} 
            type="text" 
            readOnly 
          />
        </div>
        <SubTitle style={{ marginTop: "1.5rem" }}>Password</SubTitle>
        <div style={{ display: "flex", marginTop: "0.75rem", width: '100%', gap: '1rem'}}>
          <div style={{ display: "flex", flexDirection: "column", gap: '0.375rem', width: '50%'}}>
            <SubTitle style={{ lineHeight: "1.5rem", fontSize: "0.875rem" }}>New Password</SubTitle>
            <SettingInput 
              value={newPassword} 
              onChange={(e) => setNewPassword(e.target.value)} 
              placeholder="*******" 
              type="password" 
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: '0.375rem', width: '50%'}}>
            <SubTitle style={{ lineHeight: "1.5rem", fontSize: "0.875rem" }}>Confirm Password</SubTitle>
            <SettingInput 
              value={confirmPassword} 
              onChange={(e) => setConfirmPassword(e.target.value)} 
              placeholder="*******" 
              type="password"
            />
          </div>
        </div>
        <div style={{display: 'flex', gap: '0.75rem', marginTop: '1.5rem', marginLeft: 'auto'}}>
          <SettingButton onClick={() => openDeleteModal(user)}>Delete</SettingButton>
          <SettingButton 
            style={{background:'#1364FE', border:'1px solid #1364FE', position: 'relative'}} 
            onClick={handleSave}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Save'}
          </SettingButton>
        </div>
      </ModalContent>
      {isDeleteModalOpen && (
        <DeleteModal closeModal={closeDeleteModal} user={userToDelete} closeSettingModal={closeSettingModal} />
      )}
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </ModalOverlay>
  );
};

export default SettingsModal;
