import React, { useState, forwardRef } from 'react';
import axios from 'axios';
import s3 from "../../components/Environment/Asconfig";
import { Container, DateInput, Header, InputContainer, RunButton, SubHeader, Input, InputDiv, FileDiv, FileTxt, KeywordInput, KeywordDiv } from "./Style";
import { SelectField, DropdownMenu, DropdownItem } from "../../Pages/Avatar/Style";
import SnackbarAlert from '../Alerts/SnackbarAlert';
import CircularProgress from '@mui/material/CircularProgress';
import Dropdown from "../../assets/images/dropdown.svg";
import UploadIcon from "../../assets/images/upload-white.svg";
import FileIcon from "../../assets/images/file.svg";
import VideoIcon from "../../assets/images/video.svg";
import CloseX from "../../assets/images/x-close.svg";
import Plus from "../../assets/images/plus-grey.svg";
import Minus from "../../assets/images/minus-grey.svg";
import { BLOG_POST_GENERATION, SEO_CHECK, SOCIAL_MEDIA_COPY_CREATION, WEBPAGE_COPY_GENERATION } from '../../Pages/Automations/constants';

const BlogPostSidebar = forwardRef(({ setSidebarVisible, workflow_id, fetchRecords, cardTitle, cardText, plainText }, ref) => {
  const [input1, setInput1] = useState(plainText);
  const [input2, setInput2] = useState('');
  const [publishDate, setPublishDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [instructionLoading, setInstructionLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [userInstructions, setUserInstructions] = useState(''); 
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [pageName, setPageName] = useState(''); 
  const [keywords, setKeywords] = useState([]); 
  const [currentKeyword, setCurrentKeyword] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (workflow_id === SEO_CHECK) {
      if (name === 'input1') {
        setInput1(value);
        if (value) setInput2('');
      } else if (name === 'input2') {
        setInput2(value);
        if (value) setInput1('');
      }
    } else {
      if (name === 'input1') setInput1(value);
    }
  };

  const handleDateChange = (e) => {
    setPublishDate(e.target.value);
  };

  const handleDateBlur = (e) => {
    const enteredDate = e.target.value;
    const today = new Date().toISOString().split("T")[0];
    
    // Check if the date is in "YYYY-MM-DD" format with exactly four digits for the year
    if (/^\d{4}-\d{2}-\d{2}$/.test(enteredDate)) {
      const year = parseInt(enteredDate.split("-")[0], 10);
  
      // Ensure the year has exactly four digits and is valid
      if (year < 1000 || year > 9999) {
        setSnackbarMessage("Please enter a valid year with four digits");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setPublishDate(""); 
        e.target.value = "";
      } else if (enteredDate < today) {
        setSnackbarMessage("Enter a date from today onward");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setPublishDate(""); 
        e.target.value = "";
      } else {
        setPublishDate(enteredDate); 
      }
    } else {
      setSnackbarMessage("Invalid Date");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setPublishDate(""); 
      e.target.value = "";
    }
  };
  
  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    setDropdownOpen(false);
  };

  const handlePlatformSelect = async (platform) => {
    setInstructionLoading(true);
    setSelectedPlatform(platform);
    setDropdownOpen(false);

    const selectedUserId = localStorage.getItem("selectedUserId");
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`https://admin.dialect-ai.com/api/getprefs?client_id=${selectedUserId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      const fetchedPreferences = response.data.preferences;
      // Find and set instructions for the selected platform
      const platformPrefs = fetchedPreferences.find(p => p.social_media === platform);
      if (platformPrefs) {
        setUserInstructions(platformPrefs.instructions);
      } else {
        setUserInstructions(''); // Clear if no matching preference found
      }
    } catch (error) {
      console.error("Error fetching preferences:", error);
      setSnackbarMessage("Failed to fetch preferences");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setInstructionLoading(false);
    }
  };

  const uploadFileToS3 = async (file) => {
    const selectedUserId = localStorage.getItem("selectedUserId");
    const folderName = `${selectedUserId}/documents`;
    const key = `${folderName}/${file.name}`;
    const params = {
      Bucket: "dialect-marketing-userdata",
      Key: key,
      Body: file,
    };
    const data = await s3.upload(params).promise();
    console.log("data", data.Location);
    return data.Location;
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files); // Convert file list to array
    setUploadedImages(files); // Set multiple images
  };

  const handleVideoUpload = (e) => {
    const file = e.target.files[0]; // Only take the first file
    setUploadedVideo(file); // Set the single video file
  };

  const removeImage = (index) => {
    const updatedImages = [...uploadedImages];
    updatedImages.splice(index, 1);
    setUploadedImages(updatedImages);
  };

  const removeVideo = () => {
    setUploadedVideo(null); // Clear the uploaded video
  };

  const handleKeywordInputChange = (e) => {
    setCurrentKeyword(e.target.value);
  };

  const addKeyword = () => {
    if (currentKeyword.trim() !== '') {
      setKeywords([...keywords, currentKeyword.trim()]);
      setCurrentKeyword('');
    }
  };

  const removeKeyword = (indexToRemove) => {
    setKeywords(keywords.filter((_, index) => index !== indexToRemove));
  };

  const handleRunAutomation = async () => {
    const selectedUserId = localStorage.getItem("selectedUserId");
    setLoading(true);
    if (workflow_id === SOCIAL_MEDIA_COPY_CREATION && !selectedPlatform) {
      setSnackbarMessage("Select a platform");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setLoading(false);
      return;
    }

    if (workflow_id === WEBPAGE_COPY_GENERATION && !pageName) {
      setSnackbarMessage("Enter Page Name");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setLoading(false);
      return;
    }

    if (!input1 && !input2) {
      setSnackbarMessage("Enter some content/instructions");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setLoading(false);
      return;
    }

    let message = "";
    if (workflow_id === SEO_CHECK) {
      if (input1) {
        message = `Perform SEO Analysis for the following content: ${input1}`;
      } else if (input2) {
        message = `Perform SEO Analysis for the following URL: ${input2}`;
      }
    } else {
      message = input1;
    }

    if (selectedLanguage) {
      message += ` The response should be in ${selectedLanguage}.`;
    }

    if (workflow_id === SOCIAL_MEDIA_COPY_CREATION) {
      message += ` Generate the content for the following platform: ${selectedPlatform}. Also follow these instructions: ${userInstructions}`;
    }

    if (workflow_id === WEBPAGE_COPY_GENERATION) {
      const keywordsString = keywords.join(' - ');
      message = `Page Name: ${pageName}. Instructions: ${message} Keywords: - ${keywordsString}`;
    }
    
    const payload = {
      node_id: "",
      workflow_id,
      message,
      type: workflow_id,
      client_id: selectedUserId,
    };

    if (publishDate) {
      payload.publish_date = publishDate;
    }

    if (uploadedVideo) {
      try {
        const s3Url = await uploadFileToS3(uploadedVideo);
        payload.videos_link = s3Url;
      } catch (error) {
        console.error(`Error uploading:`, error);
      }
    }

    if (uploadedImages.length > 0) {
      const imageLinks = [];
      for (const file of uploadedImages) {
        try {
          const s3Url = await uploadFileToS3(file); // Upload each image
          imageLinks.push(s3Url); // Push the S3 URL to the array
        } catch (error) {
          console.error("Error uploading image:", file.name, error);
        }
      }
      payload.media_attach = imageLinks; // Add array of image URLs to payload
    }
    
    if (workflow_id === SOCIAL_MEDIA_COPY_CREATION) {
      payload.social_media = selectedPlatform;
      payload.instructions = userInstructions;
    }

    const token = localStorage.getItem('token');
    try {
      const response = await axios.post('https://admin.dialect-ai.com/api/createautomation', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSnackbarMessage(response.data.data.data || "Automation ran successfully");
      setSnackbarSeverity("success");
      setTimeout(() => {
        setSidebarVisible(false);
      }, 1000);
    } catch (error) {
      setSnackbarMessage("Failed to run automation");
      setSnackbarSeverity("error");
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
      fetchRecords();
    }
  };

  return (
    <Container ref={ref} style={{borderLeft: "1px solid #EDEDED", overflowY: "auto", scrollbarWidth: "none"}}>
      <Header>{cardTitle}</Header>
      <SubHeader>{cardText}</SubHeader>

      {(workflow_id === BLOG_POST_GENERATION || workflow_id === SOCIAL_MEDIA_COPY_CREATION) && (
        <DateInput type="text" placeholder="Set Publish Date" onChange={handleDateChange} onBlur={handleDateBlur} onFocus={(e) => (e.target.type = "date")} min={new Date().toISOString().split("T")[0]} disabled={loading}/>
      )}

      {workflow_id === BLOG_POST_GENERATION && (
        <SelectField style={{ alignItems: "center", padding: "0.5rem 0.625rem", marginTop: "0.5rem", position: 'relative' }} onClick={() => { setDropdownOpen(!dropdownOpen) }}>
          <Input>{selectedLanguage || "Select Language"}</Input>
          <img src={Dropdown} alt="select" style={{ marginLeft: "auto" }} />
          {dropdownOpen && (
            <DropdownMenu>
              {["English", "French", "German", "Dutch"].map(language => (
                <DropdownItem key={language} onClick={() => handleLanguageSelect(language)}>
                  {language}
                </DropdownItem>
              ))}
            </DropdownMenu>
          )}
        </SelectField>
      )}

      {workflow_id === SOCIAL_MEDIA_COPY_CREATION && (
        <>
          <SelectField style={{ alignItems: "center", padding: "0.5rem 0.625rem", marginTop: "0.5rem", position: 'relative' }} onClick={() => { setDropdownOpen(!dropdownOpen) }}>
            <Input>{selectedPlatform || "Select Platform Type"}</Input>
            <img src={Dropdown} alt="select" style={{ marginLeft: "auto" }} />
            {dropdownOpen && (
              <DropdownMenu>
                {["Instagram", "Tiktok", "LinkedIn", "Pinterest"].map(platform => (
                  <DropdownItem key={platform} onClick={() => handlePlatformSelect(platform)}>
                    {platform}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            )}
          </SelectField>
          <InputContainer type="text" name="input3" placeholder="Enter Instructions" style={{ height: "20rem", minHeight: "5rem" }} value={instructionLoading ? "Loading..." : userInstructions} onChange={(e) => setUserInstructions(e.target.value)} />            
          <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
            <Header>Upload Images</Header>
            <RunButton style={{ marginLeft: "1rem", marginTop: "0rem", padding: "0.65rem 1rem" }} >
              <input type="file" multiple style={{ display: "none" }} id="image-upload" accept="image/*" onChange={handleImageUpload}/>
              <label htmlFor="image-upload" style={{ cursor: "pointer" }}>
                <img src={UploadIcon} alt="upload" /> Upload
              </label>
            </RunButton>
          </div>
          <InputDiv style={{ marginTop: "0.5rem", minHeight: uploadedImages?.length > 2 ? '12rem': uploadedImages?.length > 1 ? '8rem': uploadedImages?.length === 1 ? '5rem':'0rem', overflow:'auto'  }}>
            {uploadedImages.map((file, index) => (
              <FileDiv key={index}>
                <img src={FileIcon} alt="file" />
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <FileTxt>{file.name}</FileTxt>
                    <img src={CloseX} alt="Remove" style={{ cursor: "pointer" }} onClick={() => removeImage(index)} />
                  </div>
                </div>
              </FileDiv>
            ))}
          </InputDiv>
          <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
            <Header>Upload Videos&nbsp;&nbsp;</Header>
            <RunButton style={{ marginLeft: "1rem", marginTop: "0rem", padding: "0.65rem 1rem" }} id='2'>
              <input type="file" style={{ display: "none" }} id="video-upload" accept="video/*" onChange={handleVideoUpload} />
              <label htmlFor="video-upload" style={{ cursor: "pointer" }}>
                <img src={UploadIcon} alt="upload" /> Upload
              </label>
            </RunButton>
          </div>
          <InputDiv style={{ marginTop: "0.5rem" }}>
            {uploadedVideo && (
              <FileDiv>
                <img src={VideoIcon} alt="file" />
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <FileTxt>{uploadedVideo.name}</FileTxt>
                  </div>
                </div>
                <img src={CloseX} alt="Remove" style={{ cursor: "pointer" }} onClick={removeVideo} />
              </FileDiv>
            )}
          </InputDiv>
        </>
      )}
      {(workflow_id === WEBPAGE_COPY_GENERATION) && (
        <>
          <InputContainer type="text" name="input4" placeholder="Enter Page Name" style={{ height: "5rem", minHeight: "5rem" }} value={pageName} onChange={(e) => setPageName(e.target.value)} />        
          <div style={{display: "flex", marginTop: "0.5rem", gap: "1rem"}}>    
            <KeywordInput type="text" name="input5" placeholder="Enter Keyword" value={currentKeyword} onChange={handleKeywordInputChange}/>
            <img src={Plus} alt="Add" onClick={addKeyword} style={{cursor: 'pointer'}}/>
          </div>
          {keywords.map((keyword, index) => (
            <div key={index} style={{display: "flex", marginTop: "0.5rem", gap: "1rem"}}>    
              <KeywordDiv>{keyword}</KeywordDiv>
              <img src={Minus} alt="remove" onClick={() => removeKeyword(index)} style={{cursor: 'pointer'}} />
            </div>
          ))}
        </>
      )}
      <InputContainer type="text" name="input1" placeholder={workflow_id === WEBPAGE_COPY_GENERATION ? "Enter Instructions" : "Enter Content"} value={input1} onChange={handleInputChange} disabled={loading} style={{minHeight: "8rem"}}/>
      {(workflow_id === SEO_CHECK) && (
        <>
          <Header style={{ textAlign: "center", marginTop: "0.5rem" }}>OR</Header>
          <InputContainer type="text" name="input2" placeholder="Enter URL" value={input2} onChange={handleInputChange} disabled={loading}  style={{ height: "25rem" }}/>
        </>
      )}
      <RunButton onClick={handleRunAutomation} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : 'Run Automation'}
      </RunButton>
      <SnackbarAlert open={snackbarOpen} setOpen={setSnackbarOpen} message={snackbarMessage} severity={snackbarSeverity}/>
    </Container>
  );
});

export default BlogPostSidebar;
