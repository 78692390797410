import styled from "styled-components";

export const Container = styled.div`
  flex-direction: column;
  display: flex;
  max-width: 2000px;
  border-radius: 1.5rem;
  background: #fff;
  width: 100%;
  justify-content: center;
  margin-bottom: 0.5rem;
`;

export const PageHeader = styled.div`
  margin-top: 2rem;
  display: flex;
  margin-left: 3rem;
  gap: 1.13rem;
  @media (max-width: 840px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 760px) {
    margin-top: 1rem;
  }
  @media (max-width: 428px) {
    margin-top: 1rem;
    flex-direction: column;
  }
`;
export const Title = styled.div`
  color: #202020;
  font-family: Poppins;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.5rem; /* 133.333% */
  @media (max-width: 760px) {
    font-size: 1.25rem;
  }
`;

export const AddButton = styled.div`
  display: flex;
  padding: 0.625rem 3.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 40px;
  border: 1px solid rgba(136, 136, 136, 0.1);
  background: #FF8900;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const CardInfo = styled.div`
  color: #424770;
  font-family: sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2rem;
  opacity: 0.9;
`;

export const Header = styled.div`
  color: var(--Gray-900, #101828);

  /* heading-5 20px/The quick brown fox jumps over the lazy dog. */
  font-family: "Wix Madefor Text";
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem; /* 140% */
`;

export const CardDiv = styled.div`
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
  gap: 1.5rem;
`;
