import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Container, Header, Title } from "../../components/Agents/Style";
import { Content, CenterPane, ScrollableChat, QuestionTxt, SubHeading, ChatTxt, InputContainer, Input, LoadingText } from "./Style";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowUpIcon from "../../assets/images/arrow-up.svg";

const AvatarChat = () => {
  const location = useLocation();
  const { avatar_id } = location.state || {};
  const [chatHistory, setChatHistory] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const [historyLoading, setHistoryLoading] = useState(true);
  const [loading, setLoading] = useState(false); // Loading state for initial API call
  const chatEndRef = useRef(null);

  const handleSendMessage = async () => {
    const token = localStorage.getItem("token");
    if (userMessage.trim() === "") return;

    const updatedChatHistory = [ // Update chat history with user's message
      ...chatHistory,
      { role: "user", message: userMessage },
    ];
    const tempMsg = userMessage;
    setChatHistory(updatedChatHistory);
    setUserMessage("");
    setLoading(true);

    const response = await fetch(
      "https://flowproai.dialect-ai.com/chat_with_digital_avatar",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          messages: updatedChatHistory,
          avatar_id: avatar_id,
        }),
      }
    );
    const data = await response.json();

    setChatHistory((prevHistory) => [// Update chat history with assistant's response
      ...prevHistory,
      { role: "assistant", message: data.content },
    ]);
    setLoading(false);

    await fetch("https://admin.dialect-ai.com/api/savechatlabel", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        message: tempMsg,
        avatar_id: avatar_id,
        sender: 0,
      }),
    });

    await fetch("https://admin.dialect-ai.com/api/savechatlabel", { // Save assistant's response to the savechatlabel API
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        message: data.content,
        avatar_id: avatar_id,
        sender: 1,
      }),
    });
     // Clear input field
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  useEffect(() => {
    const fetchChatHistory = async () => {
      const token = localStorage.getItem("token");
      const response = await fetch("https://admin.dialect-ai.com/api/getchatlabel", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ avatar_id }),
      });
      const data = await response.json();
      const conversation = data.conversation.map((chat) => ({
        role: chat.sender === 0 ? "user" : "assistant",
        message: chat.message,
      }));
      setChatHistory(conversation);
      setHistoryLoading(false); // Stop loading after chat history is fetched
    };

    fetchChatHistory();
  }, [avatar_id]);

  useEffect(() => {// Scroll to the latest message when chatHistory updates
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatHistory]);

  return (
    <Container>
      <Header>
        <Title>Chat with Avatar</Title>
      </Header>
      <Content>
        {historyLoading ? (
          <CircularProgress style={{ margin: "auto" }} /> // Circular progress in the center
        ) : (
          <CenterPane>
            <ScrollableChat>
              {chatHistory.map((chat, index) => (
                <React.Fragment key={index}>
                  {chat.role === "user" ? (
                    <QuestionTxt>{chat.message}</QuestionTxt>
                  ) : (
                    <>
                      <SubHeading>Answer</SubHeading>
                      <ChatTxt>{chat.message}</ChatTxt>
                    </>
                  )}
                </React.Fragment>
              ))}
              <div ref={chatEndRef} />
              {loading && <LoadingText style={{fontSize: "0.875rem", marginBottom: "1rem", lineHeight: "1.5rem"}}/>}
            </ScrollableChat>
            <InputContainer>
              <Input
                type="text"
                placeholder="Ask a question"
                value={userMessage}
                onChange={(e) => setUserMessage(e.target.value)}
                onKeyDown={handleInputKeyDown}
              />
              <img
                src={ArrowUpIcon}
                alt="send message"
                style={{ marginLeft: "auto", cursor: "pointer" }}
                onClick={handleSendMessage}
              />
            </InputContainer>
          </CenterPane>
        )}
      </Content>
    </Container>
  );
};

export default AvatarChat;
