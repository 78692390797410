import React, { useState, useEffect } from "react";
import axios from "axios";
import SnackbarAlert from "../../components/Alerts/SnackbarAlert";
import {
  ModalButton,
  InputDiv,
  InputField,
  InputLabel,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "./Style";
import CircularProgress from "@mui/material/CircularProgress";
import { useUsers } from "../../Pages/LandingPage/LandingPage";
import PaymentDeductionModal from "./PaymentDeductionModal";

const ClientModal = ({ closeModal, user }) => {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const {fetchUsers} = useUsers();
  const [paymentDedcutionPopup,setPaymentDedcutionPopup] = useState(false);

  // Populate input fields with user data if editing a user
  useEffect(() => {
    if (user) {
      setName(user.name);
      setCompanyName(user.company_name);
      setEmail(user.email);
      setPassword("********");
    }
  }, [user]);

  const handleAddClient = async () => {
    const token = localStorage.getItem("token");
    const payload = {
      email,
      password,
      name,
      company_name: companyName,
    };

    setLoading(true); // Start loading

    try {
      const response = await axios.post(
        "https://admin.dialect-ai.com/api/adduser",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response && response.data && response.data.data.status === "202") {
        setSnackbarMessage("Client added successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true); // Show snackbar
        setTimeout(() => {
          closeModal();
          fetchUsers();
        }, 2000);
      } else {
        setSnackbarMessage("Failed to add client");
        setSnackbarSeverity("error");
        setSnackbarOpen(true); // Show snackbar
      }
    } catch (error) {
      console.error("Error adding client:", error);
      setSnackbarMessage("Error adding client");
      setSnackbarSeverity("error");
      setSnackbarOpen(true); // Show snackbar
    } finally {
      setLoading(false); // Stop loading
      setSnackbarOpen(true); // Show snackbar
    }
  };

  const handleEditClient = async () => {
    const token = localStorage.getItem("token");
    const payload = {
      personal_info_json: user.personal_info_json,
      name,
      company_name: companyName,
      user_id: user.id,
    };

    setLoading(true); // Start loading

    try {
      const response = await axios.post(
        "https://admin.dialect-ai.com/api/labelsetUserPersonalInfoJson",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response && response.data && response.data.data.logs === "200") {
        setSnackbarMessage("User personal info updated successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true); // Show snackbar
        setTimeout(() => {
          closeModal();
          fetchUsers();
        }, 2000);
      } else {
        setSnackbarMessage("Failed to update user info");
        setSnackbarSeverity("error");
        setSnackbarOpen(true); // Show snackbar
      }
    } catch (error) {
      console.error("Error updating user info:", error);
      setSnackbarMessage("Error updating user info");
      setSnackbarSeverity("error");
      setSnackbarOpen(true); // Show snackbar
    } finally {
      setLoading(false); // Stop loading
      setSnackbarOpen(true); // Show snackbar
    }
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>{user ? "Edit client" : "Add new client"}</ModalHeader>
        <InputDiv>
          <InputLabel>Name</InputLabel>
          <InputField
            placeholder="Enter name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <InputLabel>Company Name</InputLabel>
          <InputField
            placeholder="Company Name"
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
          <InputLabel>Email</InputLabel>
          <InputField
            placeholder="Email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Allow email to be editable
            readOnly={!!user} // Make email read-only if editing a user
          />
          {!user &&
          <>
            <InputLabel>Password</InputLabel>
            <InputField
              placeholder="********"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)} // Allow password to be editable
              readOnly={!!user} // Make password read-only if editing a user
            />
          </>
          }
        </InputDiv>
        <div style={{ display: "flex", gap: "0.75rem", alignSelf: "stretch" }}>
          <ModalButton onClick={closeModal} disabled={loading}>
            Cancel
          </ModalButton>
          <ModalButton
            onClick={user ? handleEditClient : ()=>{setPaymentDedcutionPopup(true)}}
            disabled={loading}
            style={{ background: "#FF8900", color: "#FFF" }}
          >
            {loading ? (
              user?
              <CircularProgress size={21} color="inherit" />
              :
              "Add"
            ) : user ? (
              "Edit"
            ) : (
              "Add"
            )}
          </ModalButton>
        </div>
      </ModalContent>
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      {paymentDedcutionPopup && 
        <PaymentDeductionModal popup={setPaymentDedcutionPopup} handleAddClient={handleAddClient} loading={loading}/>
      }
    </ModalOverlay>
  );
};

export default ClientModal;
