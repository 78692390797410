import React from "react";
import { CardContainer, CardText, Header, Number, Percentage } from "./Style";
import ThinArrow from "../../assets/images/chevron-down.svg";


const StatsCard = ({
  cardHeader = "Automations done",
  cardFooter = "vs preview 20 days",
  number = 10,
  percentage = 3,
  completed = 0,
  notCompleted = 0,
  totalUsers=0
}) => {
  const getNum = () => {
    if(cardHeader === 'Total Automations'){
      return completed;
    }else if(cardHeader === 'Automations in progress'){
      return notCompleted;
    }else if(cardHeader === 'Total Users'){
      return totalUsers;
    }else{
      return 6;
    }
  }
  return (
    <CardContainer style={{padding:'0rem', height:'11.5rem', width:'14.5rem'}}>
      <Header style={{marginTop:'1.37rem', marginLeft:'1.37rem'}}>
        <CardText>{cardHeader}</CardText>
        <img src={ThinArrow} alt="more" />
      </Header>
      <Number style={{marginLeft:'1.37rem'}}>{getNum()}</Number>
      <Percentage style={{marginLeft:'1.37rem'}}>+{percentage}%</Percentage>
      <CardText
        style={{
          fontSize: "0.75rem",
          letterSpacing: "-0.01375rem",
          marginTop: "0.47rem",
          marginLeft:'1.37rem'
        }}
      >
        {cardFooter}
      </CardText>
    </CardContainer>
  );
};

export default StatsCard;
