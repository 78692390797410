import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  width: 15.25rem;
  padding: 1.25rem 0rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.5625rem;
  border: 1px solid #e6e6e6;
  background: #fff;
  box-shadow: 0px 3px 20.1px 0px rgba(0, 0, 0, 0.06);
  @media (max-width: 600px) {
    width: 85%;
  }
`;

export const Title = styled.div`
  color: #202020;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem; /* 140% */
`;

export const CardText = styled.div`
  color: #202020;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 128.571% */
  margin-top: 0.5rem;
`;

export const TrainButton = styled.div`
  margin-top: 1.38rem;
  display: flex;
  padding: 0.5625rem 1.1875rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  border-radius: 0.5rem;
  background: #ff8900;
  color: var(--Neutral-10, #fff);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const TrainBtn = styled.div`
  margin-top: 1.38rem;
  display: flex;
  padding: 0.5625rem 1.1875rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  border-radius: 0.5rem;
  background: ${({ isTrained }) =>
    isTrained ? "#D9DCE3" : "#FF8900"}; /* Conditional background */
  color: var(--Neutral-10, #fff);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const ButtonImg = styled.img`
  @media (max-width: 600px) {
    display: none;
  }
`;

export const Tag = styled.div`
  margin-top: 0.3rem;
  display: flex;
  padding: 0.1875rem 0.5625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.125rem;
  background: #ff8900;
  color: var(--Brand-White, #fff);
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 133.333% */
`;

export const EmptyTag = styled.div`
  margin-top: 0.3rem;
  display: flex;
  padding: 0.1875rem 0.5625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.125rem;
  background: #fff;
  color: var(--Brand-White, #fff);
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 133.333% */
`;
