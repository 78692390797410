import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 1001;
`;

export const ModalContent = styled.div`
  display: flex;
  width: 32rem;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 0.75rem;
  background: var(--White, #fff);

  /* Shadow/xl */
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
`;

export const ModalHeader = styled.div`
  color: var(--Gray-900, #101828);

  /* heading-5 20px/The quick brown fox jumps over the lazy dog. */
  font-family: "Wix Madefor Text";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem; /* 140% */
`;
export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-self: stretch;
`;

export const InputLabel = styled.div`
  color: #202020;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const InputField = styled.input`
  border-radius: 0.75rem;
  border: 1px solid #dcdbdd;
  background: #fff;
  padding: 0.94rem;
  color: #84818a;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const TextAreaField = styled.textarea`
  border-radius: 0.75rem;
  border: 1px solid #dcdbdd;
  background: #fff;
  padding: 0.94rem;
  color: #84818a;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  resize: none;
  height: 20rem;
`;

export const ModalButton = styled.div`
  display: flex;
  padding: 0.625rem 1.125rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex: 1 0 0;
  border-radius: 0.5rem;
  border: 1px solid rgba(136, 136, 136, 0.1);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Grey-Text, #060608);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const DragAndDrop = styled.div`
  display: flex;
  padding: 3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: 1.5px dashed #2f80ed;
  background: rgba(47, 128, 237, 0.04);
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const IconDiv = styled.div`
  border-radius: 2.25rem;
  background: rgba(47, 128, 237, 0.08);
  display: inline-flex;
  padding: 0.38463rem;
  align-items: flex-start;
`;

export const UploadText = styled.div`
  color: #313133;
  text-align: center;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03rem;
  margin-top: 1rem;
`;

export const SupportText = styled.div`
  color: #8b9ab1;
  text-align: center;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.03rem;
`;

export const Title = styled.div`
  color: var(--Gray-900, #111928);
  text-align: center;
  font-family: "Wix Madefor Text";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem; /* 140% */
`;

export const SubText = styled.div`
  color: var(--Gray-500, #6b7280);
  font-family: Barlow;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  margin-top: 0.5rem;
`;

export const SubTitle = styled.div`
  color: var(--Gray-700, #374151);
  font-family: Barlow;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
`;

export const SettingInput = styled.input`
  border-radius: 0.5rem;
  border: 1px solid #d1d5db;
  background: #fff;
  padding: 0.625rem 0.875rem;
  color: var(--Gray-400, #9ca3af);
  font-family: Barlow;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
`;

export const SettingButton = styled.div`
  display: flex;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #e41d57;
  background: #e41d57;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--White, var(--Brand-White, #fff));
  font-family: Barlow;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

//Meeting Modal styling
export const YesBtn = styled.div`
  display: flex;
  padding: 0.5rem 0.6875rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.5rem;
  background: rgba(19, 100, 254, 0.06);
  color: #1364fe;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.75rem; /* 100% */
`;

export const MediaDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0.75rem 0rem 0.75rem 0rem;
`;

export const GridContainer = styled.div`
  display: grid;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  grid-template-columns: repeat(7, 0fr);
  @media (max-width: 770px) {
    grid-template-columns: repeat(6, 0fr);
  }
  @media (max-width: 655px) {
    grid-template-columns: repeat(5, 0fr);
  }
  @media (max-width: 540px) {
    grid-template-columns: repeat(4, 0fr);
  }
  @media (max-width: 430px) {
    grid-template-columns: repeat(3, 0fr);
  }
  // @media (max-width: 1160px) {
  //   grid-template-columns: repeat(3, 0fr);
  // }
  // @media (max-width: 1160px) {
  //   grid-template-columns: repeat(3, 0fr);
  // }
  // @media (max-width: 970px) {
  //   grid-template-columns: repeat(2, 0fr);
  // }

  // @media (max-width: 600px) {
  //   grid-template-columns: 1fr;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  // }
  @media (min-width: 1680px) {
    grid-template-columns: repeat(4, 0fr);
  }
  @media (min-width: 2030px) {
    grid-template-columns: repeat(5, 0fr);
  }
  @media (min-width: 2400px) {
    grid-template-columns: repeat(6, 0fr);
  }
`;

export const MediaCard = styled.div`
  width: 5.5rem;
  height: 4.375rem;
  flex-shrink: 0;
  border-radius: 1rem;
  cursor: pointer;
  border: 1px solid var(--Gray-200, #e5e7eb);
  background: var(--Brand-White, #fff);
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
`;

//Comments styling

export const CommentInput = styled.input`
  border-radius: 1rem;
  background: #f0f2f5;
  border: 1px solid #d1d5db;
  padding: 0.625rem 0.875rem;
  color: rgba(30, 30, 30, 0.6);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
`;

export const CommentDiv = styled.div`
  margin-top: 0.3rem;
  display: flex;
  padding: 0.625rem 0.75rem;
  align-items: center;
  gap: 0.125rem;
  align-self: stretch;
  border-radius: 1rem;
  background: #f0f2f5;
`;

export const UserTxt = styled.div`
  color: #000;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
