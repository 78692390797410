import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SnackbarAlert from "../Alerts/SnackbarAlert";
import DynamicReactQuill from "../RichText/DynamicReactQuill";
import { useLocation } from "react-router-dom";
import { Container, Title, Header, HeaderBtn, TextDiv, Input, GridContainer, MediaDiv, MediaCard } from "./Style";
import { handleRichCopy, handlePlainTextCopy } from "../utils/CopyHandlers";
import { ModalOverlay, ModalContent } from "../Modals/Style";
import { InputDiv, InputLabel } from "../Modals/Style";
import { SelectField, DropdownMenu, DropdownItem } from "../../Pages/Avatar/Style";
import { AUTOMATIONS_DATA , COPY_OPTIONS, mapAutomationData} from "../../Pages/Automations/constants";
import CircularProgress from "@mui/material/CircularProgress";
import Dropdown from "../../assets/images/dropdown.svg";
import Edit from "../../assets/images/edit.svg";
import Copy from "../../assets/images/copy-icon.svg";

const formatBlogPostContent = (content) => {
  content = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  content = content.replace(/(\d+)\. \*\*(.*?)\*\*/g, '<li><strong>$2</strong></li>');
  content = content.replace(/##{1,2} (.*?)\n/g, (_, text) => `<h2 style="margin: 0.75rem 0rem;font-size:16px;">${text}</h2>`);
  content = content.replace(/(<li>.*<\/li>)/g, '<ol>$1</ol>');
  content = content.split('\n').map(line => `<p style="margin: 0.5rem 0rem;">${line}</p>`).join('');
  content = content.replace(/#/g, '');
  return content;
};

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const makeValidUrl = (url) => {
  return decodeURIComponent(url.replace(/\\\//g, "/"));
};

const BlogPostResult = () => {
  const location = useLocation();
  const { record } = location.state || {};
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isEditing, setIsEditing] = useState(false);
  const [editorContent, setEditorContent] = useState(record?.response || "");
  const [publishDate, setPublishDate] = useState(record?.publish_date || "");
  const [saveLoading, setSaveLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [copyOpen, setCopyOpen] = useState(false);
  const [previewMedia, setPreviewMedia] = useState(null);
  const navigate = useNavigate();

  const images = (record?.images && record.images !== "null") ? JSON.parse(record.images).map(makeValidUrl) : [];
  const videoLink = record?.videos_link ? makeValidUrl(record.videos_link) : "";
  
  const handleSelectAutomation = (automation) => {
    const workflow_id = automation.id;
    const automationData = mapAutomationData(automation);
    const cardTitle = automationData?.cardTitle || '';
    const cardText = automationData?.cardText || '';
    const plainText = formatText(formatBlogPostContent(editorContent)); // Get plain text with markdown formatting
    navigate("/blogpost", {
      state: { workflow_id, cardTitle, cardText, plainText, isSidebarOpen: true},
    });
    setDropdownOpen(false);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    setSaveLoading(true);
    const token = localStorage.getItem("token");
    const payload = {
      automation_id: record.id,
      updatedtext: editorContent,
    };

    try {
      const response = await axios.post("https://admin.dialect-ai.com/api/updateresponse", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSnackbarMessage(response.data.data.data || "Content saved successfully");
      setSnackbarSeverity("success");
    } catch (error) {
      setSnackbarMessage("Failed to save content");
      setSnackbarSeverity("error");
    } finally {
      setSaveLoading(false);
      setSnackbarOpen(true);
      setIsEditing(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditorContent(record.response || "");
    setPublishDate(record.publish_date);
  };

  const formatText = (html) => {
    const element = document.createElement("div");
    element.innerHTML = html;
    const traverseNodes = (node) => {
      let text = '';
      
      node.childNodes.forEach(child => {
        if (child.nodeType === Node.TEXT_NODE) {
          text += child.textContent;
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          switch (child.tagName) {
            case 'P':
              text += child.textContent + '\n';
              break;
            case 'BR':
              text += '\n';
              break;
            case 'STRONG':
              text += '**' + child.textContent + '**';
              break;
            case 'EM':
              text += '*' + child.textContent + '*';
              break;
            case 'UL':
            case 'OL':
              text += '\n' + traverseNodes(child) + '\n';
              break;
            case 'LI':
              text += '- ' + child.textContent + '\n';
              break;
            default:
              text += traverseNodes(child);
          }
        }
      });
      return text;
    };
    return traverseNodes(element).trim();
  };
  
  const handleSelectCopyOption = (option) => {
    if (option.id === 1) {
      handleRichCopy(editorContent, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen); // First option: Rich Copy
    } else if (option.id === 2) {
      handlePlainTextCopy(editorContent, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen); // Second option: Plain text copy
    }
  };
  
  const openPreview = (media) => {
    setPreviewMedia(media);
  };

  const closePreview = () => {
    setPreviewMedia(null);
  };

  return (
    <>
      <Container style={{ maxHeight: 'calc(100vh - 78px)', overflowY: 'auto' }}>
        <Header>
        <Title>Results</Title>
        {publishDate && (
          <HeaderBtn>Publish Date: {formatDate(publishDate)}</HeaderBtn>
        )}
        <div style={{ display: "flex", gap: "0.5rem", marginLeft: "auto" }}>
          {isEditing ? (
            <>
              <HeaderBtn onClick={handleCancel}>Cancel</HeaderBtn>
              <HeaderBtn onClick={handleSave}>{saveLoading ? (<CircularProgress size={21} color="inherit" />) : ("Save")}</HeaderBtn>
            </>
          ) : (
            <>
              <SelectField style={{ gap: "0.5rem", position: "relative", alignItems: "center" }} onClick={() =>{ setDropdownOpen(!dropdownOpen)}}>
                <img src={Dropdown} alt="select" />
                <Input>{"Select Automation"}</Input>
                {dropdownOpen && (
                  <DropdownMenu>
                    {AUTOMATIONS_DATA.map((automation) => (
                      <DropdownItem key={automation.id} onClick={() => handleSelectAutomation(automation)}>
                        {automation.name.length > 20 ? `${automation.name.substring(0, 20)}...` : automation.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                )}
              </SelectField>
              <HeaderBtn onClick={handleEdit}><img src={Edit} alt="edit" />Edit</HeaderBtn>
              <SelectField style={{ gap: "0.5rem", position: "relative", alignItems: "center" }} onClick={() =>{ setCopyOpen(!copyOpen)}}>
                <img src={Copy} alt="select" />
                <Input>{"Copy"}</Input>
                {copyOpen && (
                  <DropdownMenu style={{left: "-40px"}}>
                    {COPY_OPTIONS.map((option) => (
                      <DropdownItem key={option.id} onClick={() => handleSelectCopyOption(option)}>
                        {option.option}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                )}
              </SelectField>
              {/* <HeaderBtn onClick={handleCopy}> <img src={Copy} alt="copy" />Copy</HeaderBtn> */}
            </>
          )}
        </div>
      </Header>
      {(images.length > 0 || videoLink) && (
        <MediaDiv>
          <GridContainer>
            {images.map((imageUrl, index) => (
              <MediaCard key={index} onClick={() => openPreview({ type: "image", url: imageUrl })}>
                <img src={imageUrl} alt={`media-${index}`} style={{ width: "100%", height: "100%" }} />
              </MediaCard>
            ))}
            {videoLink && (
              <MediaCard onClick={() => openPreview({ type: "video", url: videoLink })}>
                <video controls style={{ width: "100%", height: "100%" }}>
                  <source src={videoLink} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </MediaCard>
            )}
          </GridContainer>
        </MediaDiv>
      )}
      <TextDiv>
        <InputDiv>
          {isEditing ? (
            <DynamicReactQuill value={formatBlogPostContent(editorContent)} setValue={setEditorContent} placeholder="Edit your content" />
          ) : (
            <InputLabel dangerouslySetInnerHTML={{ __html: formatBlogPostContent(editorContent) }} />
          )}
        </InputDiv>
      </TextDiv>
      <SnackbarAlert open={snackbarOpen} setOpen={setSnackbarOpen} message={snackbarMessage} severity={snackbarSeverity}/>
    </Container>
    {previewMedia && (
        <ModalOverlay onClick={closePreview}>
          <ModalContent style={{ width: "90%", maxWidth: "600px", maxHeight: "600px", alignItems: "center", padding: "0.5rem" }}>
            {previewMedia.type === "image" ? (
              <img src={previewMedia.url} alt="preview" style={{ width: "100%", borderRadius: "0.5rem" }} />
            ) : (
              <video controls style={{ width: "100%", borderRadius: "1rem" }}>
                <source src={previewMedia.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};
export default BlogPostResult;
