import styled from "styled-components";

export const PaginationBtn = styled.div`
  display: flex;
  padding: 0.5rem 0.875rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--Gray-300, #d1d5db);
  background: var(--Brand-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-700, #374151);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
  cursor: pointer;
  &:hover {
    background: #d9dce3;
  }
`;

export const Paginationdiv = styled.div`
  display: flex;
  padding: 0.75rem 1.5rem 1rem 1.5rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid var(--Gray-200, #e5e7eb);
`;

export const PaginationTxt = styled.div`
  color: var(--Gray-700, #374151);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
`;

export const StatusCard = styled.div`
  display: flex;
  padding: 0.5rem 0.875rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  width: fit-content;
  border: 1px solid var(--Gray-300, #d1d5db);
  background: var(--Brand-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-700, #374151);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
`;