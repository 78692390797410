import React from "react";
import {
  AgentsDiv,
  Container,
  GrayLine,
  GridContainer,
  Header,
  MobileTitle,
  SectionTitle,
  SubTitle,
  Title,
} from "./Style";
import ClientCard from "../AgentsCard/ClientCard";
import StaticCard from "../AgentsCard/StaticCard";
import { ClientAgents, StaticAgents } from "./data";

const Agents = () => {
  return (
    <Container style={{maxHeight: 'calc(100vh - 78px)', overflowY: 'auto'}}>
      <Header>
        <Title>AI Team</Title>
        <SubTitle>(5 AI-agents active)</SubTitle>
      </Header>

      <AgentsDiv>
        <MobileTitle>Static Agents</MobileTitle>
        <div style={{display:'flex', gap:'1rem', alignItems:'center', width:'100%'}}>
          <SectionTitle>
            Static Agents
          </SectionTitle>
          <GrayLine />
        </div>
        <GridContainer>
          {StaticAgents.map((item, index) => (
            <StaticCard
              key={index}
              index={index}
              cardTitle={item.cardTitle}
              cardText={item.cardText}
              cardroute={item.cardRoute}
              image={item.img}
            />
          ))}
        </GridContainer>
        <div style={{display:'flex', gap:'1rem', alignItems:'center', width:'100%'}}>
          <SectionTitle>
            Client Agents
          </SectionTitle>
          <GrayLine />
        </div>
        <MobileTitle>Client Agents</MobileTitle>
        <GridContainer style={{marginBottom:'1rem'}}>
          {ClientAgents.map((item, index) => (
            <ClientCard
              key={index}
              index={index}
              cardTitle={item.cardTitle}
              cardText={item.cardText}
              cardRoute = {item.cardRoute}
              image={item.img}
              isAgency={false}
            />
          ))}
        </GridContainer>
      </AgentsDiv>
    </Container>
  );
};

export default Agents;
