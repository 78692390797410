import React from "react";
import { useNavigate } from "react-router-dom";
import { CardContainer, CardText, Title, TrainButton, ButtonImg, Tag, EmptyTag } from "./Style";
import ArrowRightWhite from "../../assets/images/arrow_right_white.svg";

const AutomationCard = ({
  cardTitle = "Phillip Davidson",
  cardText = "Lorem ipsum dolor sit amet consectetur Ullamcorper non aseu.",
  workflow_id = "",
  image,
  type,
  tag,
  tagImage
}) => {
  const navigate = useNavigate();
  
  const handleNavigation = () => {
      navigate("/blogpost", {
        state: { workflow_id, cardTitle, cardText},
      });
  };

  return (
    <CardContainer style={{ width: '17.59rem', minHeight: '15.5rem', padding: '0rem 1.25rem' }}>
      <img alt="" src={image} style={{width:'40px', height:'40px', margin:'20px 0px 0px'}}/>
      <Title style={{ marginTop: '1.25rem' }}>{cardTitle}</Title>
      {tag && <Tag>{tag}</Tag>}
      {!tag && <EmptyTag>empty</EmptyTag>}
      <CardText style={{marginBottom: "0.5rem"}}>{cardText}</CardText>
      <TrainButton style={{ marginBottom: '1rem', marginTop: "auto" }} onClick={handleNavigation}>
        Show
        <ButtonImg src={ArrowRightWhite} alt="train"/>
      </TrainButton>
    </CardContainer>
  );
};

export default AutomationCard;