import React, { useState } from "react";
import axios from "axios";
import { ModalOverlay, ModalContent, ModalHeader, ModalButton } from "./Style";
import CircularProgress from "@mui/material/CircularProgress";
import SnackbarAlert from "../Alerts/SnackbarAlert";

const DeleteEventModal = ({ closeDeleteModal, closeModal, event, fetchEvents }) => {
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleDelete = async () => {
    const token = localStorage.getItem("token");
    setLoading(true); // Start loading
  
    try {
      if (event.record.workflow_id) {
        // If workflow_id exists, call the delete automation API
        await axios.post(
          `https://admin.dialect-ai.com/api/deleteautomation`,
          { id: event.record.id }, // Payload with eventId
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("Event Deleted");
        setSnackbarSeverity("success");
      } else {
        // If workflow_id does not exist, call labeladdcomment API with event_id
        await axios.post(
          `https://admin.dialect-ai.com/api/deleteEvent`,
          { event_id: event.record.id }, // Payload with event_id
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("Event Deleted");
        setSnackbarSeverity("success");
      }
  
      setSnackbarOpen(true);
      fetchEvents();
    } catch (error) {
      console.error("Error processing request:", error);
      setSnackbarMessage("Error processing request");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
      setTimeout(() => {
        setSnackbarOpen(false);
        closeModal();
        closeDeleteModal();
      }, 1000);
    }
  };
  
  return (
    <ModalOverlay>
      <ModalContent style={{ width: "35rem", alignItems: "center" }}>
        <ModalHeader>Are you sure you want to delete this Event?</ModalHeader>
        <div style={{ display: "flex", gap: "1rem" }}>
          <ModalButton onClick={closeDeleteModal} disabled={loading}>
            Cancel
          </ModalButton>
          <ModalButton
            onClick={handleDelete}
            style={{ background: "#1674FE", color: "#FFF" }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={21} color="inherit" />
            ) : (
              "Delete"
            )}
          </ModalButton>
        </div>
      </ModalContent>
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </ModalOverlay>
  );
};

export default DeleteEventModal;
