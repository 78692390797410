import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #FDFDFD;
`;

export const LoginBox = styled.div`
display: flex;
align-items: center;
justify-content: center;
max-height: 43.75rem;
height: 80%;
max-width: 43.75rem;
width: 95%;
border-radius: 2.5rem;
background: #FFF;
box-shadow: 0px 30px 40px 0px rgba(220, 220, 220, 0.20);
`;

export const ContentDiv = styled.div`
width: 20.75rem;

@media (max-width: 360px) {
  width: 90%;

}
`
export const Title = styled.h1`
  margin-bottom: 2rem;
  color: #171725;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.75rem; /* 137.5% */
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  // padding: 12px;
  // margin-bottom: 16px;
  // border: 1px solid #ccc;
  // border-radius: 5px;
  // font-size: 16px;

  padding: 0.94rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid #EBEBFD;
  background: #FFF;
  color: #696974;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const InputLabel = styled.div`
color: #171725;
font-family: Poppins;
font-size: 1.125rem;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 0.69rem;
`


export const FormText = styled.div`
color: #696974;
font-family: Roboto;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
`
export const SignInButton = styled.div`
display: flex;
padding: 0.625rem 0rem 0.5625rem 0rem;
justify-content: center;
align-items: center;
border-radius: 0.5rem;
background: #FF8900;
cursor: pointer;
color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 1.88rem;
margin-bottom: 0.89rem;
`
export const LogoBox = styled.div`
display: flex; 
align-items: center;
justify-content: center;
`

export const PasswordWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;