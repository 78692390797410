import React, { useState, useEffect, useRef, useCallback} from "react";
import { useNavigate, useOutletContext, useLocation } from "react-router-dom";
import axios from "axios";
import LinearProgress from "@mui/joy/LinearProgress";
import { FileDiv, FileTxt } from "./Style";
import { Container, Header, Title } from "../../components/Agents/Style";
import CircularProgress from '@mui/material/CircularProgress';
import {
  AvatarDiv,
  FlexDiv,
  InputDiv,
  Label,
  InputContainer,
  InputField,
} from "../../Pages/Avatar/Style";
import { RunButton } from "../Sidebar/Style";
import { HeaderBtn } from "../BlogPost/Style";
import ChatIcon from "../../assets/images/chat.svg";
import UploadIcon from "../../assets/images/upload-white.svg";
import PdfIcon from "../../assets/images/pdf-icon.svg";
import DocIcon from "../../assets/images/doc-icon.svg";
import TxtIcon from "../../assets/images/txt-icon.svg";
import CloseX from "../../assets/images/x-close.svg";
import s3 from "../Environment/Asconfig";
import SnackbarAlert from "../Alerts/SnackbarAlert";
import { AGENCY_AGENT, CLIENT_AGENT, NAME, COMMANDS, UPLOAD_FILES, CHAT, UPLOAD } from "../../constants/Text";

const TrainAgent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAgency = true } = location.state || {};
  const [files, setFiles] = useState([]);
  const { profileUpdated, setProfileUpdated } = useOutletContext();
  const [isModelTrained, setIsModelTrained] = useState(false);
  const [buttonText, setButtonText] = useState("Train");
  const [retrainValue, setRetrainValue] = useState(0);
  const [agentName, setAgentName] = useState("");
  const [agentId, setAgentId] = useState("");
  const [customCommand, setCustomCommand] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false); 
  const [dataLoading, setDataLoading] = useState(true);
  const fileEndRef = useRef(null);

  const fetchModelData = useCallback(async () => {
    setDataLoading(true);
    const selectedUserId = localStorage.getItem("selectedUserId");
    const token = localStorage.getItem("token");
    try {
      const apiUrl = isAgency 
        ? `https://admin.dialect-ai.com/api/agencydata`
        : `https://admin.dialect-ai.com/api/labelgetmodeldata?user_id=${selectedUserId}`;
      
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const userStatus = response.data.agent;
      if (userStatus !== null) {
        setIsModelTrained(true);
        setButtonText("Retrain");
        setRetrainValue(1);
        setAgentId(response.data.agent.id);
        setAgentName(response.data.agent.name);
        setCustomCommand(response.data.agent.description);
  
        const previousFiles = response.data.userdata.map((file) => ({
          name: file.data_key.split("documents/")[1],
          type: file.data_type,
          progress: 100,
          file: null,
          isPrevious: true,
        }));
        setFiles(previousFiles);
      } else {
        setFiles([]);
        setCustomCommand("");
        setAgentName("");
        setRetrainValue(0);
      }
    } catch (error) {
      console.error("Error fetching model data:", error);
      setFiles([]);
      setCustomCommand("");
      setAgentName("");
      setRetrainValue(0);
    } finally {
      setDataLoading(false);
    }
  }, [isAgency]); // Add any dependencies used within fetchModelData
  
  useEffect(() => {
    if (profileUpdated) { 
      fetchModelData();
      setProfileUpdated(false); 
    }
  }, [profileUpdated, setProfileUpdated, fetchModelData]); 
  
  useEffect(() => {
    fetchModelData();
  }, [fetchModelData]);

  const uploadFileToS3 = async (file) => {
    const selectedUserId = localStorage.getItem("selectedUserId");
    const folderName = `${selectedUserId}/documents`;
    const key = `${folderName}/${file.name}`;
    const params = {
      Bucket: "dialect-marketing-userdata",
      Key: key,
      Body: file,
    };
    const data = await s3.upload(params).promise();
    return data.Location;
  };

  const handleFileUpload = (event) => {
    const uploadedFiles = Array.from(event.target.files).map((file) => ({
      name: file.name,
      type: file.type,
      progress: 0,
      file,
      isPrevious: false, // Flag to indicate these are newly uploaded files
    }));
    setFiles([...files, ...uploadedFiles]);
    simulateUpload(uploadedFiles);
  };

  const simulateUpload = (uploadedFiles) => {
    uploadedFiles.forEach((file, index) => {
      const interval = setInterval(() => {
        setFiles((prevFiles) =>
          prevFiles.map((f, i) =>
            i === files.length + index
              ? { ...f, progress: f.progress < 100 ? f.progress + 10 : 100 }
              : f
          )
        );
        if (file.progress >= 100) {
          clearInterval(interval);
        }
      }, 100);
    });
  };
  const handleTrain = async () => {
    if (!customCommand && !agentName) {
      setSnackbarMessage("Enter command and name");
      setSnackbarOpen(true);
      return;
    }
    setLoading(true);
    const selectedUserId = localStorage.getItem("selectedUserId");
    const token = localStorage.getItem("token");
  
    const uploadedFileData = await Promise.all(
      files
        .filter((file) => !file.isPrevious) // Only upload new files
        .map(async (fileObj) => {
          try {
            const s3Url = await uploadFileToS3(fileObj.file);
            return {
              data_type: fileObj.type.split("/")[1],
              data_key: s3Url,
            };
          } catch (error) {
            console.error(`Error uploading ${fileObj.name}:`, error);
          }
        })
    );
  
    let payload = {
      agent_name: agentName,
      custom_commands: customCommand,
      model_name: isAgency ? "Agency Model" : `Model ${selectedUserId}`,
      model_description: isAgency ? "This is Agency Model" : `This is model ${selectedUserId}`,
      agent_language: "en",
      agent_description: `this is agent ${selectedUserId}`,
      data: uploadedFileData,
      reuse_data: 1,
    };
  
    // If isAgency is false, add user_id and retrain to the payload
    if (!isAgency) {
      payload = {
        ...payload,
        user_id: selectedUserId,
        retrain: retrainValue,
      };
    }
  
    try {
      // Conditionally call the correct API
      const apiUrl = isAgency
        ? "https://admin.dialect-ai.com/api/agencylabelmodelmade"
        : "https://admin.dialect-ai.com/api/marketingmodelmade";
        
      const response = await axios.post(apiUrl, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Error training model:", response.data);
      setSnackbarMessage("Training Started");
      setSnackbarOpen(true);
      setTimeout(() => {
        fetchModelData();
      }, 1000);
    } catch (error) {
      setSnackbarMessage("Error uploading file");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };
  
  const getFileIcon = (type) => {
    if (type.includes("pdf")) return PdfIcon;
    if (type.includes("wordprocessingml.document") || type.includes("msword")) return DocIcon;
    if (type.includes("plain")) return TxtIcon;
    if (type.includes("doc")) return DocIcon;
    if (type.includes("xlsx")) return TxtIcon;
    return null;
  };

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleChatNavigation = () => {
    if (isModelTrained) {
      navigate("/agent-chat", { state: { agentName: agentName, agentId: agentId } });
    } else {
      console.log("Model not Trained");
    }
  };

  useEffect(() => {
    if (fileEndRef.current) {
      fileEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [files]);

  return (
    <Container style={{ maxHeight: "calc(100vh - 78px)", overflowY: "auto" }}>
      <Header>
        <Title style={{ marginTop: "auto" }}>{isAgency ? AGENCY_AGENT : CLIENT_AGENT}</Title>
        <HeaderBtn
          style={{ marginLeft: "auto", marginRight: "1.5rem" }}
          onClick={handleChatNavigation}
        >
          <img src={ChatIcon} alt="Chat" /> {CHAT}
        </HeaderBtn>
      </Header>
      <AvatarDiv>
        <FlexDiv>
          <InputDiv style={{ flex: "1" }}>
            <Label>{NAME}</Label>
            <InputField
              placeholder="Name"
              value={agentName}
              onChange={(e) => setAgentName(e.target.value)}
            />
          </InputDiv>
        </FlexDiv>
        <FlexDiv style={{ marginTop: "1.25rem" }}>
          <InputDiv style={{ flex: "1" }}>
            <Label>{COMMANDS}</Label>
            <InputContainer
              type="text"
              name="Command"
              placeholder="Command Text"
              value={customCommand}
              onChange={(e) => setCustomCommand(e.target.value)}
            />
          </InputDiv>
        </FlexDiv>
        <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
          <Label>{UPLOAD_FILES}</Label>
          <RunButton
            style={{ marginLeft: "1rem", marginTop: "0rem", padding: "0.65rem 1rem" }}
          >
            <input
              type="file"
              multiple
              style={{ display: "none" }}
              id="file-upload"
              onChange={handleFileUpload}
              accept=".pdf,.txt,.doc,.docx"
            />
            <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
              <img src={UploadIcon} alt="upload" /> {UPLOAD}
            </label>
          </RunButton>
        </div>
        {dataLoading && (<CircularProgress style={{margin: "auto"}}/>)}
        {!dataLoading && <FlexDiv style={{ marginTop: "1.25rem" }}>
          <InputDiv style={{ flex: "1", maxHeight: "20rem", overflowY: "auto" }}>
            {files.map((file, index) => (
              <FileDiv key={index}>
                <img src={getFileIcon(file.type)} alt="file icon" />
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <FileTxt>{file.name}</FileTxt>
                  </div>
                  <div ref={fileEndRef} />
                  {!file.isPrevious && (
                    <LinearProgress
                      determinate
                      value={file.progress}
                      style={{ visibility: file.progress === 100 ? "hidden" : "visible" }}
                    />
                  )}
                </div>
                <img
                  src={CloseX}
                  alt="Remove"
                  style={{ cursor: "pointer" }}
                  onClick={() => removeFile(index)}
                />
              </FileDiv>
            ))}
          </InputDiv>
        </FlexDiv>}
        <RunButton
          style={{ marginLeft: "auto", marginTop: "2rem", padding: "0.65rem 3rem" }}
          onClick={handleTrain}
        >
          {loading ? <CircularProgress size={24} /> : buttonText==="Retrain" ? "Save" : "Train" }
        </RunButton>
      </AvatarDiv>
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarMessage === "Training Started" ? "success" : "error"}
      />
    </Container>
  );
};

export default TrainAgent;
