// src/Components/Editors/DynamicReactQuill.jsx
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const modules = {
  toolbar: [
    [{ size: ['small', false, 'large', 'huge'] }, { font: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ align: [] }],
    ['link', 'image', 'video'],
    ['code-block'],
    [{ script: 'sub' }, { script: 'super' }],
    ['formula'],
    ['blockquote'],
    ['clean']
  ],
  clipboard: {
    matchVisual: false
  },
};

const formats = [
  'header',
  'font',
  'size',
  'align',
  'color',
  'background',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'code-block',
  'script'
];

export default function DynamicReactQuill({ value, setValue, placeholder, height='', marginBottom='5%'}) {
  return (
    <ReactQuill
      modules={modules}
      formats={formats}
      theme='snow'
      value={value}
      onChange={setValue}
      placeholder={placeholder || 'Unleash Your Creativity'}      
      style={{ background: '#fff', color: 'black', marginBottom: marginBottom, height:height}}
    />
  );
}