import React, {useEffect, useState} from 'react'
import { InputLabel, TextDiv, Title, NotesDeleteImg } from './Style'
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import {
    Container,
    Header,
} from "../../components/Agents/Style";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from '../../assets/images/NotesDelete.svg'
import EditIcon from '../../assets/images/EditNote.svg';
import HideIcon from '../../assets/images/Visibility.svg';
import ShowIcon from '../../assets/images/VisibilityOn.svg';
import Tooltip from '@mui/material/Tooltip';
import SnackbarAlert from "../Alerts/SnackbarAlert";

const Show = () => {
    const navigate = useNavigate();
    const [notes, setNotes] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [noteName, setNoteName] = useState('');
    const [noteText, setNoteText] = useState('');
    const [show, setShow] = useState(0);

    const deleteNotes = async () => {
      setLoading(true);
      try {
        await axios.post('https://admin.dialect-ai.com/api/labeldeletenote', {
          note_id: id,
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,  // Authorization token
          },
        });
        setLoading(false);
        navigate('/notes')        
      } catch (error) {
        console.error('Error fetching notes:', error);
      } finally {
        setLoading(false); // Stop loading when data is fetched
      }
    };

    const updateNotes = async () => {
      const token = localStorage.getItem("token");
      setLoading(true);
      try {
        await axios.post(
          'https://admin.dialect-ai.com/api/labelupdatenote',
          {
            data: noteText,
            title: noteName,
            note_id: id,
            visibility: show === 1 ? 0 : 1,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLoading(false);
        setSnackbarMessage(show === 1 ?'Hidden': 'Made Visible');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setShow(show === 1 ? 0 : 1)        
      } catch (error) {
        console.error('Error fetching notes:', error);
      } finally {
        setLoading(false); // Stop loading when data is fetched
      }
    };

    useEffect(() => {
      const fetchNotes = async () => {
        setLoading(true);
        try {
          const response = await axios.post('https://admin.dialect-ai.com/api/labelgetonenote', {
            note_id: id,
          }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,  // Authorization token
            },
          });
          setNotes(response.data.usernotes);
          setNoteName(response.data.usernotes.title);
          setNoteText(response.data.usernotes.data); 
          setShow(response.data.usernotes.visibility);         
          setLoading(false);
        } catch (error) {
          console.error('Error fetching notes:', error);
        } finally {
          setLoading(false); // Stop loading when data is fetched
        }
      };
  
      fetchNotes();
    }, [id]);

    return (
    <Container style={{ maxHeight: "calc(100vh - 78px)", overflowY: "auto" }}>
        {loading?
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%', height:'100vh'}}>
                <CircularProgress />
            </div>
        : notes?
            <>
                <Header style={{ justifyContent: 'space-between' }}>
                    <Title onClick={() => navigate('/notes')}>{`< ${notes.title}`}</Title>
                </Header>    
                <TextDiv>
                    <div style={{display:'flex', width:'100%', justifyContent:'space-between'}}>
                      <Title>{notes.title}</Title>
                      <div style={{display:'flex', gap:'4px'}}>
                        <Tooltip title={show === 0 ? 'Show' : 'Hide'}>                        
                          <NotesDeleteImg alt='' src={show === 0 ? HideIcon : ShowIcon} onClick={updateNotes}/>
                        </Tooltip>
                        <Tooltip title={'Edit'}>
                          <NotesDeleteImg alt='' src={EditIcon} onClick={()=>navigate(`/edit-notes/${notes.id}`)} style={{width:"24px"}}/>
                        </Tooltip>
                        <Tooltip title={'Delete'}>                        
                          <NotesDeleteImg alt='' src={DeleteIcon} onClick={deleteNotes}/>
                        </Tooltip>
                      </div>
                    </div>                 
                    <InputLabel dangerouslySetInnerHTML={{ __html: notes.data }} />
                </TextDiv>
                <SnackbarAlert
                    open={snackbarOpen}
                    setOpen={setSnackbarOpen}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />                
            </>
            :
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%', height:'100vh'}}>
                No Data Available
            </div>
        }
    </Container>
  )
}

export default Show