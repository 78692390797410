import React, { useState } from 'react';
import {
  AddNotesBtn,
  Container,
  Header,
} from "../../components/Agents/Style";
import { AddNoteOuterDiv, AddNoteOuterDivTitle, InputField, Title } from './Style';
import DynamicReactQuill from '../RichText/DynamicReactQuill';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress";
import SnackbarAlert from "../Alerts/SnackbarAlert"; // Import SnackbarAlert
import Switch from "@mui/material/Switch";

const Add = () => {
  const navigate = useNavigate();
  const [noteName, setNoteName] = useState('');
  const [noteText, setNoteText] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);  // Snackbar open state
  const [snackbarMessage, setSnackbarMessage] = useState('');  // Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');  // Snackbar severity (success/error)
  const [visibility, setVisibility] = useState(false); // State for the switch

  const saveNote = async () => {
    const token = localStorage.getItem("token");
    const selectedUserId = localStorage.getItem("selectedUserId");
    setLoading(true);

    try {
      await axios.post(
        'https://admin.dialect-ai.com/api/labelcreatenote',
        {
          data: noteText,
          title: noteName,
          user_id: selectedUserId,
          visibility: visibility // Set visibility based on switch state
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSnackbarMessage('Note saved successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setNoteName('');
      setNoteText('');
    } catch (error) {
      console.error('Error saving note:', error);
      setSnackbarMessage('Failed to save the note!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true); 
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container style={{ maxHeight: "calc(100vh - 78px)", overflowY: "auto" }}>
      <Header style={{ justifyContent: 'space-between' }}>
        <Title onClick={() => navigate('/notes')}>{'< Create Notes'}</Title>
      </Header>
      <AddNoteOuterDiv>
        <AddNoteOuterDivTitle>Name your note</AddNoteOuterDivTitle>
        <InputField
          type="text"
          value={noteName}
          onChange={(e) => setNoteName(e.target.value)}
        />
        <DynamicReactQuill
          height='500px'
          marginBottom={'80px'}
          value={noteText}
          setValue={setNoteText}
        />
        <div style={{display : "flex", alignItems: "center", marginBottom: "1.25rem"}}>
          <AddNoteOuterDivTitle>Visibility</AddNoteOuterDivTitle>
          <Switch
            color="warning"
            checked={visibility}
            onChange={(e) => setVisibility(e.target.checked)} // Toggle visibility
          />
        </div>
        <AddNotesBtn style={{ maxWidth: '70px' }} onClick={saveNote} disabled={loading}>
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Save'}
        </AddNotesBtn>
      </AddNoteOuterDiv>
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Container>
  );
};

export default Add;
