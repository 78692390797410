import styled from "styled-components";

export const NotesImage = styled.img`
    width: 264px;
    height: 172px;
    border-radius: 8px;
`;

export const NotesHeader = styled.div`
    color: #202020;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-top: 0.5rem;
`;

export const NotesDescription = styled.div`
    color: #202020;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    max-width: 264px;
`;

export const GridContainer = styled.div`
  display: grid;
  justify-content: space-between;
  width: 100%;
  margin-right: 24px;
  margin-top: 1rem;
  grid-template-columns: repeat(4, 0fr);
  @media (max-width: 1400px) {
    grid-template-columns: repeat(3, 0fr);
  }
  @media (max-width: 1160px) {
    grid-template-columns: repeat(2, 0fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 0fr);
  }
  @media (min-width: 1680px) {
    grid-template-columns: repeat(4, 0fr);
  }
  @media (min-width: 2030px) {
    grid-template-columns: repeat(5, 0fr);
  }
  @media (min-width: 2400px) {
    grid-template-columns: repeat(6, 0fr);
  }
`;

export const CardOuterDiv = styled.div`
    display: flex;
    flex-direction: column; 
    max-width: 264px; 
    margin-top:1rem;
    cursor: pointer;
    transition: transform 0.3s ease;
    &:hover {
        transform: scale(1.1);
    }
`;

export const AddNoteOuterDiv = styled.div`
  display: flex;
  margin: 2rem 1.5rem 0.5rem 3rem;
  padding: 24px 24px 17px;
  flex-direction: column;
  border-radius: 32px;
  background: #fafafa;  
`;

export const Title = styled.div`
  color: #202020;
  font-family: Poppins;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.5rem; 
  cursor: pointer;
  @media (max-width: 760px) {
    font-size: 1.25rem;
  }
  &:hover {
    opacity: 0.8;
  }
`;

export const AddNoteOuterDivTitle = styled.div`
  color: #202020;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; 
`;

export const InputField = styled.input`
  display: flex;
  padding: 0.625rem;
  margin-top: 0.625rem;
  margin-bottom: 1.25rem;
  color: #84818a;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 0.75rem;
  border: 1px solid #dcdbdd;
  background: var(--Brand-White, #fff);
`;

export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 1.5rem 1.5rem 3rem;
  padding: 2rem;
  border-radius: 1rem;
  border: 1px solid var(--Gray-200, #e5e7eb);
  background: var(--Brand-White, #fff);
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
`;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-self: stretch;
`;

export const InputLabel = styled.div`
  color: #202020;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const NotesDeleteImg = styled.img` 
  width: 20px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }  
`;