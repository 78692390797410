import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SnackbarAlert from "../../components/Alerts/SnackbarAlert";
import {
  Container,
  Form,
  LoginBox,
  Title,
  Input,
  InputLabel,
  SignInButton,
  ContentDiv,
  LogoBox,
  FormText
} from "./Style";
import CircularProgress from "@mui/material/CircularProgress";
import Logo from "../../assets/images/MediaHub.svg";

const Login = () => {
  const buttonRef = useRef(null);  
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {

    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch("https://admin.dialect-ai.com/api/labellogin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Login failed");
      }

      const data = await response.json();

      if (data.data.status === "200") {
        setSnackbarMessage("Login successful");
        setSnackbarOpen(true);

        // Save token to local storage
        const personalInfoData = JSON.parse(data.user.personal_info_json);
        localStorage.setItem("token", data.token);
        localStorage.setItem("labelName", data.user.name);
        localStorage.setItem("labelImage", personalInfoData?.profileImage);
        localStorage.setItem("user", JSON.stringify(data.user));

        setTimeout(() => {
          setSnackbarOpen(false); // Close Snackbar after 1 second
          navigate("/");
        }, 1000); // 1000 milliseconds = 1 second
      } else {
        throw new Error("Login failed");
      }
    } catch (error) {
      setSnackbarMessage("Wrong Credentials");
      setSnackbarOpen(true);
    } finally {
      setLoading(false); // Set loading to false when the API call is finished
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        buttonRef.current.click();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Container>
      <LoginBox>
        <ContentDiv>
          <LogoBox>
            <img src={Logo} alt="logo" style={{width:'5rem'}}/>
          </LogoBox>
          <Title>Login</Title>
          <Form>
            <InputLabel>Email</InputLabel>
            <Input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
            />
            <InputLabel style={{ marginTop: "1.31rem" }}>Password</InputLabel>
            <Input
              type="password"
              name="password"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleChange}
            />
            <FormText
              style={{
                marginLeft: "auto",
                marginTop: "0.69rem",
                cursor: "pointer",
              }}
              onClick={() => navigate('/forgotpassword')}
              onMouseOver={(e) =>
                (e.currentTarget.style.textDecoration = "underline")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.textDecoration = "none")
              }
            >
              Forgot password?
            </FormText>
            <SignInButton
              type="submit"
              onClick={handleSubmit}
              disabled={loading}
              ref={buttonRef}
            >
              {loading ? (
                <CircularProgress size={21} color="inherit" />
              ) : (
                "Sign In"
              )}
            </SignInButton>
          </Form>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <FormText>
              Don't have an account?{" "}
              <span
                style={{ color: "#FF8900", cursor: "pointer" }}
                onClick={() => navigate("/signup")}
                onMouseOver={(e) =>
                  (e.currentTarget.style.textDecoration = "underline")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.textDecoration = "none")
                }
              >
                Sign Up
              </span>
            </FormText> */}
          </div>
        </ContentDiv>
      </LoginBox>
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarMessage === "Login successful" ? "success" : "error"}
      />
    </Container>
  );
};

export default Login;
