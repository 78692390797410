import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Header, Title } from "../../components/Agents/Style";
import { Content, CenterPane, ScrollableChat, LoadingText } from "../Chat/Style";
import {
  AgentChatBox,
  AgentChatDiv,
  ChatText,
  UserChatBox,
  UserChatDiv,
} from "./Style";
import AgentIcon from "../../assets/images/red-circle.svg";
import UserIcon from "../../assets/images/blue-circle.svg";
import { InputContainer, Input } from "../Chat/Style";
import ArrowUpIcon from "../../assets/images/arrow-up.svg";

const AgentChat = () => {
  const location = useLocation();
  const { agentName, agentId } = location.state || {};
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const chatEndRef = useRef(null);

  const handleSendMessage = async () => {
    if (!inputMessage) return;

    // Add the user message to the chat
    const newMessages = [...messages, { role: "USER", content: inputMessage }];
    setMessages(newMessages);
    setInputMessage("");
    setIsLoading(true);

    // Prepare API request payload
    const requestBody = {
      agent_id: String(agentId),
      agent_name: agentName,
      messages: newMessages,
    };

    try {
      const response = await fetch(
        "https://flowproai.dialect-ai.com/marketing_platform/query",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      const assistantMessage = { role: "ASSISTANT", content: data.content };
      setMessages((prevMessages) => [...prevMessages, assistantMessage]);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  useEffect(() => {// Scroll to the latest message when chatHistory updates
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <Container style={{ maxHeight: "calc(100vh - 78px)", overflowY: "auto" }}>
      <Header>
        <Title>Chat</Title>
      </Header>
      <Content style={{ height: "100vh" }}>
        <CenterPane style={{ width: "90%" }}>
          <ScrollableChat>
            {messages.map((msg, index) => (
              <div key={index}>
                {msg.role === "USER" ? (
                  <UserChatDiv>
                    <UserChatBox>
                      <ChatText>{msg.content}</ChatText>
                    </UserChatBox>
                    <img src={AgentIcon} alt="Agent" />
                  </UserChatDiv>
                ) : (
                  <AgentChatDiv>
                    <img src={UserIcon} alt="User" />
                    <AgentChatBox>
                      <ChatText style={{ color: "#FFF" }}>
                        {msg.content}
                      </ChatText>
                    </AgentChatBox>
                  </AgentChatDiv>
                )}
              </div>
            ))}
            <div ref={chatEndRef} />
            {isLoading && (
              <AgentChatDiv>
                <img src={UserIcon} alt="User" />
                <AgentChatBox>
                  <ChatText style={{ color: "#FFF" }}><LoadingText isClient={true}/></ChatText>
                </AgentChatBox>
              </AgentChatDiv>
            )}
          </ScrollableChat>
          <InputContainer>
            <Input
              type="text"
              placeholder="Ask a question"
              value={inputMessage}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
            />
            <img
              src={ArrowUpIcon}
              alt="send message"
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={handleSendMessage}
            />
          </InputContainer>
        </CenterPane>
      </Content>
    </Container>
  );
};

export default AgentChat;
