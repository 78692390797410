import React, { useState, useEffect } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import ConnectEmailModal from "../Modals/ConnectEmailModal";
import {
  Container,
  Header,
  ProfileCard,
  Label,
  Value,
  SectionTitle,
  Title,
  EditButton,
  InputField,
} from "./Style";
import AvatarIcon from "../../assets/images/empty-avatar.svg";
import CameraIcon from "../../assets/images/photo-camera.svg";
import EditIcon from "../../assets/images/edit.svg";
import s3 from "../Environment/Asconfig"; // Import your S3 configuration
import { TrainBtn } from "../AgentsCard/Style";
import DeleteIcon from "../../assets/images/delete.svg";

const UserProfile = () => {
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveAdressLoading, setSaveAdressLoading] = useState(false);
  const [editPersonal, setEditPersonal] = useState(false);
  const [editAddress, setEditAddress] = useState(false); 
  const [personalInfo, setPersonalInfo] = useState({});
  const [address, setAddress] = useState({});
  const [selectedFile, setSelectedFile] = useState(null); 
  const [previewImage, setPreviewImage] = useState(AvatarIcon); 
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [emailData, setEmailData] = useState(null);
  const [showEmailSection, setShowEmailSection] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const fetchUserData = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("User is not authenticated.");
      return;
    }

    try {
      // Fetch user profile data
      const profileResponse = await axios.get(
        "https://admin.dialect-ai.com/api/getLabelJson",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (profileResponse.data && profileResponse.data.data.logs === "200") {
        const personalInfoData = JSON.parse(profileResponse.data.data.personal_info);
        setPersonalInfo(personalInfoData);
        setAddress(JSON.parse(profileResponse.data.data.address));
        setPreviewImage(personalInfoData.profileImage || AvatarIcon);
      }

      // Fetch connected emails
      const emailResponse = await axios.get(
        "https://admin.dialect-ai.com/api/label/email/getconnectedemails",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (emailResponse.data.status === "Success") {
        if (emailResponse.data.connected_emails.length > 0) {
          const emailData = emailResponse.data.connected_emails[0];
          setEmailData(emailData);
          setShowEmailSection(true);
        } else {
          setShowEmailSection(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const user = JSON.parse(localStorage.getItem("user")); 

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewImage(URL.createObjectURL(file)); // Update preview image
    }
  };

  const uploadImageToS3 = async (file) => {
    const userId = user.id; // Get userId from user object in local storage
    const folderName = `${userId}/images`;
    const key = `${folderName}/${file.name}`;
    const params = {
      Bucket: "dialect-marketing-userdata",
      Key: key,
      Body: file,
    };
    const data = await s3.upload(params).promise();
    return data.Location; // Return the S3 URL of the uploaded image
  };

  const handleSavePersonal = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("User is not authenticated.");
      return;
    }
    setSaveLoading(true);
    try {
      let updatedPersonalInfo = { ...personalInfo };

      if (selectedFile) {
        const s3Url = await uploadImageToS3(selectedFile);
        localStorage.setItem("labelImage", s3Url);
        updatedPersonalInfo.profileImage = s3Url; // Update personalInfo with S3 URL
      }

      const response = await axios.post(
        "https://admin.dialect-ai.com/api/setLabelPersonalInfoJson",
        {
          personal_info_json: JSON.stringify(updatedPersonalInfo),
          name: `${updatedPersonalInfo.firstName} ${updatedPersonalInfo.lastName}`,
          company_name: updatedPersonalInfo.company_name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.data.logs === "200") {
        localStorage.setItem("user", JSON.stringify({
          ...user,
          company_name: updatedPersonalInfo.company_name,
        }));
      } else {
        console.error("Failed to update personal info.");
      }
    } catch (error) {
      console.error("Error updating personal info:", error);
    } finally {
      setSaveLoading(false);
      setEditPersonal(false);
      fetchUserData();
    }
  };

  const handleSaveAddress = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("User is not authenticated.");
      return;
    }
    setSaveAdressLoading(true);
    try {
      const response = await axios.post(
        "https://admin.dialect-ai.com/api/setLabelAddressJson",
        {
          address_json: JSON.stringify(address),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.data.logs === "200") {
        console.log('Address updated')
      } else {
        console.error("Failed to update address info.");
      }
    } catch (error) {
      console.error("Error updating address info:", error);
    } finally {
      setSaveAdressLoading(false);
      setEditAddress(false);
      fetchUserData();
    }
  };

  const handleDeleteEmail = async () => {
    const token = localStorage.getItem("token");
    if (!token || !emailData?.id) {
      console.error("User is not authenticated or email data is missing.");
      return;
    }

    try {
      setDeleteLoading(true);
      const response = await axios.post(
        "https://admin.dialect-ai.com/api/label/email/deleteconnectedemails",
        {
          email_connection_id: emailData.id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status === "Success") {
        setEmailData(null);
        setShowEmailSection(false);
      } else {
        console.error("Failed to delete email connection");
      }
    } catch (error) {
      console.error("Error deleting email connection:", error);
    } finally {
      setDeleteLoading(false);
    }
  };

  const openEmailModal = () => {setIsEmailModalOpen(true);};
  const closeEmailModal = () => setIsEmailModalOpen(false);

  return (
    <Container style={{ maxHeight: 'calc(100vh - 78px)', overflowY: 'auto' }}>
      <Header>
        <Title>Profile</Title>
      </Header>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", }}>
          <CircularProgress />
        </div>
      ) : (
      <>
      <ProfileCard style={{ flexDirection: 'column' }}>
        <div style={{ display: "flex", width: "100%" }}>
          <div>
            <img src={previewImage} alt="upload" style={{ cursor: editPersonal ? 'pointer' : 'default', height: '80px', width: '80px', borderRadius: '50%' }} onClick={() => editPersonal && document.getElementById('fileInput').click()} />
            {editPersonal && (
              <input  type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} accept="image/*" />
            )}
            <img src={CameraIcon} alt="upload" style={{}} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginLeft: "2rem" }}>
            <SectionTitle>
              {personalInfo.firstName || personalInfo.lastName ? `${personalInfo.firstName} ${personalInfo.lastName}` : `${user.firstName} ${user.lastName}`}
            </SectionTitle>
            <Value>{personalInfo.bio}</Value>
            <Value>{address?.city}, {address?.country}</Value>
          </div>
          {editPersonal ? (
            <EditButton style={{ marginLeft: "auto", marginBottom: "auto", cursor: 'pointer' }} onClick={handleSavePersonal}>
              {saveLoading ? (
                <CircularProgress size={21} color="inherit" />
              ) : (
                "Save"
              )}
            </EditButton>
          ) : (
            <EditButton style={{ marginLeft: "auto", marginBottom: "auto", cursor: 'pointer' }} onClick={() => setEditPersonal(true)}>
              <img src={EditIcon} alt="edit personal info" />
              Edit
            </EditButton>
          )}
        </div>
        <SectionTitle style={{marginTop: "2rem"}}>Personal Information</SectionTitle>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "column", flex: '1'}}>
            <Label style={{ marginTop: "1.5rem" }}>First Name</Label>
            {editPersonal ? (
              <InputField value={personalInfo.firstName} onChange={(e) => setPersonalInfo({ ...personalInfo, firstName: e.target.value })} />
            ) : (
              <Value>{personalInfo.firstName || "not defined"}</Value>
            )}
            <Label style={{ marginTop: "1.5rem" }}>Company Name</Label>
            {editPersonal ? (
              <InputField value={personalInfo.company_name} onChange={(e) => setPersonalInfo({ ...personalInfo, company_name: e.target.value })} />
            ) : (
              <Value>{personalInfo.company_name || "not defined"}</Value>
            )}
            <Label style={{ marginTop: "1.5rem" }}>Designation</Label>
            {editPersonal ? (
              <InputField value={personalInfo.bio} onChange={(e) => setPersonalInfo({ ...personalInfo, bio: e.target.value })} />
            ) : (
              <Value>{personalInfo.bio || "not defined"}</Value>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column", flex: '1' }}>
            <Label style={{ marginTop: "1.5rem" }}>Last Name</Label>
            {editPersonal ? (
              <InputField value={personalInfo.lastName} onChange={(e) => setPersonalInfo({ ...personalInfo, lastName: e.target.value })} />
            ) : (
              <Value>{personalInfo.lastName || "not defined"}</Value>
            )}
            <Label style={{ marginTop: "1.5rem" }}>Phone</Label>
            {editPersonal ? (
              <InputField value={personalInfo.phone} onChange={(e) => setPersonalInfo({ ...personalInfo, phone: e.target.value })} />
            ) : (
              <Value>{personalInfo.phone || "not defined"}</Value>
            )}
          </div>
        </div>
      </ProfileCard>
      <ProfileCard style={{flexDirection: "column"}}>
        <div style={{display: "flex", flex: "1", justifyContent: "space-between", alignItems: "center"}}>
          <SectionTitle>Address</SectionTitle>
          {editAddress ? (
            <EditButton style={{ marginLeft: "auto", marginBottom: "auto", cursor: 'pointer' }} onClick={handleSaveAddress}>
              {saveAdressLoading ? (
                <CircularProgress size={21} color="inherit" />
              ) : (
                "Save"
              )}
            </EditButton>
          ) : (
            <EditButton style={{ marginLeft: "auto", marginBottom: "auto", cursor: 'pointer' }} onClick={() => setEditAddress(true)}>
              <img src={EditIcon} alt="edit" />
              Edit
            </EditButton>
          )}
        </div>
        <div style={{display: "flex"}}>
          <div style={{ display: "flex", flexDirection: "column", flex: '1' }}>
            <Label style={{ marginTop: "0.8rem" }}>Country</Label>
            {editAddress ? (
              <InputField value={address?.country || "not defined"} onChange={(e) => setAddress({ ...address, country: e.target.value })} />
            ) : (
              <Value>{address?.country || "not defined"}</Value>
            )}
            <Label style={{ marginTop: "1.5rem" }}>Postal Code</Label>
            {editAddress ? (
              <InputField  value={address?.postalCode}  onChange={(e) => setAddress({ ...address, postalCode: e.target.value })} />
            ) : (
              <Value>{address?.postalCode || "not defined"}</Value>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column", flex: '1' }}>
            <Label style={{ marginTop: "0.8rem" }}>City/State</Label>
            {editAddress ? (
              <InputField value={address?.city} onChange={(e) => setAddress({ ...address, city: e.target.value })} />
            ) : (
              <Value>{address?.city || "not defined"}</Value>
            )}
            <Label style={{ marginTop: "1.5rem" }}>Tax ID</Label>
            {editAddress ? (
              <InputField value={address?.taxId} onChange={(e) => setAddress({ ...address, taxId: e.target.value })} />
            ) : (
              <Value>{address?.taxId || "not defined"}</Value>
            )}
          </div>
        </div>
      </ProfileCard>
          <ProfileCard style={{flexDirection: "column"}}>
            <div style={{display: "flex", flex: "1", justifyContent: "space-between", alignItems: "center"}}>
              <SectionTitle>{emailData ? "Connected Email" : "Connect Your Email"}</SectionTitle>
              <div style={{display: "flex", gap: "0.5rem"}}>
                <TrainBtn style={{marginTop: "0rem"}} onClick={openEmailModal}>{emailData ? "Edit" : "Connect"}</TrainBtn>
                {emailData && (
                  <div 
                    onClick={handleDeleteEmail} 
                    style={{ 
                      cursor: 'pointer',
                      opacity: deleteLoading ? 0.5 : 1,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    {deleteLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <img src={DeleteIcon} alt="delete" />
                    )}
                  </div>
                )}
              </div>
            </div>
            {showEmailSection && (
            <div style={{display: "flex", marginTop: "1.5rem"}} id="email">
              <div style={{ display: "flex", flexDirection: "column", flex: '1' }}>
                <Label>Name</Label>
                <Value>{emailData?.name || "Not defined"}</Value>
                <Label style={{ marginTop: "1.5rem" }}>Email</Label>
                <Value>{emailData?.email || "Not defined"}</Value>
                <Label style={{ marginTop: "1.5rem" }}>SMTP Url</Label>
                <Value>{emailData?.smtp || "Not defined"}</Value>
              </div>
              <div style={{ display: "flex", flexDirection: "column", flex: '1' }}>
                <Label>SMTP Port 1</Label>
                <Value>{emailData?.["port-1"] || "Not defined"}</Value>
                <Label style={{ marginTop: "1.5rem" }}>SMTP Port 2</Label>
                <Value>{emailData?.["port-2"] || "Not defined"}</Value>
              </div>
            </div>
            )}
          </ProfileCard>
        </>
      )}
      {isEmailModalOpen && (
        <ConnectEmailModal 
          fetchUserData={fetchUserData}
          closeModal={closeEmailModal}
          emailData={emailData ? emailData : null}
        />
      )}
    </Container>
  );
};

export default UserProfile;