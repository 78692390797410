import React, { useEffect, useState, useCallback } from "react";
import { ChartDiv, Container, GridContainer, TextDiv } from "./Style";
import { GrayLine } from "../Agents/Style";
import StatsCard from "../StatsCard/StatsCard";
import { useOutletContext } from "react-router-dom";
import { typeMapping } from "../../Pages/Automations/constants";
import axios from "axios";
import AutomationChart from "../Charts/AutomationChart";

const GridItemsObj = [
  {
    cardHeader: "Total Automations",
    cardFooter: "vs preview 20 days",
    number: 15,
    percentage: 12,
  },
  {
    cardHeader: "Total Agents",
    cardFooter: "vs preview 20 days",
    number: 6,
    percentage: 3,
  },
  {
    cardHeader: "Automations in progress",
    cardFooter: "vs preview 20 days",
    number: 24,
    percentage: 8,
  },
  {
    cardHeader: "Total Users",
    cardFooter: "vs preview 20 days",
    number: 10,
    percentage: 18,
  },
];

const AgencySide = () => {
  const {userCount } = useOutletContext();
  const [inProgress, setInProgress] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [chartData, setChartData] = useState([]);



  const fetchKPIS = useCallback(async () => {
    const token = localStorage.getItem("token");
    
    const processChartData = (data) => {
      const dateSeries = {};
    
      // Process each data point in the API response
      data.forEach(item => {
        const { date, types } = item;
        types.forEach(({ type, usage }) => {
          const name = typeMapping[type]; // Map type to name
    
          // Check if name is undefined or empty
          if (!name) {
            console.error(`No mapping found for type: ${type}`);
            return; // Skip this iteration if no mapping found
          }
    
          // Initialize dateSeries for this name if not present
          if (!dateSeries[name]) {
            dateSeries[name] = [];
          }
    
          // Push date and usage to the corresponding series
          dateSeries[name].push([new Date(date).getTime(), usage]);
        });
      });
    
      // Convert to ApexChart's series format
      return Object.keys(dateSeries).map(name => ({
        name,
        data: dateSeries[name],
      }));
    };
    

    try {
      // Fetch KPIs
      const response = await axios.get(
        `https://admin.dialect-ai.com/api/dashautomations`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.completed) {
        setCompleted(response.data.completed);
        setInProgress(response.data.notCompleted);
        setTotalUsers(response.data.usercount);
      }

      // Fetch chart data
      const chartResponse = await axios.get(
        `https://admin.dialect-ai.com/api/getMostUsed`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const processedData = processChartData(chartResponse.data);
      console.log(processedData)
      setChartData(processedData);
    } catch (error) {
      console.error("Error fetching records:", error);
    }
  }, []);  // typeMapping is memoized now

  useEffect(() => {
    fetchKPIS();
  }, [fetchKPIS]);
  
  const options = {
    chart: {
      type: 'area',
      height: 350,
      stacked: true,
      events: {
        selection: function (chart, e) {
          console.log(new Date(e.xaxis.min));
        },
      },
    },
    colors: ['#008FFB', '#00E396', '#CED4DC', '#E5322D', '#FFB900'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'monotoneCubic',
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
    },
    xaxis: {
      type: 'datetime',
    },
  };

  return (
    <Container style={{marginRight: "1.5rem", maxHeight: 'calc(100vh - 78px)', overflowY: 'auto'}}>
      <TextDiv style={{marginTop: "2rem"}}>Welcome back</TextDiv>
      <GridContainer>
        {GridItemsObj.map((item, index) => (
          <StatsCard
            key={index}
            index={index}
            cardHeader={item.cardHeader}
            cardFooter={item.cardFooter}
            number={index === 0 ? userCount : item.number}
            percentage={item.percentage}
            completed={completed}
            notCompleted={inProgress}
            totalUsers={totalUsers}
          />
        ))}
      </GridContainer>
      <div style={{display:'flex', gap:'1rem', alignItems:'center', width:'100%',marginBottom: "1.5rem", marginTop: "2rem"}}>
        <TextDiv>Automation Chart</TextDiv>
        <GrayLine />
      </div>      
      <ChartDiv>
        <AutomationChart options={options} series={chartData} />
      </ChartDiv>
    </Container>
  );
};

export default AgencySide;
