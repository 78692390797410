import React, { useState, useEffect } from 'react';
import {
  AddNotesBtn,
  Container,
  Header,
} from "../../components/Agents/Style";
import { AddNoteOuterDiv, AddNoteOuterDivTitle, InputField, Title } from './Style';
import DynamicReactQuill from '../RichText/DynamicReactQuill';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress";
import SnackbarAlert from "../Alerts/SnackbarAlert";

const Edit = () => {
  const navigate = useNavigate();
  const [noteName, setNoteName] = useState('');
  const [noteText, setNoteText] = useState('');
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);  // Snackbar open state
  const [snackbarMessage, setSnackbarMessage] = useState('');  // Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');  // Snackbar severity (success/error)
  const { id } = useParams();  

  const saveNote = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      await axios.post(
        'https://admin.dialect-ai.com/api/labelupdatenote',
        {
          data: noteText,
          title: noteName,
          note_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSnackbarMessage('Note updated successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving note:', error);
      setSnackbarMessage('Failed to save the note!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true); 
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPageLoading(true);   
    const fetchNotes = async () => {
      try {
        const response = await axios.post('https://admin.dialect-ai.com/api/labelgetonenote', {
          note_id: id,
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,  // Authorization token
          },
        });
        setNoteName(response.data.usernotes.title);
        setNoteText(response.data.usernotes.data);
      } catch (error) {
        console.error('Error fetching notes:', error);
      } finally {
        setPageLoading(false); // Stop loading when data is fetched
      }
    };

    fetchNotes();
  }, [id]);

  return (
    <Container style={{ maxHeight: "calc(100vh - 78px)", overflowY: "auto" }}>
      {pageLoading?
        <div style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%', marginTop:'15rem'}}>
            <CircularProgress size={48} color="primary" />
        </div>
      :
      <>
      <Header style={{ justifyContent: 'space-between' }}>
        <Title onClick={() => navigate(`/show-notes/${id}`)}>{'< Update Notes'}</Title>
      </Header>
      <AddNoteOuterDiv>
        <AddNoteOuterDivTitle>Name your note</AddNoteOuterDivTitle>
        <InputField
          type="text"
          value={noteName}
          onChange={(e) => setNoteName(e.target.value)}
        />
        <DynamicReactQuill
          height='500px'
          marginBottom={'80px'}
          value={noteText}
          setValue={setNoteText}
        />
        <AddNotesBtn style={{ maxWidth: '70px' }} disabled={loading} onClick={saveNote}>
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Update'}
        </AddNotesBtn>
          </AddNoteOuterDiv>
        <SnackbarAlert
            open={snackbarOpen}
            setOpen={setSnackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
        />
        </>
        }
    </Container>
  );
};

export default Edit;
