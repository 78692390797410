import React, { useMemo, useState, useCallback } from "react"; 
import { useNavigate } from "react-router-dom";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import EyeIcon from "../../assets/images/eye.svg";
import DeleteIcon from "../../assets/images/delete.svg";
import Retry from "../../assets/images/restart.svg";
import Tooltip from '@mui/material/Tooltip';
import { PaginationBtn, PaginationTxt, Paginationdiv, StatusCard } from "./Style";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import SnackbarAlert from "../../components/Alerts/SnackbarAlert";
import { BLOG_POST_GENERATION, SOCIAL_MEDIA_COPY_CREATION } from "../../Pages/Automations/constants";

const BlogPostTable = ({ records, setRecords, cardTitle, cardText }) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 8,
  });
  const [loadingRowId, setLoadingRowId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const navigate = useNavigate();

  const getStatus = useCallback((response) => {
    if (!response || response === "pending") {
      return { text: "Pending", color: "#C2C3C6" };
    } else if (response.includes("Workflow not found") || response.includes("I can't handle this request")) {
      return { text: "Failed", color: "#EB4545" };
    } else {
      return { text: "Completed", color: "#0DCB68" };
    }
  }, []);
  
  const handleRetryClick = useCallback(async (record) => {
    setLoadingRowId(record.id);
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        "https://admin.dialect-ai.com/api/retrieveautomation",
        { id: record.id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );    
      const updatedRecord = response.data.record;  
      if (updatedRecord.response !== "pending") {
        setRecords((prevRecords) =>
          prevRecords.map((r) => 
            r.id === record.id ? updatedRecord : r
          )
        );
        setSnackbarMessage("Automation completed");
        setSnackbarSeverity("success");
      } else {
        setSnackbarMessage("Request is in process");
        setSnackbarSeverity("info");
      }
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Automation still in process");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }

    setLoadingRowId(null);
  }, [setRecords]);

  const handleShowClick = useCallback(async (record) => {
    setLoadingRowId(record.id);
    setLoadingRowId(null);
    navigate("/result", { state: { record: record } });
  }, [navigate]);

  const handleDeleteClick = useCallback(async (record) => {
    setLoadingRowId(record.id);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        "https://admin.dialect-ai.com/api/deleteautomation",
        { id: record.id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSnackbarMessage(response.data.data.data);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setRecords((prevRecords) =>
        prevRecords.filter((r) => r.id !== record.id)
      );
    } catch (error) {
      setSnackbarMessage("Failed to delete automation");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
    setLoadingRowId(null);
  }, [setRecords]);

  const tableKey = useMemo(() => `table-${records.length}-${records.map(r => r.id).join('-')}`, [records]);
  const showPublishDate = useMemo(() => {
    return records.length > 0 && (records[0].workflow_id === BLOG_POST_GENERATION || records[0].workflow_id === SOCIAL_MEDIA_COPY_CREATION);
  }, [records]);

  const columns = useMemo(
    () => {
      const baseColumns = [
        {
          accessorKey: "title",
          header: "Title",
          size: 150,
          Cell: () => cardTitle,
        },
        {
          accessorKey: "message",
          header: "Input",
          size: 150,
          Cell: ({ cell }) => {
            const message = cell.getValue();
            const truncatedMessage =
              message.length > 30 ? `${message.substring(0, 30)}...` : message;

            return (
              <Tooltip title={message}>
                <span>{truncatedMessage}</span>
              </Tooltip>
            );
          },
        },
        {
          accessorKey: "created_at",
          header: "Date",
          size: 150,
          Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString(),
        },
        {
          accessorKey: "status",
          header: "Status",
          size: 150,
          Cell: ({ row }) => {
            const { text, color } = getStatus(row.original.response);
            return <StatusCard style={{ background: color }}>{text}</StatusCard>
          },
        },
        {
          accessorKey: "action",
          header: "Action",
          enableSorting: false,
          Cell: ({ row }) => (
            <div style={{ display: "flex", gap: "0.5rem" }}>
              {loadingRowId === row.original.id ? (
                <CircularProgress size={20} />
              ) : (
                <>
                  {row.original.response === "pending" ? (
                    <img src={Retry} alt="retry" title="Retry" style={{ cursor: "pointer" }} onClick={() => handleRetryClick(row.original)} />
                  ) : (
                    <img src={EyeIcon} alt="show" title="Show" style={{ cursor: "pointer" }} onClick={() => handleShowClick(row.original)} />
                  )}
                  <img src={DeleteIcon} alt="delete" title="Delete" style={{ cursor: "pointer" }} onClick={() => handleDeleteClick(row.original)} />
                </>
              )}
            </div>
          ),
          size: 100,
        },
      ];

      if (showPublishDate) {
        baseColumns.splice(3, 0, {
          accessorKey: "publish_date",
          header: "Publish Date",
          size: 150,
          Cell: ({ cell }) => {
            const publishDate = cell.getValue();
            return publishDate
              ? new Date(publishDate).toLocaleDateString()
              : "N/A";
          },
        });
      }
      return baseColumns;
    },
    [loadingRowId, cardTitle, handleDeleteClick, handleShowClick, getStatus, handleRetryClick, showPublishDate]
  );

  const table = useMaterialReactTable({
    columns,
    data: records,
    muiPaginationProps: {
      showRowsPerPage: false,
    },
    enableTopToolbar: false,
    enableColumnActions: false,
    muiTableHeadCellProps: {
      sx: {
        padding: "0.75rem 1.5rem",
        color: "#202020",
        fontSize: "0.75rem",
        fontWeight: "600",
        backgroundColor: "#F9FAFB",
        boxShadow: "none",
        fontFamily: "Poppins",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        padding: "1rem 1.5rem",
        color: "#202020",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        fontFamily: "Poppins",
        fontWeight: "400",
      },
    },
    onPaginationChange: setPagination,
    state: { pagination },
    renderBottomToolbar: ({ table }) => {
      return (
        <Paginationdiv>
          <PaginationBtn
            onClick={() =>
              setPagination((prev) => ({
                pageIndex:
                  prev.pageIndex > 0 ? prev.pageIndex - 1 : prev.pageIndex,
                pageSize: prev.pageSize,
              }))
            }
          >
            Previous
          </PaginationBtn>
          <PaginationTxt>
            Page {pagination.pageIndex + 1} of{" "}
            {Math.ceil(records.length / pagination.pageSize)}
          </PaginationTxt>
          <PaginationBtn
            onClick={() =>
              setPagination((prev) => ({
                pageIndex:
                  prev.pageIndex + 1 ===
                  Math.ceil(records.length / pagination.pageSize)
                    ? prev.pageIndex
                    : prev.pageIndex + 1,
                pageSize: prev.pageSize,
              }))
            }
          >
            Next
          </PaginationBtn>
        </Paginationdiv>
      );
    },
  });

  return (
    <>
      <MaterialReactTable key={tableKey} table={table} />
      <SnackbarAlert open={snackbarOpen} setOpen={setSnackbarOpen} message={snackbarMessage} severity={snackbarSeverity} />
    </>
  );
};

export default BlogPostTable;
