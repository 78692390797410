import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ContentDiv,
  ProfileSection,
  ProfileName,
  PortalUser,
  SidebarContainer,
  ProfileWebsite,
  UpperMenu,
  MenuItem,
  MenuText,
  LowerMenu,
  LogoutDiv,
  AddUser,
  AddText,
  UsersDiv,
  Popup,
  PopupItem,
} from "./Style";
import ClientModal from "../Modals/ClientModal";
import User from "../../assets/images/user.svg";
import ExpandIcon from "../../assets/images/expand-icon.svg";
import Divider from "../../assets/images/divider.svg";
import Category from "../../assets/images/category.svg";
import Category_white from "../../assets/images/category-white.svg";
import Calendar from "../../assets/images/calendar.svg";
import Calendar_white from "../../assets/images/calendar-white.svg";
import Activity from "../../assets/images/activity.svg";
import Activity_white from "../../assets/images/activity-white.svg";
import Note from "../../assets/images/note.svg";
import Note_white from "../../assets/images/Note-white.svg";
import Logout from "../../assets/images/logout.svg";
import CardIcon from "../../assets/images/card.svg";
import Card_white from "../../assets/images/card-white.svg";
import Plus from "../../assets/images/plus-icon.svg";
import SettingIcon from "../../assets/images/setting-icon.svg";
import SettingsModal from "../Modals/SettingsModal";
import NotesSvg from '../../assets/images/ClientNotes.svg'
import WhiteNotesSvg from '../../assets/images/ClientNotesWhite.svg'
import { AGENCY_AGENT } from "../../constants/Text";

const UpperMenuItems = [
  { icon: Category, iconWhite: Category_white, text: "Overview", route: "/" },
  {
    icon: Calendar,
    iconWhite: Calendar_white,
    text: "AI Team",
    route: "/agents",
  },
  {
    icon: Activity,
    iconWhite: Activity_white,
    text: "Automations",
    route: "/automations",
  },
  {
    icon: Calendar,
    iconWhite: Calendar_white,
    text: AGENCY_AGENT,
    route: "/train-agent",
  },
  {
    icon: NotesSvg,
    iconWhite: WhiteNotesSvg,
    text: "Client Notes",
    route: "/notes",
  },    
  {
    icon: Note,
    iconWhite: Note_white,
    text: "Social Calendar",
    route: "/socialcalendar",
  },    
  {
    icon: CardIcon,
    iconWhite: Card_white,
    text: "Payment",
    route: "/addcard",
  },
];

const Sidebar = ({ users, selectedUser, setSelectedUser, profileUpdated, setProfileUpdated }) => {
  const navigate = useNavigate();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isUsersDivVisible, setIsUsersDivVisible] = useState(false);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [openPopupId, setOpenPopupId] = useState(null); // State to track which user's popup is open 
  const [modalUser, setModalUser] = useState(null); // State to track user data for the modal
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [userForSettings, setUserForSettings] = useState(null); // State to track the user for the settings modal

  const handleClick = (route) => {
    if (route) {
      navigate(route);
    }
  };

  const handleProfileClick = () => {
    setIsUsersDivVisible(!isUsersDivVisible);
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    localStorage.setItem("selectedUserId", user.id); 
    localStorage.setItem("selectedUserName", user.name);
    setIsUsersDivVisible(false); 
    setOpenPopupId(null); 
    setProfileUpdated(true);
  };

  const openClientModal = () => {
    setModalUser(null); 
    setIsClientModalOpen(true);
  };

  const closeClientModal = () => setIsClientModalOpen(false);

  const openSettingsModal = (user) => {
    setUserForSettings(user);
    setIsSettingsOpen(true);
  };
  const closeSettingsModal = () => setIsSettingsOpen(false);
  
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div style={{display:'flex'}}>
    <SidebarContainer>
      <ContentDiv>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "stretch",
          }}
        >
          <ProfileSection onClick={handleProfileClick}>
            <img
              src={User}
              alt="Client"
              style={{ width: "2.5rem", height: "2.5rem" }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "0.62rem",
              }}
            >
              <ProfileName>{selectedUser?.name}</ProfileName>
              <ProfileWebsite>{selectedUser?.email}</ProfileWebsite>
            </div>
            <img src={ExpandIcon} alt="expand" style={{ marginLeft: "auto" }} />
          </ProfileSection>
          {isUsersDivVisible && (
            <UsersDiv>
              {users.map((user) => (
                <PortalUser key={user.id} style={{ position: "relative" }}>
                  <div
                    style={{ display: "flex" }}
                    onClick={() => handleUserSelect(user)}
                  >
                    <img
                      src={User}
                      alt="Client"
                      style={{ width: "2.5rem", height: "2.5rem" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "0.62rem",
                      }}
                    >
                      <ProfileName>{user.name}</ProfileName>
                      <ProfileWebsite>{user.email}</ProfileWebsite>
                    </div>
                  </div>
                  <img
                    src={SettingIcon}
                    alt="settings"
                    style={{ marginLeft: "auto", cursor: "pointer" }}
                    // onClick={() =>
                    //   setOpenPopupId(openPopupId === user.id ? null : user.id)
                    //   // setSettingsOpen(openPopupId === user.id ? null : user.id)
                    // } // Toggle popup
                    onClick={() => openSettingsModal(user)}
                  />
                  <Popup isOpen={openPopupId === user.id}>
                    <PopupItem>Select User</PopupItem>

                    {/* Open modal for editing */}
                  </Popup>
                </PortalUser>
              ))}
              <img src={Divider} alt="divider" />
            </UsersDiv>
          )}
          <AddUser onClick={openClientModal}>
            <img src={Plus} alt="add" />
            <AddText>Add new client</AddText>
          </AddUser>
        </div>
        <img src={Divider} alt="divider" style={{ width: "15.5rem", margin: "1rem 0rem" }} />
        <UpperMenu>
          {UpperMenuItems.map((item, index) => (
            <MenuItem
              key={index}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              onClick={() => handleClick(item.route)}
              isHovered={hoveredIndex === index}
            >
              <img
                src={hoveredIndex === index ? item.iconWhite : item.icon}
                alt={item.text}
              />
              <MenuText
                isHovered={hoveredIndex === index}
                style={{ marginLeft: "1rem" }}
              >
                {item.text}
              </MenuText>
            </MenuItem>
          ))}
        </UpperMenu>
      </ContentDiv>
      <ContentDiv style={{ marginTop: "auto" }}>
        <LowerMenu>
          <LogoutDiv onClick={handleLogout}>
            <img src={Logout} alt="Overview" />
            <MenuText style={{ marginLeft: "1rem" }}>Log Out</MenuText>
          </LogoutDiv>
        </LowerMenu>
      </ContentDiv>
      {isClientModalOpen && (
        <ClientModal closeModal={closeClientModal} user={modalUser} />
      )}{" "}
      {isSettingsOpen && (
        <SettingsModal
          user={userForSettings}
          closeSettingModal={closeSettingsModal}
          closeSettingsModal={closeSettingsModal} // Close settings modal
        />
      )}
    </SidebarContainer>
    <div style={{width:'1px', height:'calc(100vh - 5rem)', background:'#EDEDED'}} />
    </div>
  );
};

export default Sidebar;
