import React, { useMemo, useState, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import ChatIcon from "../../assets/images/chat.svg";
import DeleteIcon from "../../assets/images/delete.svg";
import CircularProgress from "@mui/material/CircularProgress";
import SnackbarAlert from "../Alerts/SnackbarAlert";
import { PaginationBtn, PaginationTxt, Paginationdiv } from "./Style";

const AvatarTable = ({ characters, token }) => {
  const [records, setRecords] = useState(characters);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 8,
  });
  const [loadingId, setLoadingId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const navigate = useNavigate();

  const handleDelete = async (record) => {
    setLoadingId(record.id);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        "https://admin.dialect-ai.com/api/deleteCharacter",
        { character_id: record.id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSnackbarMessage(response.data.data.message);
      setSnackbarSeverity("success");
      setSnackbarOpen(true); 
      setRecords((prevRecords) => prevRecords.filter((character) => character.id !== record.id));
    } catch (error) {
      setSnackbarMessage("Failed to delete Avatar");
      setSnackbarSeverity("error");
      setSnackbarOpen(true); 
    } 
    setLoadingId(null);
  };

  const handleChatClick = useCallback((avatar_id) => {
    navigate(`/avatarchat?`, { state: { avatar_id } });
  }, [navigate]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 150,
        Cell: ({ cell }) => cell.getValue(),
      },
      {
        accessorKey: "gender",
        header: "Gender",
        size: 150,
        Cell: ({ cell }) => cell.getValue(),
      },
      {
        accessorKey: "age",
        header: "Age",
        size: 150,
        Cell: ({ cell }) => cell.getValue(),
      },
      {
        accessorKey: "action",
        header: "Action",
        enableSorting: false,
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "0.5rem" }}>
            {loadingId === row.original.id ? (
              <CircularProgress size={24} />
            ) : (
              <img
                src={DeleteIcon}
                alt="delete"
                title="Delete"
                style={{ cursor: "pointer" }}
                onClick={() => handleDelete(row.original)}
              />
            )}
            <img
              src={ChatIcon}
              alt="Chat"
              title="Chat with Avatar"
              style={{ cursor: "pointer" }}
              onClick={() => handleChatClick(row.original.avatar_id)}
            />
          </div>
        ),
        size: 100,
      },
    ],
    [loadingId, handleChatClick]
  );

  const table = useMaterialReactTable({
    columns,
    data: records,
    muiPaginationProps: {
      showRowsPerPage: false,
    },
    enableTopToolbar: false,
    enableColumnActions: false,
    muiTableHeadCellProps: {
      sx: {
        padding: "0.75rem 1.5rem",
        color: "#202020",
        fontSize: "0.75rem",
        fontWeight: "600",
        backgroundColor: "#F9FAFB",
        boxShadow: "none",
        fontFamily: "Poppins",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        padding: "1rem 1.5rem",
        color: "#202020",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        fontFamily: "Poppins",
        fontWeight: "400",
      },
    },
    onPaginationChange: setPagination,
    state: { pagination },
    renderBottomToolbar: ({ table }) => (
      <Paginationdiv>
        <PaginationBtn
          onClick={() =>
            setPagination((prev) => ({
              pageIndex: prev.pageIndex > 0 ? prev.pageIndex - 1 : prev.pageIndex,
              pageSize: prev.pageSize,
            }))
          }
        >
          Previous
        </PaginationBtn>
        <PaginationTxt>
          Page {pagination.pageIndex + 1} of{" "}
          {Math.ceil(records.length / pagination.pageSize)}
        </PaginationTxt>
        <PaginationBtn
          onClick={() =>
            setPagination((prev) => ({
              pageIndex:
                prev.pageIndex + 1 === Math.ceil(records.length / pagination.pageSize)
                  ? prev.pageIndex
                  : prev.pageIndex + 1,
              pageSize: prev.pageSize,
            }))
          }
        >
          Next
        </PaginationBtn>
      </Paginationdiv>
    ),
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default AvatarTable;
