import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import SnackbarAlert from "../../components/Alerts/SnackbarAlert";
import {
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalButton,
  DragAndDrop,
  IconDiv,
  UploadText,
  SupportText,
} from "./Style";
import UploadIcon from "../../assets/images/upload.svg";
import s3 from "../Environment/Asconfig"; // Import your S3 configuration

const TrainModal = ({ closeModal, agentName='Name', agentDesc='Desc'}) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  // const [isDragActive, setIsDragActive] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (
      selectedFile &&
      ["application/pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/msword", "text/plain"].includes(selectedFile.type)
    ) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
      // setIsDragActive(false);
    } else {
      alert("Please upload a valid file type (.pdf, .doc, .xlsx, .txt)");
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    // setIsDragActive(false);
    const selectedFile = event.dataTransfer.files[0];
    if (
      selectedFile &&
      ["application/pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/msword", "text/plain"].includes(selectedFile.type)
    ) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    } else {
      alert("Please upload a valid file type (.pdf, .doc, .xlsx, .txt)");
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    // setIsDragActive(true);
  };

  const handleDragLeave = () => {
    // setIsDragActive(false);
  };

  const handleUpload = async () => {
    if (file) {
      setLoading(true); // Set loading to true when the API call starts
      const userId = localStorage.getItem("selectedUserId");
      const selectedUserId = localStorage.getItem("selectedUserId");
      const token = localStorage.getItem("token");
      const folderName = `${userId}/documents`;
      const key = `${folderName}/${file.name}`;
      const params = {
        Bucket: "dialect-marketing-userdata",
        Key: key,
        Body: file,
      };

      try {
        const data = await s3.upload(params).promise();
        const dataLocation = data.Location;
        let dataType = "";
        const fileExtension = file.name.split(".").pop();
        switch (fileExtension) {
          case "pdf":
            dataType = "pdf";
            break;
          case "xlsx":
            dataType = "xlsx";
            break;
          case "doc":
          case "docx":
            dataType = "docx";
            break;
          case "txt":
            dataType = "txt";
            break;
          default:
            dataType = "unknown";
        }

        // Prepare the payload
        const payload = {
          user_id: selectedUserId,
          data: [
            {
              data_type: dataType,
              data_key: dataLocation,
            },
          ],
          retrain: 0,
          agent_name:agentName,
          agent_description: agentDesc        
        };

        const response = await fetch("https://admin.dialect-ai.com/api/labelmodelmade", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error("Failed to send POST request to the API");
        }
        setSnackbarMessage("File uploaded successfully");
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
          closeModal();
        }, 2000); 
      } catch (err) {
        console.error("Error uploading file:", err);
        setSnackbarMessage("Error uploading file");
        setSnackbarOpen(true);
      } finally {
        setLoading(false); 
      }
    }
  };

  return (
    <ModalOverlay>
      <ModalContent style={{ alignItems: "center" }}>
        <ModalHeader>Train</ModalHeader>
        <DragAndDrop
          onClick={() => document.getElementById("fileInput").click()}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          <input
            id="fileInput"
            type="file"
            accept=".pdf,.doc,.xlsx,.txt"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <IconDiv>
            <img src={UploadIcon} alt="upload" />
          </IconDiv>
          <UploadText>
            Drag and drop files, or
            <span style={{ color: "#2F80ED" }}> Browse</span>
          </UploadText>
          <SupportText>Support pdf, doc, xlxs, txt files</SupportText>
          {fileName && <div style={{ marginTop: "1rem" }}>{fileName}</div>}
        </DragAndDrop>
        <div style={{ display: "flex", gap: "0.75rem", alignSelf: "stretch" }}>
          <ModalButton onClick={closeModal}>Cancel</ModalButton>
          <ModalButton
            style={{ background: "#FF8900", color: "#FFF" }}
            onClick={handleUpload}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={21} color="inherit" />
            ) : (
              "Train"
            )}
          </ModalButton>
        </div>
      </ModalContent>
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarMessage === "File uploaded successfully" ? "success" : "error"}
      />
    </ModalOverlay>
  );
};

export default TrainModal;