import Copy from '../../assets/images/CopyCreator.svg';
import SEO from '../../assets/images/SEOCheck.svg';
import ST from '../../assets/images/StoryTeller.svg';
import DA from '../../assets/images/DataAnalyst.svg';
import Avatar from '../../assets/images/ClientAvatar.svg';
import Agent from '../../assets/images/ClinetAgent.svg';

export const StaticAgents = [
    {
      cardTitle: "Copy creator",
      cardText: "I will generate the copy for social media",
      img:Copy
    },
    {
      cardTitle: "SEO Agent",
      cardText: "I will do the SEO analysis of the content",
      img:SEO
    },
    {
      cardTitle: "Story Teller",
      cardText: "I am the best story teller agent",
      img:ST
    },
    {
      cardTitle: "Data Analysist",
      cardText: "I will analyze your data to provide insights",
      img:DA
    },
];
  
export const ClientAgents = [
    {
      cardTitle: "Client Avatar",
      cardRoute: "/createavatar",
      img: Avatar,
      isTrained: false
    },
    {
      cardTitle: "Client Agent",
      cardRoute: "/train-agent",
      img: Agent,
      isTrained: true
    },
];