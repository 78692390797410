import React, { useEffect, useState, useCallback  } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import Holidays from "date-holidays";
import "react-big-calendar/lib/css/react-big-calendar.css";
import MeetingModal from "../Modals/MeetingModal";
import axios from "axios";
import { Container, Header, Title, SubTitle } from "../../components/Agents/Style";
import { TimezoneContainer, TimezoneSelect, Option, HeaderButton} from "./Style";
import CommentIcon from "../../assets/images/comment.svg";
import { SELECT_TIMEZONE, SOCIAL_CALENDAR } from "../../constants/Text";
import AddEventModal from "../Modals/AddEventModal";
import { BLOG_POST_GENERATION, SOCIAL_MEDIA_COPY_CREATION } from "../../Pages/Automations/constants";
const localizer = momentLocalizer(moment);

const SocialCalendar = () => {
  const { profileUpdated, setProfileUpdated } = useOutletContext();
  const [isMeetingModalOpen, setIsMeetingModalOpen] = useState(false);
  const [event, setEvent] = useState(null);
  const [events, setEvents] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState("NL"); // Default timezone
  const [timezones, setTimezones] = useState([]);
  const [isAddEventOpen, setIsAddEventOpen] = useState(false);

  const fetchEvents = useCallback(async () => {
    try {
      const token = localStorage.getItem('token'); // Fetch token from localStorage
      const userId = localStorage.getItem('selectedUserId');
      const userEmail = localStorage.getItem('selectedUserEmail');
      const payload1 = {
        type: BLOG_POST_GENERATION,
        client_id: userId,
      };
      const payload2 = {
        type: SOCIAL_MEDIA_COPY_CREATION,
        client_id: userId,
      };

      // Make the two API calls
      const [response1, response2] = await Promise.all([
        axios.post(`https://admin.dialect-ai.com/api/allclientautomations`, payload1, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        axios.post(`https://admin.dialect-ai.com/api/allclientautomations`, payload2, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      ]);

      // Extract the records and map them to the events format
      const allEvents = [...response1.data.records, ...response2.data.records].map(record => ({
        id: record.id,
        title: record.workflow_id === BLOG_POST_GENERATION ? "Blog Post" : record.workflow_id === SOCIAL_MEDIA_COPY_CREATION ? "Social Media Copy" : record.title,    
        name: record.message,
        description: record.workflow_id === BLOG_POST_GENERATION ? record.response : record.workflow_id === SOCIAL_MEDIA_COPY_CREATION ? record.response : record.description,
        start: new Date(record.publish_date), 
        end: new Date(record.publish_date),
        allDay: true,
        comments: "", 
        interaction: record.interacted,
        userEmail: userEmail,
        images: record.images,
        videos_link: record.videos_link,
        record: record
      }));

      setEvents(allEvents); // Set the events from API
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  }, []); // Added useCallback to ensure stable reference

  useEffect(() => {
    fetchEvents(); // Call fetchEvents inside useEffect to initialize the data on component mount
    setProfileUpdated(false); 
  }, [fetchEvents, profileUpdated, setProfileUpdated]);

  useEffect(() => {
    // Fetch all supported countries and regions from the Holidays package
    const hd = new Holidays();
    const availableTimezones = [];

    const countries = hd.getCountries();
    Object.keys(countries).forEach((countryCode) => {
      const regions = hd.getStates(countryCode);
      if (regions) {
        Object.keys(regions).forEach((regionCode) => {
          availableTimezones.push({
            label: `${countries[countryCode]} (${regions[regionCode]})`,
            value: `${countryCode}/${regionCode}`,
          });
        });
      } else {
        availableTimezones.push({
          label: countries[countryCode],
          value: countryCode,
        });
      }
    });

    setTimezones(availableTimezones);
  }, []);

  useEffect(() => {
    const fetchHolidays = () => {
      const hd = new Holidays();
      const [country, state] = selectedTimezone.split("/");

      hd.init(country, state); 

      const currentYear = new Date().getFullYear();
      const fetchedHolidays = hd.getHolidays(currentYear).map((holiday) => ({
        id: holiday.date,
        title: holiday.name,
        start: new Date(holiday.start),
        end: new Date(holiday.end),
        allDay: true,
        description: holiday.type,
        comments: "Holiday",
      }));

      setHolidays(fetchedHolidays);
    };

    fetchHolidays();
  }, [selectedTimezone]);

  const combinedEvents = [...events, ...holidays];

  const handleSelectEvent = (event) => {
    setEvent(event);
    setIsMeetingModalOpen(true);
  };

  const handleCloseEventDetailModal = () => {
    setIsMeetingModalOpen(false);
    setEvent(null);
  };

  const dayPropGetter = (date) => {
    const isHoliday = holidays.some(
      (holiday) =>
        moment(holiday.start).isSame(date, "day") ||
        moment(holiday.end).isSame(date, "day")
    );
    if (isHoliday) {
      return {
        style: {
          backgroundColor: "#FFF3E0", 
        },
      };
    }
    return {};
  };

  const handleTimezoneChange = (e) => {
    setSelectedTimezone(e.target.value); 
  };

  const openAddEventModal = () => {setIsAddEventOpen(true);};
  const closeAddEventModal = () => setIsAddEventOpen(false);
  
  return (
    <Container
      style={{
        maxHeight: "calc(100vh - 78px)",
        overflowY: "auto",
      }}
    >
      <Header style={{ marginBottom: "1.25rem" }}>
        <Title>{SOCIAL_CALENDAR}</Title>
        <TimezoneContainer>
          <SubTitle htmlFor="timezone">{SELECT_TIMEZONE}</SubTitle>
          <TimezoneSelect id="timezone" value={selectedTimezone} onChange={handleTimezoneChange}>
            <Option disabled>Select a timezone</Option>
            {timezones.map((zone) => (
              <Option key={zone.value} value={zone.value}>
                {zone.label}
              </Option>
            ))}
          </TimezoneSelect>
        </TimezoneContainer>
        <HeaderButton onClick={() => openAddEventModal()}>Add Event</HeaderButton>
      </Header>
      <Calendar
        className="h-[850px]"
        localizer={localizer}
        events={combinedEvents}
        startAccessor="start"
        endAccessor="end"
        views={["month", "week", "day", "agenda"]}
        formats={{
          dayFormat: "dd",
          monthHeaderFormat: "MMMM yyyy",
        }}
        onSelectEvent={handleSelectEvent}
        components={{
          event: CustomEvent, 
        }}
        dayPropGetter={dayPropGetter}
        style={{ padding: "1rem" }}
      />
      {isMeetingModalOpen && (
        <MeetingModal closeModal={handleCloseEventDetailModal} event={event} fetchEvents={fetchEvents}/>
      )}
      <style>
        {`
            .rbc-day-slot .rbc-event, .rbc-event, .rbc-day-slot .rbc-background-event, .rbc-event.rbc-selected {
                background-color: #FF8900;
                border: none;
            }
        `}
      </style>
      {isAddEventOpen && (
        <AddEventModal closeAddEventModal= {closeAddEventModal} fetchEvents={fetchEvents}/>
      )}
    </Container>
  );
};

export default SocialCalendar;

const CustomEvent = ({ event }) => {
  const handleCommentClick = (e) => {
    e.stopPropagation(); 
    console.log("Comments:", event.comments);
  };

  return (
    <div style={{ display: "flex" }}>
      <span>
        {event.title.length > 15
          ? `${event.title.substring(0, 15)}...`
          : event.title}
      </span>
      {event.comments !== "Holiday" && (
        <div style={{ display: "flex", marginLeft: "auto", marginRight: "0.3rem" }}>
          <img
            src={CommentIcon}
            alt="Comment"
            style={{ marginTop: "0.15rem" }}
            onClick={handleCommentClick}
          />
          <span>{event.comments}</span>
        </div>
      )}
    </div>
  );
};
