import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 1000;
`;

export const ProfileDiv = styled.div`
  display: flex;
  gap: 0.88rem;
  margin-left: auto;
`;

export const ProfileTxt = styled.div`
  color: #313133;
  font-family: Inter;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.055rem;
`;

export const Popup = styled.div`
  position: absolute;
  top: 4rem;
  right: 1rem;
  background: #fff;
  border: 1px solid #dcdbdd;
  border-radius: 0.75rem;
  padding: 0.2rem;
  z-index: 1001;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

export const PopupOption = styled.div`
  padding: 0.55rem 2rem;
  font-family: Poppins;
  font-size: 0.875rem;
  color: #202020;
  cursor: pointer;
    white-space: pre;
  &:hover {
    background: #f0f0f0;
  }
`;