import styled, { keyframes } from "styled-components";

export const SidebarContainer = styled.div`
  min-width: 18.5rem;
  height: 85vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
`;

export const LogoDiv = styled.div`
  display: flex;
  gap: 0.62rem;
  margin-left: 2.5rem;
`;

export const LogoText = styled.div`
  color: var(--Neutral-100, #101010);
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 2.25rem */
`;

export const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
  margin-left: 1rem;
`;

export const ProfileSection = styled.div`
  display: flex;
  padding: 0.75rem 1.5rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background: #f2f4f8;
  cursor: pointer;
  &:hover {
    background: #d9dce3;
  }
`;

export const UsersDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 14rem;
  overflow: scroll;
  scrollbar-width: none;
`;
export const PortalUser = styled.div`
  display: flex;
  padding: 0.75rem 1.5rem;
  background: #fafafa;
  cursor: pointer;
  &:hover {
    background: #d9dce3;
  }
`;

export const AddText = styled.div`
  color: #ff8900;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
`;

export const AddUser = styled.div`
  display: flex;
  padding: 0.875rem 0rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  cursor: pointer;
  &:hover ${AddText} {
    text-decoration: underline;
  }
`;

export const ProfileName = styled.div`
  color: var(--Gray-900, #101828);
  font-family: "Plus Jakarta Sans";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
`;

export const ProfileWebsite = styled.div`
  color: var(--Gray-500, #667085);
  font-family: "Plus Jakarta Sans";
  font-size: 0.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
`;

export const UpperMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

export const MenuItem = styled.div`
  display: flex;
  padding: 1rem 1.5rem;
  border-radius: ${(props) => (props.isHovered ? "0.5rem" : "0")};
  background: ${(props) =>
    props.isHovered ? "var(--Primary-Main, #FF8900)" : "transparent"};
  transition: background 0.3s ease, color 0.3s ease;
  cursor: pointer;
`;
export const MenuText = styled.div`
  color: ${(props) =>
    props.isHovered
      ? "var(--Neutral-10, #FFF)"
      : "var(--Neutral-100, #101010)"};
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
`;

export const LowerMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

export const LogoutDiv = styled.div`
  display: flex;
  padding: 1rem 1.5rem;
  transition: background 0.3s ease, color 0.3s ease;
  cursor: pointer;
  margin-top: 1.12rem;
  border-radius: 0.5rem;
  border: 1px solid var(--Neutral-40, #d6d6d6);
  margin-left: 1rem;
  margin-right: 1rem;

  &:hover {
    background: #f2f4f8;
  }
`;

export const Popup = styled.div`
  position: absolute;
  top: -7.1rem;
  right: 1rem;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 10; /* Ensure the popup is above other content */
  padding: 0.5rem;
  width: 12rem; /* Adjust width as needed */
  border-radius: 0.5rem;
  display: ${(props) =>
    props.isOpen
      ? "block"
      : "none"}; /* Conditionally show/hide based on isOpen prop */
`;

export const PopupItem = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

//Automation Sidebar
// export const slideIn = keyframes`
//   from {
//     transform: translateX(100%);
//   }
//   to {
//     transform: translateX(0);
//   }
// `;
const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  width: 41rem;
  height: 90vh;
  padding: 2rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  border-radius: 2rem 0rem 0rem 2rem;
  background: #fbfcfc;
  margin-left: auto;
  position: fixed;
  top: 0;
  right: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  transform: translateX(100%);
  animation: ${slideIn} 0.5s forwards;
`;

export const Header = styled.div`
  color: #202020;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.625rem; /* 144.444% */
`;
export const SubHeader = styled.div`
  color: #84818a;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const InputContainer = styled.textarea`
  height: 54.75rem;
  padding: 1rem;
  border-radius: 0.75rem;
  border: 1px solid #dcdbdd;
  background: var(--Brand-White, #fff);
  color: #47464a;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0.5rem;
  resize: none;
`;

export const DateInput = styled.input`
  display: flex;
  padding: 0.625rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.75rem;
  border: 1px solid #dcdbdd;
  background: #fff;
  color: #47464a;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  margin-top: 0.5rem;
  cursor: pointer;

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 0.8rem; /* Adjust this value to move the icon to the left */
    cursor: pointer;
    opacity: 0.7;
    &:hover {
      opacity: 0.5;
    }
  }
`;

export const RunButton = styled.div`
  margin-top: 1.5rem;
  margin-left: auto;
  display: inline-flex;
  padding: 0.75rem 1.1875rem;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  border-radius: 0.5rem;
  border: 1px solid #ff8900;
  background: #ff8900;
  color: var(--Neutral-10, var(--Brand-White, #fff));
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem; /* 112.5% */
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const Input = styled.div`
  color: #47464a;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative; /* Added to position dropdown */
`;

export const FileDiv = styled.div`
  display: flex;
  padding: 0.5rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.375rem;
  border: 1px solid #dcdbdd;
  background: var(--Brand-White, #fff);
`;

export const FileTxt = styled.div`
  color: #84818a;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const KeywordInput = styled.input`
  display: flex;
  padding: 0.625rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.75rem;
  border: 1px solid #dcdbdd;
  background: #fff;
  color: #47464a;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  width: 100%;
`;

export const KeywordDiv = styled.div`
  display: flex;
  padding: 0.625rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.75rem;
  border: 1px solid #dcdbdd;
  background: #E8E8E8;
  color: #47464a;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  width: 100%;
`;
