import BGP from '../../assets/images/BlogPG.svg';
import SEO from '../../assets/images/SEOCheck.svg';
import Persona from '../../assets/images/Persona.svg';
import Copy from '../../assets/images/CopySplit.svg';
import CopyCreation from "../../assets/images/copy-creation.svg";
import WCC from '../../assets/images/WCC.svg';
import POE from '../../assets/images/POE.svg';

export const mapAutomationData = (automation) => {
    switch (automation.id) {
        case 41:
            return {
                type: 41,
                cardTitle: 'Blog Post Generation',
                cardText: 'This Automation Will Help You To Generate The Blog Post Based On Provided Topic',
                image: BGP,
                tag: "Story Teller",
            };
        case 55:
            return {
                type: 55,
                cardTitle: 'SEO Check',
                cardText: 'This automation will do the SEO analysis of the content and respond with the comprehensive report',
                image: SEO,
                tag: "SEO Agent",
            };
        case 43:
            return {
                type: 43,
                cardTitle: 'Check by Persona',
                cardText: 'This automation will verify the provided content',
                image: Persona,
                tag: "Data Analyst",
            };
        case 45:
            return {
                type: 45,
                cardTitle: 'Copy Splitting',
                cardText: 'This automation will split the general copy into platform specific copies',
                image: Copy,
                tag: "Copy Creator",
            };
        case 74:
            return {
                type: 74,
                cardTitle: 'Social Media Copy Creation',
                cardText: 'This automation will generate the copy for social media',
                image: CopyCreation,
                tag: "Copy Creator",
            };
        case 84:
            return {
                type: 84,
                cardTitle: 'Webpage Copy Generation',
                cardText: 'This automation will create webpage content if it is given page name, instructions and keywords.',
                image: WCC,
                tag: "Copy Creator",
            };
        case 85:
            return {
                type: 85,
                cardTitle: 'Purpose for Existence Document',
                cardText: 'This automation generates a "purpose of existence" document given text about a product or idea.',
                image: POE,
                tag: "Story Teller",
            };
        default:
            return null;
    }
};

export const BLOG_POST_GENERATION = 41;
export const SEO_CHECK = 55;
export const CHECK_BY_PERSONA = 43;
export const COPY_SPLITTING = 45;
export const SOCIAL_MEDIA_COPY_CREATION = 74;
export const WEBPAGE_COPY_GENERATION = 84;
export const PURPOSE_FOR_EXISTENSE_DOCUMENT = 85;

export const typeMapping = {
    41: 'Blog Post Generation',
    55: 'SEO Check',
    43: 'Check by Persona',
    45: 'Copy Splitting',
    74: 'Social Media copy creation'
}

export const AUTOMATIONS_DATA = [
    {
        name: 'Blog Post Generation',
        desc: 'This Automation Will Help You To Generate The Blog Post Based On Provided Topic',
        id: 41
    },
    {
        name: 'SEO Check',
        desc: 'This automation will do the SEO analysis of the content and respond with the comprehensive report.',
        id: 55
    },
    {
        name: 'Check by Persona',
        desc: 'This automation will verify the provided content',
        id: 43
    },
    {
        name: 'Copy Splitting',
        desc: 'This automation will split the general copy into platform specific copies',
        id: 45
    },
    {
        name: 'Social Media copy creation',
        desc: 'This automation will generate the copy for social media',
        id: 74
    },
];

export const COPY_OPTIONS = [
    {
        id: 1,
        option: "Copy Rich Text"
    },
    {
        id: 2,
        option: "Simple Copy"
    }
]

