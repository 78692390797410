import styled from "styled-components";

export const CardContainer = styled.div`
display: flex;
flex-direction: column;
padding: 1.37rem;
width: 12.5725rem;
height: 8.635rem;
border-radius: 0.5625rem;
border: 1px solid #E6E6E6;
background: #FFF;
box-shadow: 0px 3px 20.1px 0px rgba(0, 0, 0, 0.06);
`
export const Header = styled.div`
display: flex;
`

export const CardText = styled.div`
color: #0B1B33;
font-family: Poppins;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.015rem;
`
export const Number = styled.div`
color: #0B1B33;
font-family: Poppins;
font-size: 3.25rem;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.065rem;
`
export const Percentage = styled.div`
width: fit-content;
margin-top: 0.13rem;
border-radius: 2.375rem;
background: #FF8900;
padding: 0.0625rem 0.5rem;
color: #FFF;
font-family: Poppins;
font-size: 0.625rem;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.0125rem;
`
