import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "../Pages/LandingPage/LandingPage";
import AgencySide from "../components/AgencySide/AgencySide";
import Agents from "../components/Agents/Agents";
import Login from "../Pages/Auth/Login";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import UserProfile from "../components/Profile/UserProfile";
import ProtectedRoute from "../components/ProtectedRoutes/ProtectedRoute";
import Automations from "../Pages/Automations/Automations";
import AddCard from "../Pages/AddCard/AddCard";
import BlogPost from "../components/BlogPost/BlogPost";
import CreateAvatar from "../Pages/Avatar/CreateAvatar";
import AllAvatars from "../Pages/Avatar/AllAvatars";
import AvatarChat from "../components/Chat/AvatarChat";
import BlogPostResult from "../components/BlogPost/BlogPostResult";
import TrainAgent from "../components/Rag/TrainAgent";
import AgentChat from "../components/Rag/AgentChat";
import Calendar from "../components/BlogPost/Calendar";
import List from "../components/Notes/List";
import Add from "../components/Notes/Add";
import Show from "../components/Notes/Show";
import SocialCalendar from "../components/Calendar/SocialCalendar";
import Edit from "../components/Notes/Edit";
import PasswordSetup from "../Pages/Auth/PasswordSetup";
import ResetSuccess from "../Pages/Auth/ResetSuccess";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <LandingPage />
            </ProtectedRoute>
          }
        >
          <Route index element={<AgencySide />} />
          <Route path="agents" element={<Agents />} />
          <Route path="userprofile" element={<UserProfile />} />
          <Route path="automations" element={<Automations />} />
          <Route path="blogpost" element={<BlogPost />} />
          <Route path="addcard" element={<AddCard />} />
          <Route path="createavatar" element={<CreateAvatar />} />
          <Route path="avatars" element={<AllAvatars />} />
          <Route path="avatarchat" element={<AvatarChat />} />
          <Route path="result" element={<BlogPostResult />} />
          <Route path="train-agent" element={<TrainAgent />} />
          <Route path="agent-chat" element={<AgentChat />} />
          <Route path="calendar" element={<Calendar />} />
          <Route path="socialcalendar" element={<SocialCalendar />} />
          <Route path="notes" element={<List />} />
          <Route path="add-notes" element={<Add />} />
          <Route path="show-notes/:id" element={<Show />} />
          <Route path="edit-notes/:id" element={<Edit />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<PasswordSetup />} />
        <Route path="/resetsuccess" element={<ResetSuccess />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
