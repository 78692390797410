import React, { useState } from "react";
import axios from "axios"; // Import axios for HTTP requests
import LinearProgress from "@mui/joy/LinearProgress";
import { Container, Header, Title } from "../../components/Agents/Style";
import { RunButton } from "../../components/Sidebar/Style";
import { AvatarDiv, FlexDiv, Input, InputDiv, InputField, Label, SelectField, DropdownMenu, DropdownItem, InputContainer, FileDiv, FileTxt } from "./Style";
import Dropdown from "../../assets/images/dropdown.svg";
import UploadIcon from "../../assets/images/upload-white.svg";
import PdfIcon from "../../assets/images/pdf-icon.svg";
import DocIcon from "../../assets/images/doc-icon.svg";
import TxtIcon from "../../assets/images/txt-icon.svg";
import CloseX from "../../assets/images/x-close.svg";
import { useNavigate } from "react-router-dom";
import SnackbarAlert from "../../components/Alerts/SnackbarAlert";
import CircularProgress from "@mui/material/CircularProgress";
import s3 from "../../components/Environment/Asconfig";

const genders = ["Male", "Female", "Neither"];
const socialMediaOptions = ["Instagram", "LinkedIn", "Pinterest", "Tiktok"];
const searchEngineOptions = ["Google", "Bing", "Ecosia", "ChatGPT", "Other tools"];
const interestsOptions = ["Friendly", "Active", "Adventurous", "Sporty", "Indoors", "Outdoors", "Social", "Extravert", "Introvert", "Individualist"];

const CreateAvatar = () => {
  const [genderOpen, setGenderOpen] = useState(false);
  const [socialMediaOpen, setSocialMediaOpen] = useState(false);
  const [searchEngineOpen, setSearchEngineOpen] = useState(false);
  const [interestsOpen, setInterestsOpen] = useState(false);
  const [selectedGender, setSelectedGender] = useState("");
  const [age, setAge] = useState("");
  const [ageError, setAgeError] = useState("");
  const [selectedSocialMedia, setSelectedSocialMedia] = useState([]);
  const [selectedSearchEngine, setSelectedSearchEngine] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [name, setName] = useState("");
  const [livingArea, setLivingArea] = useState("");
  const [salary, setSalary] = useState("");
  const [livingStatus, setLivingStatus] = useState("");
  const [goalsAndPainPoints, setGoalsAndPainPoints] = useState("");
  const [feelings, setFeelings] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [newFile, setNewFile] = useState(null);
  const navigate = useNavigate();

  const handleGenderSelect = (gender) => {
    setSelectedGender(gender);
    setGenderOpen(false);
  };

  const handleSocialMediaSelect = (media) => {
    setSelectedSocialMedia((prev) =>
      prev.includes(media)
        ? prev.filter((item) => item !== media)
        : [...prev, media]
    );
  };

  const handleSearchEngineSelect = (engine) => {
    setSelectedSearchEngine((prev) =>
      prev.includes(engine)
        ? prev.filter((item) => item !== engine)
        : [...prev, engine]
    );
  };

  const handleInterestsSelect = (interest) => {
    setSelectedInterests((prev) =>
      prev.includes(interest)
        ? prev.filter((item) => item !== interest)
        : [...prev, interest]
    );
  };

  const handleAgeChange = (e) => {
    const value = e.target.value;
    setAge(value);

    if (value <= 0) {
      setAgeError("Age must be greater than 0");
    } else {
      setAgeError("");
    }
  };

  const handleCreateAvatar = async () => {
    const userId = localStorage.getItem("selectedUserId");
    console.log("user", userId);

    if (!name || !selectedGender || !age) {
      setSnackbarMessage("Name, Gender, and Age are required");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    const token = localStorage.getItem("token");
    let uploadedFileData = [];
    try {
      console.log("Inside try");
      const s3Url = await uploadFileToS3(newFile);
      uploadedFileData.push(s3Url);
    } catch (error) {
      console.error(`Error uploading:`, error);
    }
    
    const payload = {
      name,
      client_id: parseInt(userId, 10),
      gender: selectedGender,
      age: parseInt(age, 10), // Convert age to an integer
      living_area: livingArea,
      salary,
      living_status: livingStatus,
      interests: selectedInterests,
      social_medias: selectedSocialMedia,
      search_engines: selectedSearchEngine,
      goals_and_pain_points: goalsAndPainPoints,
      feelings,
      uploaded_urls: uploadedFileData,
    };

    try {
      const response = await axios.post("https://admin.dialect-ai.com/api/createCharacter", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSnackbarMessage(response.data.data.message);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setName("");
      setSelectedGender("");
      setAge("");
      setLivingArea("");
      setSalary("");
      setLivingStatus("");
      setSelectedInterests([]);
      setSelectedSocialMedia([]);
      setSelectedSearchEngine([]);
      setGoalsAndPainPoints("");
      setFeelings("");
      setFile(null);
    } catch (error) {
      setSnackbarMessage("Failed to Create Avatar");
      setSnackbarSeverity("error");
      setSnackbarOpen(true); 
    }
    setLoading(false);
  };

  const getFileIcon = (type) => {
    if (type.includes("pdf")) return PdfIcon;
    if (type.includes("wordprocessingml.document") || type.includes("msword"))
      return DocIcon;
    if (type.includes("plain")) return TxtIcon;
    if (type.includes("doc")) return DocIcon;
    if (type.includes("xlsx")) return TxtIcon;
    return null;
  };

  const uploadFileToS3 = async (file) => {
    console.log(file)
    const selectedUserId = localStorage.getItem("selectedUserId");
    const folderName = `${selectedUserId}/documents`;
    const key = `${folderName}/${file.name}`;
    const params = {
      Bucket: "client-avatars-userdata",
      Key: key,
      Body: file,
    };
    const data = await s3.upload(params).promise();
    console.log("data", data.Location);
    return data.Location;
  };
  
  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0]; // Get the first file uploaded
    setNewFile(uploadedFile);
    console.log(uploadedFile)
    if (uploadedFile) {
      setFile({
        name: uploadedFile.name,
        type: uploadedFile.type,
        progress: 0,
      });
      const uploadInterval = setInterval(() => {
        setFile((prevFile) => {
          if (prevFile.progress >= 100) {
            clearInterval(uploadInterval); // Stop when progress reaches 100
            return prevFile;
          }
          return { ...prevFile, progress: prevFile.progress + 10 }; // Increment progress by 10 each step
        });
      }, 200);
    }
  };

  const removeFile = () => {
    setFile(null); // Remove the file when the close icon is clicked
  };

  return (
    <Container style={{maxHeight: 'calc(100vh - 78px)', overflowY: 'auto'}}>
      <Header>
        <Title style={{marginTop: "auto"}}>Create Avatar</Title>
        <RunButton style={{marginRight: "1.5rem"}} onClick={() => navigate('/avatars')}>Chat</RunButton>
      </Header>
      <AvatarDiv>
        <FlexDiv>
          <InputDiv style={{ flex: "1" }}>
            <Label>Name your persona</Label>
            <InputField value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
          </InputDiv>
          <InputDiv style={{ flex: "1" }}>
            <Label>Gender</Label>
            <SelectField onClick={() => setGenderOpen(!genderOpen)}>
              <Input>{selectedGender || "Select"}</Input>
              <img src={Dropdown} alt="select" style={{ marginLeft: "auto" }} />
              {genderOpen && (
                <DropdownMenu>
                  {genders.map((gender) => (
                    <DropdownItem key={gender} onClick={() => handleGenderSelect(gender)}>
                      {gender}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              )}
            </SelectField>
          </InputDiv>
          <InputDiv style={{ flex: "1" }}>
            <Label>Age</Label>
            <InputField
              type="number"
              value={age}
              onChange={handleAgeChange}
              placeholder="Enter age"
            />
            {ageError && <div style={{ color: "red", marginTop: "5px" }}>{ageError}</div>}
          </InputDiv>
        </FlexDiv>
        <FlexDiv style={{ marginTop: "0.63rem" }}>
          <InputDiv style={{ flex: "1" }}>
            <Label>Living Area</Label>
            <InputField value={livingArea} onChange={(e) => setLivingArea(e.target.value)} placeholder="Living Area" />
          </InputDiv>
          <InputDiv style={{ flex: "1" }}>
            <Label>Salary</Label>
            <InputField value={salary} onChange={(e) => setSalary(e.target.value)} placeholder="Salary" />
          </InputDiv>
          <InputDiv style={{ flex: "1" }}>
            <Label>Living Status</Label>
            <InputField value={livingStatus} onChange={(e) => setLivingStatus(e.target.value)} placeholder="Living Status" />
          </InputDiv>
        </FlexDiv>
        <FlexDiv style={{ marginTop: "1.25rem" }}>
          <InputDiv style={{ flex: "1" }}>
            <Label>Interests (Select One or More)</Label>
            <SelectField onClick={() => setInterestsOpen(!interestsOpen)}>
              <Input>{selectedInterests.length > 0 ? selectedInterests.join(", ") : "Select"}</Input>
              <img src={Dropdown} alt="select" style={{ marginLeft: "auto" }} />
              {interestsOpen && (
                <DropdownMenu>
                  {interestsOptions.map((interest) => (
                    <DropdownItem key={interest} onClick={() => handleInterestsSelect(interest)}>
                      {selectedInterests.includes(interest) ? "✓" : "   "} {interest}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              )}
            </SelectField>
          </InputDiv>
          <InputDiv style={{ flex: "1" }}>
            <Label>Which social media do they use</Label>
            <SelectField onClick={() => setSocialMediaOpen(!socialMediaOpen)}>
              <Input>{selectedSocialMedia.length > 0 ? selectedSocialMedia.join(", ") : "Select"}</Input>
              <img src={Dropdown} alt="select" style={{ marginLeft: "auto" }} />
              {socialMediaOpen && (
                <DropdownMenu>
                  {socialMediaOptions.map((media) => (
                    <DropdownItem key={media} onClick={() => handleSocialMediaSelect(media)}>
                      {selectedSocialMedia.includes(media) ? "✓" : "   "} {media}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              )}
            </SelectField>
          </InputDiv>
        </FlexDiv>
        <FlexDiv style={{ marginTop: "1.25rem" }}>
          <InputDiv style={{ flex: "1" }}>
            <Label>Search engine</Label>
            <SelectField onClick={() => setSearchEngineOpen(!searchEngineOpen)}>
              <Input>{selectedSearchEngine.length > 0 ? selectedSearchEngine.join(", ") : "Select"}</Input>
              <img src={Dropdown} alt="select" style={{ marginLeft: "auto" }} />
              {searchEngineOpen && (
                <DropdownMenu>
                  {searchEngineOptions.map((engine) => (
                    <DropdownItem key={engine} onClick={() => handleSearchEngineSelect(engine)}>
                      {selectedSearchEngine.includes(engine) ? "✓" : "   "} {engine}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              )}
            </SelectField>
          </InputDiv>
        </FlexDiv>
        <FlexDiv style={{ marginTop: "1.25rem" }}>
          <InputDiv style={{ flex: "1" }}>
            <Label>Goals and Pain Points</Label>
            <InputContainer
              type="text"
              name="input1"
              placeholder="Message"
              value={goalsAndPainPoints}
              onChange={(e) => setGoalsAndPainPoints(e.target.value)}
            />
          </InputDiv>
        </FlexDiv>
        <FlexDiv style={{ marginTop: "1.25rem" }}>
          <InputDiv style={{ flex: "1" }}>
            <Label>Overall Feelings</Label>
            <InputContainer
              type="text"
              name="input2"
              placeholder="Message"
              value={feelings}
              onChange={(e) => setFeelings(e.target.value)}
            />
          </InputDiv>
        </FlexDiv>
        <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
          <Label>Upload Files</Label>
          <RunButton
            style={{ marginLeft: "1rem", marginTop: "0rem", padding: "0.65rem 1rem" }}
          >
            <input
              type="file"
              multiple
              style={{ display: "none" }}
              id="file-upload"
              accept=".pdf,.txt,.doc,.docx,.xlsx"
              onChange={handleFileUpload}
            />
            <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
              <img src={UploadIcon} alt="upload" /> Upload
            </label>
          </RunButton>
        </div>
        <FlexDiv style={{ marginTop: "1.25rem" }}>
          <InputDiv style={{ flex: "1", maxHeight: "20rem", overflowY: "auto" }}>
            {file && (
              <FileDiv>
                <img src={getFileIcon(file.type)} alt="file icon" />
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <FileTxt>{file.name}</FileTxt>
                  </div>
                  <div />
                  <LinearProgress
                    determinate
                    value={file.progress}
                    style={{ visibility: file.progress === 100 ? "hidden" : "visible" }}
                  />
                </div>
                <img
                  src={CloseX}
                  alt="Remove"
                  style={{ cursor: "pointer" }}
                  onClick={removeFile}
                />
              </FileDiv>
            )}
          </InputDiv>
        </FlexDiv>
        <FlexDiv>
          <RunButton onClick={handleCreateAvatar}>{!loading ? ("Create Avatar") : (<CircularProgress size={21} color="inherit" />)}</RunButton>
        </FlexDiv>
      </AvatarDiv>
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Container>
  );
};

export default CreateAvatar;
