import React from "react";
import { CardContainer, Title, CardText } from "./Style";
import ArrowRightBlack from "../../assets/images/arrow-right_black.svg";
import styled from "styled-components";

const RotatedImage = styled.img`
  height: 48px;
  width: 48px;
  @media (max-width: 600px) {
    transform: rotate(45deg);
    margin-left: 0rem;
  }
`;

const StaticCard = ({
  cardTitle = "Phillip Davidson",
  cardText = "Lorem ipsum dolor sit amet consectetur Ullamcorper non aseu.",
  image
}) => {
  return (
    <CardContainer>
      <img alt="" src={image} style={{width:'40px', height:'40px', marginLeft:'1.25rem', marginBottom:'1rem'}}/>
      <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
        <div>
        <Title style={{marginLeft:'1.25rem'}}>{cardTitle}</Title>
        <CardText style={{marginLeft:'1.25rem'}}>
          {cardText}
        </CardText>
        </div>
        <RotatedImage src={ArrowRightBlack} alt="train" />
      </div>
    </CardContainer>
  );
};

export default StaticCard;
