import React, { useState, useEffect, createContext, useContext, useRef } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import MobileNavbar from "../../components/MobileNavbar/MobileNavbar";
import { useMediaQuery } from 'react-responsive';
import { Container, Overlay, ProfileDiv, ProfileTxt, Popup, PopupOption } from './Style';
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { LogoDiv, LogoText } from "../../components/MobileNavbar/Style";
import Logo from "../../assets/images/MediaHub.svg";
import AvatarIcon from "../../assets/images/empty-avatar.svg";
import MoreArrow from "../../assets/images/more-arrow.svg";

const UserContext = createContext();

const LandingPage = () => {
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [profileUpdated, setProfileUpdated] = useState(false);
  const [previewImage, setPreviewImage] = useState(AvatarIcon);
  const [popupVisible, setPopupVisible] = useState(false);
  const popupRef = useRef(null);
  const navigate = useNavigate();

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 601px)'
  });

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const handleMoreClick = () => {
    setPopupVisible(!popupVisible);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setPopupVisible(false); // Close the popup
    }
  };

  useEffect(() => {
    if (popupVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Cleanup
    };
  }, [popupVisible]);

  const fetchUsers = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const response = await axios.get(
          "https://admin.dialect-ai.com/api/viewportalusers",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data && response.data.allusers) {
          setUsers(response.data.allusers);
          setSelectedUser(response.data.allusers[0]); 
          localStorage.setItem("selectedUserId", response.data.allusers[0].id); 
          localStorage.setItem("selectedUserEmail", response.data.allusers[0].email); 
          localStorage.setItem("selectedUserName", response.data.allusers[0].name); 
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error("No token found in local storage.");
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedImage = localStorage.getItem("labelImage");
    const storedName = localStorage.getItem("labelName");

    if (storedImage) {
      setPreviewImage(storedImage);
    }

    if (!storedImage) {
      setPreviewImage(AvatarIcon); // Default to AvatarIcon if labelImage is not present
    }

    if (storedName) {
      setProfileUpdated(storedName); // Set labelName in the profile text
    }

    fetchUsers();
  }, []);

  const handleProfileClick = () => {
    navigate("/userprofile");
    setPopupVisible(!popupVisible);
  };

  if (loading) {
    return (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <UserContext.Provider value={{ users, fetchUsers }}>
      <div style={{borderBottom:'1px solid #EDEDED', padding:'1.25rem 1.25rem 1rem 1.25rem'}}>
        <LogoDiv>
          <img src={Logo} alt="Modern Media Hub" style={{width:'3rem'}}/>
          <LogoText>Modern Media Hub</LogoText>
          <ProfileDiv>
            <img src={previewImage} alt="profile" height={26} style={{borderRadius: '50%'}}/>
            <ProfileTxt>{localStorage.getItem("labelName") || "ModernHub"}</ProfileTxt>
            <img src={MoreArrow} alt="more" onClick={handleMoreClick} style={{cursor: 'pointer'}} />
            {popupVisible && (
              <Popup ref={popupRef}>
                <PopupOption onClick={handleProfileClick}>Profile</PopupOption>
              </Popup>
            )}
          </ProfileDiv>
        </LogoDiv>
      </div>
      <Container>
        {isDesktopOrLaptop ? 
          <Sidebar users={users} selectedUser={selectedUser} setSelectedUser={setSelectedUser} profileUpdated={profileUpdated} setProfileUpdated={setProfileUpdated} /> : 
          <MobileNavbar onHamburgerClick={toggleSidebar} />}
        {isSidebarVisible && <Overlay onClick={toggleSidebar}>
          <Sidebar users={users} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
        </Overlay>}
        <Outlet context={{ userCount: users.length, profileUpdated, setProfileUpdated }} />
      </Container>
    </UserContext.Provider>
  );
};

export default LandingPage;

export const useUsers = () => {
  return useContext(UserContext);
};
