import styled, { keyframes } from "styled-components";

export const Content = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  margin: 1.5rem;
  height: 88vh;
  border-radius: 0.625rem;
  border: 1px solid #ebebfd;
  background: #fdfdfd;
  box-shadow: 0px 30px 80px 0px rgba(220, 220, 220, 0.2);

  @media (max-height: 850px) {
    height: 100%;
    min-height: 80vh;
    max-height: 80vh;
  }
`;

export const CenterPane = styled.div`
  display: flex;
  flex-direction: column;
  width: 51.66%;
  padding: 1rem 0rem 1rem 0rem;
`;

export const ScrollableChat = styled.div`
  overflow-y: auto;
  flex: 1;
  scrollbar-width: thin;
`;

export const QuestionTxt = styled.div`
  color: #313133;
  font-family: Poppins;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.25rem; /* 120% */
`;

export const ChatTxt = styled.div`
  color: #40414f;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  margin-bottom: 1.5rem;
`;

export const SubHeading = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  color: #313133;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem; /* 155.556% */
`;

export const InputContainer = styled.div`
  border-radius: 0.5rem;
  border: 1px solid #ebebfd;
  padding: 0.8125rem 0.8125rem 0.8125rem 1.3125rem;
  display: flex;
  align-items: center;
  margin-top: auto;
`;

export const Input = styled.input`
  border: none;
  outline: none;
  color: #9393a4;
  width: 80%;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 125% */
`;

const loading = keyframes`
  0% {
    content: 'Loading';
  }
  33% {
    content: 'Loading.';
  }
  66% {
    content: 'Loading..';
  }
  100% {
    content: 'Loading...';
  }
`;

export const LoadingText = styled.div`
  color: ${({ isClient }) => (isClient ? "#FFF" : "#40414f")};
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
  &:after {
    content: "Loading";
    animation: ${loading} 1.5s infinite;
  }
`;
