import React, { useEffect, useState } from "react";
import AvatarTable from "../../components/Tables/AvatarTable";
import { Container, Header, Title } from "../../components/Agents/Style";
import { TableDiv, LoaderOverlay } from "../../components/BlogPost/Style";
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";

const AllAvatars = () => {
  const [characters, setCharacters] = useState([]);
  const [loading, setLoading] = useState(true); // Manage loading state
  
  const fetchCharacters = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("https://admin.dialect-ai.com/api/getusercharacters", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCharacters(response.data.character);
    } catch (error) {
      console.error("Error fetching characters:", error);
    } finally {
      setLoading(false); // Set loading to false after the request completes
    }
  };

  useEffect(() => {
    fetchCharacters();
  }, []);

  return (
    <Container style={{maxHeight: 'calc(100vh - 78px)', overflowY: 'auto'}}>
      <Header>
        <Title>All Avatars</Title>
      </Header>
      <TableDiv>
        {loading && (
          <LoaderOverlay>
            <CircularProgress />
          </LoaderOverlay>
        )}
        {!loading && <AvatarTable characters={characters} />}
      </TableDiv>
    </Container>
  );
};

export default AllAvatars;
