import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 2000px;
  width: 100%;
  border-radius: 1.5rem;
  background: #fff;
  scrollbar-width: none;
`;

export const Header = styled.div`
  margin-top: 2rem;
  width: 95%;
  display: flex;
  margin-left: 2rem;
  gap: 1.13rem;
  align-items: center;
  @media (max-width: 840px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 760px) {
    margin-top: 1rem;
  }
  @media (max-width: 428px) {
    margin-top: 1rem;
    flex-direction: column;
  }
`;

export const Title = styled.div`
  color: #202020;
  font-family: Poppins;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.5rem; /* 133.333% */
  @media (max-width: 760px) {
    font-size: 1.25rem;
  }
`;

export const TableDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-self: stretch;
  margin-top: 2.04rem;
`;

export const RunButton = styled.div`
  display: inline-flex;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.125rem;
  border-radius: 0.75rem;
  border: 1px solid #dcdbdd;
  background: var(--Brand-White, #fff);
  color: #202020;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  margin-left: auto;
  &:hover {
    background: #d9dce3;
  }
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7); // Optional: light overlay
  z-index: 1;
`;

export const HeaderBtn = styled.div`
  display: inline-flex;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.125rem;
  border-radius: 0.75rem;
  border: 1px solid #dcdbdd;
  background: var(--Brand-White, #fff);
  color: #202020;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  &:hover {
    background: #d9dce3;
  }
`;

export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
  padding: 2rem;
  border-radius: 1rem;
  border: 1px solid var(--Gray-200, #e5e7eb);
  background: var(--Brand-White, #fff);
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
`;

export const Input = styled.div`
  color: #202020;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
`;

//Calendar styling
export const CalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const ArrowButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

export const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 1fr);
  width: 100%;
`;

export const DayBox = styled.div`
  text-align: center;
  font-weight: bold;
  padding: 10px;
  background-color: #f0f0f0;
`;

export const DateBox = styled.div`
  text-align: center;
  padding: 10px;
  overflow: auto;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  height: 10rem; /* Adjust as per your design */
  color: var(--Gray-500, #667085);
  font-family: "Plus Jakarta Sans";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  span {
    font-size: 1rem;
  }
`;

export const DayTxt = styled.div`
  color: var(--Gray-900, #101828);
  font-family: "Plus Jakarta Sans";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  text-transform: uppercase;
`;

export const MeetingItem = styled.div`
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 2px 5px;
  margin: 2px 0;
  color: #000;
  font-family: Poppins;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MeetingTxt = styled.div`
  color: var(--Gray-500, #667085);
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 180% */
`;

export const Ellipsis = styled.div`
  cursor: pointer;
  color: #007bff;
  margin-top: 5px;
  font-size: 12px;
  font-weight: bold;
`;

//Media div styling

export const MediaDiv = styled.div`
  display: flex;
  margin: 1.5rem 1.5rem 0rem 1.5rem;
`;

export const GridContainer = styled.div`
  display: grid;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  grid-template-columns: repeat(4, 0fr);
  @media (max-width: 1650px) {
    grid-template-columns: repeat(4, 0fr);
  }
  @media (max-width: 1160px) {
    grid-template-columns: repeat(3, 0fr);
  }
  @media (max-width: 1160px) {
    grid-template-columns: repeat(3, 0fr);
  }
  @media (max-width: 970px) {
    grid-template-columns: repeat(2, 0fr);
  }
  @media (max-width: 740px) {
    grid-template-columns: repeat(1, 0fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (min-width: 1680px) {
    grid-template-columns: repeat(4, 0fr);
  }
  @media (min-width: 2030px) {
    grid-template-columns: repeat(5, 0fr);
  }
  @media (min-width: 2400px) {
    grid-template-columns: repeat(6, 0fr);
  }
`;

export const MediaCard = styled.div`
  width: 11.875rem;
  height: 9.375rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  cursor: pointer;
  border: 1px solid var(--Gray-200, #e5e7eb);
  background: var(--Brand-White, #fff);
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
`;
