
const formatBlogPostContent = (content) => {
  content = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  content = content.replace(/(\d+)\. \*\*(.*?)\*\*/g, '<li><strong>$2</strong></li>');
  content = content.replace(/##{1,2} (.*?)\n/g, (_, text) => `<h2 style="margin: 0.75rem 0rem;font-size:16px;">${text}</h2>`);
  content = content.replace(/(<li>.*<\/li>)/g, '<ol>$1</ol>');
  content = content.split('\n').map(line => `<p style="margin: 0.5rem 0rem;">${line}</p>`).join('');
  content = content.replace(/#/g, '');
  return content;
};
  
const formatText = (html) => {
  const element = document.createElement("div");
  element.innerHTML = html;
  const traverseNodes = (node) => {
    let text = "";

    node.childNodes.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        text += child.textContent;
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        switch (child.tagName) {
          case "P":
            text += child.textContent + "\n";
            break;
          case "BR":
            text += "\n";
            break;
          case "STRONG":
            text += "**" + child.textContent + "**";
            break;
          case "EM":
            text += "*" + child.textContent + "*";
            break;
          case "UL":
          case "OL":
            text += "\n" + traverseNodes(child) + "\n";
            break;
          case "LI":
            text += "- " + child.textContent + "\n";
            break;
          default:
            text += traverseNodes(child);
        }
      }
    });
    return text;
  };
  return traverseNodes(element).trim();
};

export const handleRichCopy = (
  editorContent,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen
) => {
  const plainText = formatText(formatBlogPostContent(editorContent));
  const htmlContent = formatBlogPostContent(editorContent);

  if (navigator.clipboard && navigator.clipboard.write) {
    const data = new ClipboardItem({
      "text/plain": new Blob([plainText], { type: "text/plain" }),
      "text/html": new Blob([htmlContent], { type: "text/html" }),
    });

    navigator.clipboard
      .write([data])
      .then(() => {
        setSnackbarMessage("Copied Successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("Clipboard write failed: ", error);
        setSnackbarMessage("Failed to copy");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  } else {
    fallbackCopyText(
      plainText,
      htmlContent,
      setSnackbarMessage,
      setSnackbarSeverity,
      setSnackbarOpen
    );
  }
};

export const handlePlainTextCopy = (
  editorContent,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen
) => {
  const plainText = formatText(formatBlogPostContent(editorContent));

  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard
      .writeText(plainText)
      .then(() => {
        setSnackbarMessage("Copied successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("Clipboard write failed: ", error);
        setSnackbarMessage("Failed to copy text");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  } else {
    fallbackCopyText(
      plainText,
      null,
      setSnackbarMessage,
      setSnackbarSeverity,
      setSnackbarOpen
    );
  }
};

const fallbackCopyText = (
  plainText,
  htmlContent,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen
) => {
  const textArea = document.createElement("textarea");
  textArea.value = plainText;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
    setSnackbarMessage("Copied plain text via fallback");
    setSnackbarSeverity("success");
  } catch (err) {
    setSnackbarMessage("Failed to copy text via fallback");
    setSnackbarSeverity("error");
  } finally {
    document.body.removeChild(textArea);
    setSnackbarOpen(true);
  }

  if (htmlContent) {
    const htmlTextArea = document.createElement("textarea");
    htmlTextArea.value = htmlContent;
    document.body.appendChild(htmlTextArea);
    htmlTextArea.focus();
    htmlTextArea.select();

    try {
      document.execCommand("copy");
      setSnackbarMessage("Copied rich text via fallback");
    } catch (htmlErr) {
      console.error("HTML fallback copy failed: ", htmlErr);
    } finally {
      document.body.removeChild(htmlTextArea);
    }
  }
};
