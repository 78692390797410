import styled from "styled-components";

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 1rem;
`;

export const LogoDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.51rem;
`;

export const LogoText = styled.div`
  color: var(--Neutral-100, #101010);
  font-family: Poppins;
 font-size: 1.24263rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 2.25rem */
`;

export const UserDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const UserText = styled.div`
  color: #313133;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.04rem;
`;
