import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import SnackbarAlert from "../../components/Alerts/SnackbarAlert";
import {
  ModalButton,
  InputDiv,
  InputField,
  InputLabel,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "./Style";

const ConnectEmailModal = ({ closeModal, emailData, fetchUserData }) => {
  const [name, setName] = useState("");
  const [smtpUrl, setSmtpUrl] = useState("");
  const [imap, setImap] = useState("");
  const [smtpPort1, setSmtpPort1] = useState("");
  const [smtpPort2, setSmtpPort2] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    if (emailData) {
      setName(emailData.name || "");
      setEmail(emailData.email || "");
      setSmtpUrl(emailData.smtp || "");
      setSmtpPort1(emailData["port-1"] || "");
      setSmtpPort2(emailData["port-2"] || "");
      setImap(null); // Set imap to null as per the requirement
      setPassword(null);
      setIsUpdate(true);
    }
  }, [emailData]);

  const handleSubmit = async () => {
    if (!name || !smtpUrl || !smtpPort1 || !smtpPort2 || !email ) {
      setSnackbarMessage("Fill out empty fields");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setLoading(false);
      return;
    }
    if (!password ) {
      setSnackbarMessage("Please Enter Password");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setLoading(false);
      return;
    }
    setLoading(true);
    const payload = {
      name,
      smtp: smtpUrl,
      imap: imap, 
      port_1: smtpPort1,
      port_2: smtpPort2,
      email,
      password: password
    };
    const token = localStorage.getItem("token");
    try {
      const apiUrl = isUpdate
        ? "https://admin.dialect-ai.com/api/label/email/updateconnectedemails"
        : "https://admin.dialect-ai.com/api/label/email/addconnectedemails";
      
      if (isUpdate) {
        payload.email_connection_id = emailData.id; // Include ID if updating
      }

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setSnackbarMessage("Email updated successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true); // Show snackbar
        setTimeout(() => {
          closeModal();
          fetchUserData();
        }, 1000);
        setLoading(false);
      } 
    } catch (error) {
      setSnackbarMessage("Failed to update Email");
      setSnackbarSeverity("error");
      setSnackbarOpen(true); // Show snackbar
    } finally {
      setLoading(false); 
    }
  };

  return (
    <ModalOverlay>
      <ModalContent style={{maxHeight:"85%", overflowY: "auto"}}>
        <ModalHeader>{isUpdate ? "Update Email" : "Connect your Email"}</ModalHeader>
        <InputDiv>
          <InputLabel>Name</InputLabel>
          <InputField
            placeholder="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <InputLabel>SMTP Url</InputLabel>
          <InputField
            placeholder="smtp.example.com"
            type="text"
            value={smtpUrl}
            onChange={(e) => setSmtpUrl(e.target.value)}
          />
          <InputLabel>Imap</InputLabel>
          <InputField
            placeholder="imap.example.com"
            type="text"
            value={imap}
            onChange={(e) => setImap(e.target.value)}
            disabled={isUpdate} // Disable imap input if updating
          />
          <InputLabel>SMTP Port 1</InputLabel>
          <InputField
            placeholder="Port 1"
            type="text"
            value={smtpPort1}
            onChange={(e) => setSmtpPort1(e.target.value)}
          />
          <InputLabel>SMTP Port 2</InputLabel>
          <InputField
            placeholder="Port 2"
            type="text"
            value={smtpPort2}
            onChange={(e) => setSmtpPort2(e.target.value)}
          />
          <InputLabel>Email</InputLabel>
          <InputField 
            placeholder="john.doe@example.com"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputLabel>Password</InputLabel>
          <InputField 
            placeholder="******"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </InputDiv>
        <div style={{ display: "flex", gap: "0.75rem", alignSelf: "stretch" }}>
          <ModalButton onClick={closeModal} disabled={loading}>Cancel</ModalButton>
          <ModalButton
            style={{ background: "#FF8900", color: "#FFF" }}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={21} color="inherit" />
            ) : (
              isUpdate ? "Update" : "Connect"
            )}
          </ModalButton>
        </div>
      </ModalContent>
      <SnackbarAlert
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </ModalOverlay>
  );
};

export default ConnectEmailModal;
