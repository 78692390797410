import styled from "styled-components";

export const TimezoneContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Label = styled.label`
  font-size: 1rem;
  margin-right: 0.5rem;
  color: #333;
`;

export const TimezoneSelect = styled.select`
  appearance: none;
  background-color: #fff;
  border-radius: 0.75rem;
  border: 1px solid #dcdbdd;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #202020;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  outline: none;
  cursor: pointer;
  transition: border 0.3s ease;
  width: 250px; /* adjust based on preference */
  text-align: center;
  &:hover {
    background: #f2f4f8;
  }
`;

export const Option = styled.option`
  color: #333;
  background-color: #fff;
  justify-content: space-between;
`;

export const HeaderButton = styled.div`
  margin-right: 1rem;
  display: inline-flex;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  border: 1px solid #dcdbdd;
  background: var(--Brand-White, #fff);
  color: #202020;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  &:hover {
    background: #f2f4f8;
  }
`;