import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 2000px;
  width: 100%;
  border-radius: 1.5rem;
  background: #fff;
  margin-bottom: 0.5rem;
  scrollbar-width: none;
`;

export const Header = styled.div`
  margin-top: 2rem;
  display: flex;
  margin-left: 3rem;
  gap: 1.13rem;
  align-items: center;
  @media (max-width: 840px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 760px) {
    margin-top: 1rem;
  }
  @media (max-width: 428px) {
    margin-top: 1rem;
    flex-direction: column;
  }
`;

export const Title = styled.div`
  color: #202020;
  font-family: Poppins;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.5rem; /* 133.333% */
  @media (max-width: 760px) {
    font-size: 1.25rem;
  }
`;

export const SubTitle = styled.div`
  color: #84818a;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 760px) {
    font-size: 0.75rem;
  }
`;

export const AddButton = styled.div`
  display: inline-flex;
  height: 2.5rem;
  padding: 0.4rem 1.125rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border-radius: 6.1875rem;
  background: var(--neutral-900, #171717);
  @media (max-width: 760px) {
    padding: 0.3rem 1rem;
    font-size: 0.75rem;
  }
`;

export const ButtonText = styled.div`
  color: var(--white, #fff);
  font-family: "Plus Jakarta Sans";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
`;

export const AgentsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 3rem;
  gap: 2rem;
  margin-top: 2rem;
  @media (max-width: 760px) {
    margin-top: 1.5rem;
  }
`;

export const SectionTitle = styled.div`
  color: var(--Components-Typography-Global-colorTextHeading, rgba(0, 0, 0, 0.88));
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  grid-template-columns: repeat(4, 0fr);
  @media (max-width: 1670px) {
    grid-template-columns: repeat(3, 0fr);
  }
  @media (max-width: 1350px) {
    grid-template-columns: repeat(2, 0fr);
  }
  @media (max-width: 1020px) {
    grid-template-columns: repeat(1, 0fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (min-width: 1680px) {
    grid-template-columns: repeat(4, 0fr);
  }
  @media (min-width: 2030px) {
    grid-template-columns: repeat(5, 0fr);
  }
  @media (min-width: 2400px) {
    grid-template-columns: repeat(6, 0fr);
  }
`;

export const MobileTitle = styled.div`
  color: #202020;
  font-family: Poppins;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem; /* 127.273% */
  margin-top: 1.81rem;
  display: none;
  @media (max-width: 600px) {
    display: block;
    margin-left: 1rem;
    margin-right: auto;
  }
`;

export const GrayLine = styled.div`
  width: 80%;
  height: 1px;
  background-color: var(--Neutral-30, #EDEDED);
`;

export const AddNotesBtn = styled.div`
  display: flex;
  height: 38px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  background: #FF8900;
  color: var(--Brand-White, #FFF);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  margin-right: 1.5rem;
  &:hover{
    opacity: 0.8;
  }  
`;