import Visa from '../../assets/images/visaCard-icon copy.svg';
import DC from '../../assets/images/DinerClub.svg';
import MasterCard from '../../assets/images/MasterCard copy.svg';
import AE from '../../assets/images/AmericanExpress.svg';
import Discover from '../../assets/images/Discover.svg';
import BcCard from '../../assets/images/BCcard.svg';

export const CardImgs = {
    'visa': Visa,
    'amex': AE,
    'mastercard': MasterCard,
    'dinerclub': DC,
    'discover': Discover,
    'bccard': BcCard
};

export const PORTAL_ID = "11";
