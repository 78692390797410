import React from "react";
import Logo from "../../assets/images/MediaHub.svg";
import Hamburger from "../../assets/images/hamburger.svg";
import { NavbarContainer, LogoDiv, LogoText, UserDiv, UserText } from "./Style";
import UserAvatar from "../../assets/images/user-avatar.svg";
import MoreArrow from "../../assets/images/more-arrow.svg";

const MobileNavbar = ({ onHamburgerClick }) => {
  return (
    <NavbarContainer>
      <LogoDiv>
        <img src={Hamburger} alt="Menu" onClick={onHamburgerClick} />
        <img src={Logo} alt="Agency" style={{width:'3rem'}}/>
        <LogoText>Agency</LogoText>
      </LogoDiv>
      <UserDiv>
        <img src={UserAvatar} alt="user"/>
        <UserText style={{marginLeft: '0.25rem'}}>Kurnia Majid</UserText>
        <img src={MoreArrow} alt="more" style={{marginLeft: '0.5rem'}}/>
      </UserDiv>
    </NavbarContainer>
  );
};

export default MobileNavbar;
