import styled from "styled-components";

export const FileDiv = styled.div`
  display: inline-flex;
  padding: 1rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.375rem;
  border: 1px solid #dcdbdd;
  background: var(--Brand-White, #fff);
`;

export const FileTxt = styled.div`
  color: #84818a;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
//Agent Chat
export const UserChatDiv = styled.div`
  display: flex;
  align-items: flex-start;
  overflow-y: auto;
  justify-content: flex-end;
  & > img {
    margin-left: 0.5rem; /* Add margin to the left of the image */
  }
`;

export const UserChatBox = styled.div`
  border-radius: 0.75rem;
  border: 1px solid rgba(9, 9, 9, 0.1);
  background: var(--Brand-White, #fff);
  display: flex;
  padding: 0.7rem;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

export const ChatText = styled.div`
  color: #40414f;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
`;

export const AgentChatDiv = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;
  overflow-y: auto; /* Make it scrollable */
`;

export const AgentChatBox = styled.div`
  border-radius: 0.75rem;
  border-radius: 0.75rem;
  background: #FF8900;
  display: flex;
  padding: 0.7rem;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
