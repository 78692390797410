import React from 'react';
import { useNavigate } from 'react-router-dom';
import {Container, LoginBox, Title, FormText, SignInButton, ContentDiv} from './Style';
import SuccessIcon from '../../assets/images/success-icon.svg';

export default function ResetSuccess() {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <Container>
        <LoginBox>
            <ContentDiv style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '62%'}}>
                <img src={SuccessIcon} alt="img" />
                <Title style={{marginBottom: '0.63rem', textAlign: 'center'}}>Successful password reset!</Title>
                <FormText style={{textAlign: 'center'}}>You can now use your new password to login into to your account 🙌</FormText>
                <SignInButton style={{width: '80%', marginTop: '2.5rem'}} onClick={handleLoginClick}>Login</SignInButton>
            </ContentDiv>
        </LoginBox> 
    </Container>
  )
}