import React from 'react';
import Chart from 'react-apexcharts';

const AutomationChart = ({ options, series }) => {
  console.log("options", options);
  console.log("series", series);
  return (
    <Chart
      options={options}
      series={series}
      type="area"
      height={350}
    />
  );
};

export default AutomationChart;
