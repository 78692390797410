import styled from "styled-components";

export const AvatarDiv = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  background: #fbfcfc;
  padding: 2rem;
  margin: 1.5rem;
`;

export const FlexDiv = styled.div`
  display: flex;
  gap: 1.25rem;
  width: 100%;
`;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative; /* Added to position dropdown */
`;

export const Label = styled.div`
  color: #202020;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.625rem;
`;

export const InputField = styled.input`
  display: flex;
  padding: 0.625rem;
  color: #84818a;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 0.75rem;
  border: 1px solid #dcdbdd;
  background: var(--Brand-White, #fff);
`;

export const SelectField = styled.div`
  display: flex;
  padding: 0.625rem;
  border-radius: 0.75rem;
  border: 1px solid #dcdbdd;
  background: var(--Brand-White, #fff);
  cursor: pointer;
  &:hover {
    background: #d9dce3;
  }
`;

export const Input = styled.div`
  color: #84818a;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #dcdbdd;
  border-radius: 0.75rem;
  margin-top: 0.25rem;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

export const DropdownItem = styled.div`
  padding: 0.625rem;
  font-family: Poppins;
  font-size: 0.875rem;
  color: #202020;
  cursor: pointer;
    white-space: pre;
  &:hover {
    background: #f1f1f1;
  }
`;

export const InputContainer = styled.textarea`
  height: 6.5rem;
  padding: 0.62rem;
  border-radius: 0.75rem;
  border: 1px solid #dcdbdd;
  background: var(--Brand-White, #fff);
  color: #84818a;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  resize: none;
`;

export const FileTxt = styled.div`
  color: #84818a;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const FileDiv = styled.div`
  display: inline-flex;
  padding: 1rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.375rem;
  border: 1px solid #dcdbdd;
  background: var(--Brand-White, #fff);
`;
