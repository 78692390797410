import React, { useState, useRef, useEffect, useCallback } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { Container, Title, Header, TableDiv, RunButton, LoaderOverlay } from "./Style";
import BlogPostTable from "../../components/Tables/BlogPostTable";
import EyeIcon from "../../assets/images/restart.svg";
import BlogPostSidebar from "../Sidebar/BlogPostSidebar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

const BlogPost = () => {
  const location = useLocation();
  const { workflow_id, cardTitle, cardText, plainText, isSidebarOpen = false } = location.state || {};
  const [isSidebarVisible, setSidebarVisible] = useState(isSidebarOpen);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const { profileUpdated, setProfileUpdated } = useOutletContext();
  const sidebarRef = useRef(null);

  const fetchRecords = useCallback(async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const selectedUserId = localStorage.getItem("selectedUserId");
    const payload = {
      type: workflow_id,
      client_id: selectedUserId,
    };
    try {
      const response = await axios.post(
        `https://admin.dialect-ai.com/api/allclientautomations`, payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //Remove events from automations table records
      const filteredRecords = response.data.records.filter(record => record.workflow_id === workflow_id);
      setRecords(filteredRecords);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching records:", error);
      setLoading(false);
    }
  }, [workflow_id]); 

  useEffect(() => {
    if (workflow_id) {
      fetchRecords(); 
    }
  }, [workflow_id, fetchRecords]); 

  useEffect(() => {
    if (profileUpdated) { 
      fetchRecords(); 
      setProfileUpdated(false); 
    }
  }, [profileUpdated, setProfileUpdated, fetchRecords]); 

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSidebarVisible(false);
    }
  };

  useEffect(() => {
    if (isSidebarVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarVisible]);

  return (
    <Container style={{maxHeight: 'calc(100vh - 78px)', overflowY: 'auto'}}>
      <Header>
        <Title>{cardTitle}</Title>
        <RunButton onClick={() => setSidebarVisible(true)}>
          <img src={EyeIcon} alt="run" />
          {records.length === 0 ? "Run" : "Run Again"}
        </RunButton>
      </Header>
      <TableDiv>
        {loading && (
          <LoaderOverlay>
            <CircularProgress />
          </LoaderOverlay>
        )}
        {!loading && <BlogPostTable records={records} setRecords={setRecords} cardTitle={cardTitle} cardText={cardText}/>}
      </TableDiv>
      {isSidebarVisible && (
        <BlogPostSidebar
          ref={sidebarRef}
          workflow_id={workflow_id}
          fetchRecords={fetchRecords}
          cardTitle={cardTitle} 
          cardText={cardText}
          setSidebarVisible={setSidebarVisible}
          plainText={plainText}
        />
      )}
    </Container>
  );
};

export default BlogPost;
