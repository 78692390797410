import React, { useState } from "react";
import { CardContainer, Title, TrainBtn } from "./Style";
import TrainModal from "../Modals/TrainModal";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const ClientCard = ({
  cardTitle = "Phillip Davidson",
  cardRoute = "/createavatar",
  image,
  isTrained,
  isAgency
}) => {

  
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleTrainClick = () => {
    navigate(cardRoute, { state: {isAgency}});
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
 
  return (
    <CardContainer>
      <img alt="" src={image} style={{width:'40px', height:'40px', marginLeft:'1.25rem', marginBottom:'1rem'}}/>
      <Title style={{ marginLeft: '1.25rem' }}>{cardTitle}</Title>
      <TrainBtn onClick={handleTrainClick} style={{ marginLeft: '1.25rem' }} isTrained={isTrained}>
        {isTrained ? "Trained" : "Train"}
      </TrainBtn>
      {isModalOpen && <TrainModal closeModal={handleCloseModal} agentName={cardTitle}/>}
    </CardContainer>
  );
};

export default ClientCard;
