import React, { useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import DeleteEventModal from "./DeleteEventModal";
import SnackbarAlert from "../Alerts/SnackbarAlert";
import { handleRichCopy, handlePlainTextCopy } from "../utils/CopyHandlers";
import { ModalOverlay, ModalContent, SupportText, MediaDiv, GridContainer, MediaCard, CommentInput, CommentDiv, UserTxt } from "./Style";
import { SelectField, DropdownMenu, DropdownItem } from "../../Pages/Avatar/Style";
import { CardText } from "../AgentsCard/Style";
import { Title } from "../Agents/Style";
import { COPY_OPTIONS } from "../../Pages/Automations/constants";
import CloseX from "../../assets/images/x-close.svg";
import CheckIcon from "../../assets/images/check-circle.svg";
import CancelIcon from "../../assets/images/cancel-circle.svg";
import DeleteIcon from "../../assets/images/delete.svg";
import ArrowUpIcon from "../../assets/images/arrow-up.svg";
import DeleteGrey from "../../assets/images/delete-grey.svg";
import Copy from "../../assets/images/copy-icon.svg";
import { Input } from "../BlogPost/Style";

const makeValidUrl = (url) => {
  return decodeURIComponent(url.replace(/\\\//g, "/"));
};

const MeetingModal = ({ closeModal, event, fetchEvents }) => {
  const [previewMedia, setPreviewMedia] = useState(null);
  const [comments, setComments] = useState(event?.record?.comments || []);
  const [editorContent] = useState(event?.description || "");
  const [newComment, setNewComment] = useState("");
  const [commentLoading, setCommentLoading] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(null);
  const [copyOpen, setCopyOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const labelName = localStorage.getItem("labelName") || "Label User";
  const selectedUserName = localStorage.getItem("selectedUserName") || "User";
  const images = event?.record?.images && event.record.images !== "null" ? JSON.parse(event.record.images).map(makeValidUrl) : [];
  const videoLink = event?.record?.videos_link ? makeValidUrl(event.record.videos_link) : "";

  let statusIcon;
  let statusText;

  if (event.interaction === 1) {
    statusIcon = CheckIcon;
    statusText = "Accepted";
  } else if (event.interaction === 0) {
    statusIcon = CancelIcon;
    statusText = "Declined";
  } else {
    statusIcon = CloseX;
    statusText = "Not responded";
  }

  const formatBlogPostContent = (content) => {
    content = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    content = content.replace(/(\d+)\. \*\*(.*?)\*\*/g, '<li><strong>$2</strong></li>');
    content = content.replace(/##{1,2} (.*?)\n/g, (_, text) => `<h2 style="margin: 0.75rem 0rem;font-size:16px;">${text}</h2>`);
    content = content.replace(/(<li>.*<\/li>)/g, '<ol>$1</ol>');
    content = content.split('\n').map(line => `<p style="margin: 0.5rem 0rem;">${line}</p>`).join('');
    content = content.replace(/#/g, '');
    return content;
  };

  const openPreview = (media) => {
    setPreviewMedia(media);
  };

  const closePreview = () => {
    setPreviewMedia(null);
  };

  const handleAddComment = async (commentId) => {
    setCommentLoading(commentId);
    const token = localStorage.getItem("token");
  
    if (!newComment.trim()) {
      console.log("Comment cannot be empty.");
      return;
    }
  
    try {
      const payload = {
        comment: newComment,
        // Conditionally send automation_id or event_id based on whether workflow_id is present
        ...(event.record.workflow_id ? { automation_id: event.record.id } : { event_id: event.record.id }),
      };
  
      const response = await fetch("https://admin.dialect-ai.com/api/labeladdcomment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
  
      setCommentLoading(false);
  
      if (response.ok) {
        const addedComment = await response.json();
        setComments([...comments, { id: addedComment.id, comment: newComment }]); // Update the comment list with the new comment text
        setNewComment(""); // Clear the input field
        fetchEvents();
      }
    } catch (error) {
      console.error("Error adding comment:", error);
      setCommentLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAddComment();
    }
  };

  const handleDeleteComment = async (commentId) => {
    setDeleteLoading(commentId); // Set the loading state to the id of the comment being deleted
    const token = localStorage.getItem("token");
    try {
      const response = await fetch("https://admin.dialect-ai.com/api/labeldeletecomment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ comment_id: commentId }),
      });
  
      if (response.ok) {
        setComments(comments.filter(comment => comment.id !== commentId)); // Remove the deleted comment from the list
        fetchEvents();
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    } finally {
      setDeleteLoading(null); // Reset the loading state after the request is complete
    }
  };
  
  const openDeleteModal = (user) => {
    setIsDeleteModalOpen(true);
  };
  const closeDeleteModal = () => setIsDeleteModalOpen(false);
  
  const handleSelectCopyOption = (option) => {
    if (option.id === 1) {
      handleRichCopy(editorContent, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen); // First option: Rich Copy
    } else if (option.id === 2) {
      handlePlainTextCopy(editorContent, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen); // Second option: Plain text copy
    }
  };

  return (
    <>
      <ModalOverlay>
        <ModalContent style={{ width: "80%", alignItems: "flex-start", gap: "0rem", padding: "0rem", height: "90%"}}>
          {event.comments === "Holiday" ? (
            <>
              <div style={{ display: "flex", gap: "1rem", padding: "1rem 3% 0rem", width: "94%", justifyContent: "space-between"}} id="holiday-div1">
                <Title style={{ fontWeight: "400", lineHeight: "1.75rem" }}>{event.title}</Title>
                <img src={CloseX} alt="close" style={{ cursor: "pointer" }} onClick={closeModal} />
              </div>
              <div style={{ padding: "0.5rem 3%" }} id="holiday-div2">
                <SupportText style={{ color: "#98A2B3", fontSize: "0.75rem", textAlign: "left" }}>
                  {event.start.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })} - {event.end.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                </SupportText>
              </div>
            </>
          ) : (
            <>
            <div style={{ display: "flex", gap: "1rem", padding: "1rem 3% 0rem", width: "94%", justifyContent: "space-between"}} id="holiday-div1">
              <Title style={{ fontWeight: "400", lineHeight: "1.75rem" }}>{event.title}</Title>
              <div style={{display: "flex", gap: "1rem"}}>
                <SelectField style={{ gap: "0.5rem", position: "relative", alignItems: "center" }} onClick={() =>{ setCopyOpen(!copyOpen)}}>
                  <img src={Copy} alt="copy" style={{cursor: "pointer", height: "1.5rem"}}/>
                  <Input style={{opacity: "0.8"}}>{"Copy"}</Input>
                  {copyOpen && (
                    <DropdownMenu style={{left: "-40px"}}>
                      {COPY_OPTIONS.map((option) => (
                        <DropdownItem key={option.id} onClick={() => handleSelectCopyOption(option)}>
                          {option.option}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  )}
                </SelectField>
                <img src={DeleteGrey} alt="delete automation" style={{ cursor: "pointer" }} onClick={openDeleteModal}/>
                <img src={CloseX} alt="close" style={{ cursor: "pointer" }} onClick={closeModal} />
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", width: "94%", padding: "1.5rem 3%", overflowY: "auto", scrollbarWidth: "thin" }} id="event-div">
              {/* <Title style={{ fontWeight: "500", lineHeight: "1.5rem", fontSize: "1.4rem" }}>{event.name}</Title> */}
              {(images.length > 0 || videoLink) && (
                <MediaDiv>
                  {videoLink && (
                    <MediaCard style={{width: "12rem", height: "8rem"}}>
                      <video controls style={{ width: "100%", height: "100%", borderRadius: "1rem" }}>
                        <source src={videoLink} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </MediaCard>
                  )}
                  <GridContainer>
                    {images.map((imageUrl, index) => (
                      <MediaCard key={index} onClick={() => openPreview({ type: "image", url: imageUrl })}>
                        <img src={imageUrl} alt={`media-${index}`} style={{ width: "100%", height: "100%", borderRadius: "1rem" }} />
                      </MediaCard>
                    ))}
                  </GridContainer>
                </MediaDiv>
              )}
              <SupportText
                style={{ color: "#667085", fontSize: "1.2rem", textAlign: "left"}}
                dangerouslySetInnerHTML={{ __html: formatBlogPostContent(editorContent) }}
              ></SupportText>
            </div>
            <div style={{ display: "flex", flexDirection: "column", width: "94%", padding: "0rem 3% 1.5rem 3%", marginTop: "auto"}}>
              <div style={{ display: "flex", gap: '0.375rem', margin:'1rem 0rem', position: "relative", width: "100%"}}>
                <CommentInput
                  type="text"
                  placeholder="Add a comment"
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  onKeyDown={handleKeyDown} // Trigger comment addition on Enter
                />
                {commentLoading ? (
                  <CircularProgress size={28} style={{ marginLeft: "auto" }} />
                ) : (
                  <img src={ArrowUpIcon} alt="add comment" style={{ width: "2rem", marginLeft: "auto", cursor: "pointer"}} onClick={handleAddComment} />
                )}
              </div>
              <div style={{display: "flex", flexDirection:"column", maxHeight: comments.length > 2 ? "9rem" : "", overflowY: "auto", scrollbarWidth: "thin"}}>
                {comments.length > 0 && comments.map(comment => (
                  <CommentDiv key={comment.id}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                      <UserTxt>{comment.added_by === "label" ? labelName : selectedUserName}</UserTxt>
                      <CardText style={{marginTop: "0rem"}}>{comment.comment}</CardText>
                    </div>
                    {deleteLoading === comment.id ? ( <CircularProgress size={24} style={{ marginLeft: "auto" }} /> ) : (<img src={DeleteIcon} alt="delete" style={{ marginLeft: "auto", cursor: "pointer" }} onClick={() => handleDeleteComment(comment.id)}/> )}
                  </CommentDiv>
                ))}
              </div>
              <div style={{ display: "flex", gap: "0.37rem", marginTop: "0.5rem" }}>
                <img src={statusIcon} alt="status" />
                <div style={{ display: "flex", flexDirection: "column", marginTop: "0.25rem" }}>
                  <CardText style={{ lineHeight: "0.25rem" }}>{event.userEmail}</CardText>
                  <SupportText style={{ color: "#98A2B3", fontSize: "0.75rem", textAlign: "left" }}>{statusText}</SupportText>
                </div>
              </div>
            </div>
            </>
          )}
        </ModalContent>
        <SnackbarAlert
          open={snackbarOpen}
          setOpen={setSnackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </ModalOverlay>
      {isDeleteModalOpen && (
        <DeleteEventModal closeDeleteModal={closeDeleteModal} closeModal={closeModal} event={event} fetchEvents={fetchEvents}/>
      )}
      {previewMedia && (
        <ModalOverlay onClick={closePreview}>
          <ModalContent style={{ width: "800px", padding: "1rem" }}>
            {previewMedia.type === "image" && (
              <img src={previewMedia.url} alt="preview" style={{ width: "100%", height: "auto", borderRadius: "1rem" }} />
            )}
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default MeetingModal;
