import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 2000px;
  width: 100%;
  border-radius: 1.5rem;
  background: #fff;
  margin-bottom: 0.5rem;
  margin-left: 1.5rem;
  scrollbar-width: none;
`;

export const TextDiv = styled.div`
  color: var(--Components-Typography-Global-colorTextHeading, rgba(0, 0, 0, 0.88));
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
`;

export const GridContainer = styled.div`
  display: grid;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 2rem;
  grid-template-columns: repeat(4, 0fr);
  @media (max-width: 1320px) {
    grid-template-columns: repeat(3, 0fr);
  }
  @media (max-width: 1060px) {
    grid-template-columns: repeat(2, 0fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 0fr);
  }
  @media (min-width: 1680px) {
    grid-template-columns: repeat(4, 0fr);
  }
  @media (min-width: 2030px) {
    grid-template-columns: repeat(5, 0fr);
  }
  @media (min-width: 2400px) {
    grid-template-columns: repeat(6, 0fr);
  }
`;

export const ChartDiv = styled.div`
display: flex;
flex-direction: column;
padding: 1.25rem;
border-radius: 0.5625rem;
border: 1px solid #EDEDED;
background: var(--Brand-White, #FFF);
box-shadow: 0px 3px 20.1px 0px rgba(0, 0, 0, 0.06);
`