import React, { useState } from "react";
import axios from "axios";
import SnackbarAlert from "../Alerts/SnackbarAlert";
import CircularProgress from '@mui/material/CircularProgress';
import { ModalContent, ModalOverlay, SettingInput, SubTitle, Title } from "./Style";
import { DateInput, FileDiv, FileTxt, InputDiv, RunButton } from "../Sidebar/Style";
import s3 from "../../components/Environment/Asconfig";
import CloseX from "../../assets/images/x-close.svg";
import UploadIcon from "../../assets/images/upload-white.svg";
import FileIcon from "../../assets/images/file.svg";
import DynamicReactQuill from "../RichText/DynamicReactQuill";
import { BLOG_POST_GENERATION } from "../../Pages/Automations/constants";
const AddEventModal = ({ closeAddEventModal, fetchEvents }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [publishDate, setPublishDate] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState(""); // This will store rich text content
  const [loading, setLoading] = useState(false);

  const handleDateBlur = (e) => {
    const enteredDate = e.target.value;
    const today = new Date().toISOString().split("T")[0];
    
    if (/^\d{4}-\d{2}-\d{2}$/.test(enteredDate)) {
      const year = parseInt(enteredDate.split("-")[0], 10);
      if (year < 1000 || year > 9999) {
        setSnackbarMessage("Please enter a valid year with four digits");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setPublishDate(""); 
        e.target.value = "";
      } else if (enteredDate < today) {
        setSnackbarMessage("Enter a date from today onward");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setPublishDate(""); 
        e.target.value = "";
      } else {
        setPublishDate(enteredDate); 
      }
    } else {
      setSnackbarMessage("Invalid Date");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setPublishDate(""); 
      e.target.value = "";
    }
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.filter((file) => file.type.startsWith("image/"));
    setUploadedImages([...uploadedImages, ...newImages]);
  };

  const handleRemoveImage = (index) => {
    const newImages = uploadedImages.filter((_, i) => i !== index);
    setUploadedImages(newImages);
  };

  const uploadFileToS3 = async (file) => {
    const selectedUserId = localStorage.getItem("selectedUserId");
    const folderName = `${selectedUserId}/documents`;
    const key = `${folderName}/${file.name}`;
    const params = {
      Bucket: "dialect-marketing-userdata",
      Key: key,
      Body: file,
    };
    const data = await s3.upload(params).promise();
    return data.Location;
  };

  const handleAddEvent = async () => {
    if (!title) {
      setSnackbarMessage("Event Title is required");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    const selectedUserId = localStorage.getItem("selectedUserId");
    const token = localStorage.getItem("token");

    const payload = {
      client_id: selectedUserId,
      title,
      description, // Using rich text editor content
      publish_date: `${publishDate} 00:00:00`,
      images: [],
      event_type: "Label Event",
      type: BLOG_POST_GENERATION,
    };

    if (uploadedImages.length > 0) {
      const imageLinks = [];
      for (const file of uploadedImages) {
        try {
          const s3Url = await uploadFileToS3(file); // Upload each image
          imageLinks.push(s3Url); // Push the S3 URL to the array
        } catch (error) {
          console.error("Error uploading image:", file.name, error);
        }
      }
      payload.images = imageLinks; // Add array of image URLs to payload 
    }

    try {
      const response = await axios.post("https://admin.dialect-ai.com/api/createevent", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response", response);
      setLoading(false);
      setSnackbarMessage("Event created successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      fetchEvents();
      setTimeout(() => {
        closeAddEventModal();
      }, 1000);
    } catch (error) {
      setLoading(false);
      setSnackbarMessage("Failed to create event");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <ModalOverlay>
      <ModalContent style={{ width: "40rem", padding: "1.5rem", gap: "0rem", height: "90%"}}>
        <div style={{ display: "flex", width: "100%" }}>
          <Title>Add Event</Title>
          <img src={CloseX} alt="close" style={{ marginLeft: "auto", cursor:'pointer' }} onClick={closeAddEventModal} />
        </div>

        {/* Event Title */}
        <div style={{ display: "flex", flexDirection: "column", gap: '0.375rem', width: '100%', marginTop:'0.75rem'}}>
          <SubTitle style={{ lineHeight: "1.5rem", fontSize: "0.875rem" }}>Event Title</SubTitle>
          <SettingInput type="text" placeholder="Enter Title" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>

        {/* Event Description with Rich Text Editor */}
        <div style={{ display: "flex", flexDirection: "column", gap: '0.375rem', width: '100%', marginTop:'0.75rem'}}>
          <SubTitle style={{ lineHeight: "1.5rem", fontSize: "0.875rem" }}>Event Description</SubTitle>
          <DynamicReactQuill 
            value={description} 
            setValue={setDescription} 
            placeholder="Enter Description" 
            height="200px" 
          />
        </div>

        {/* Publish Date */}
        <div style={{ display: "flex", flexDirection: "column", gap: '0.375rem', width: '100%', marginTop:'3rem'}}>
          <SubTitle style={{ lineHeight: "1.5rem", fontSize: "0.875rem" }}>Publish Date</SubTitle>
          <DateInput type="text" placeholder="Set Publish Date" onChange={(e) => setPublishDate(e.target.value)} onBlur={handleDateBlur} onFocus={(e) => (e.target.type = "date")} min={new Date().toISOString().split("T")[0]} />
        </div>

        {/* Upload Images */}
        <div style={{ display: "flex", flexDirection: "column", gap: '0.375rem', width: '100%', marginTop:'1rem'}}>
          <div style={{ display: "flex", alignItems: "center"}}>
            <SubTitle style={{ lineHeight: "1.5rem", fontSize: "0.875rem" }}>Upload Images</SubTitle>
            <RunButton as="label" htmlFor="imageUpload" style={{ marginLeft: "1rem", marginTop: "0rem", padding: "0.5rem", cursor: 'pointer' }}>
              <img src={UploadIcon} alt="upload" />
              Upload
              <input type="file" id="imageUpload" style={{ display: 'none' }} accept="image/*" multiple onChange={handleImageUpload} />
            </RunButton>
          </div>
        </div>

        {/* Display Uploaded Images */}
        <InputDiv style={{width: "100%", marginTop:'1rem', marginBottom: "0.75rem", overflowY: "auto", scrollbarWidth: "thin"}}>
          {uploadedImages.map((file, index) => (
            <FileDiv key={index}>
              <img src={FileIcon} style={{height: "1.75rem"}} alt="file" />
              <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <FileTxt>{file.name}</FileTxt>
                  <img src={CloseX} alt="Remove" style={{ cursor: "pointer" }} onClick={() => handleRemoveImage(index)} />
                </div>
              </div>
            </FileDiv>
          ))}
        </InputDiv>
        <RunButton onClick={handleAddEvent} style={{marginTop: "auto"}}>
          {loading ? <CircularProgress size={24} /> : 'Add Event'}
        </RunButton>
      </ModalContent>
      <SnackbarAlert open={snackbarOpen} setOpen={setSnackbarOpen} message={snackbarMessage} severity={snackbarSeverity} />
    </ModalOverlay>
  );
};

export default AddEventModal;
