import React, { useEffect, useState } from "react";
import {
  AgentsDiv,
  Container,
  GridContainer,
  Header,
  Title,
} from "../../components/Agents/Style";
import AutomationCard from "../../components/AgentsCard/AutomationCard";
import CircularProgress from "@mui/material/CircularProgress";
import { mapAutomationData } from "./constants";

const Automations = () => {
  const [automations, setAutomations] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchAutomations = async (userId, setAutomations, setLoading) => {
    const token = localStorage.getItem("token"); // Get token from localStorage
    try {
      const response = await fetch(
        "https://admin.dialect-ai.com/api/labelgeneralworkflows",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Authorization header with Bearer token
          },
        }
      );
      const data = await response.json();
      const mappedAutomations = data.general_workflows
        .map(mapAutomationData)
        .filter(Boolean); // Filter out null values
      setAutomations(mappedAutomations);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching automations:", error);
      setLoading(false); // Hide the loader in case of an error
    }
  };

  useEffect(() => {
    const selectedUserId = localStorage.getItem("selectedUserId");
    if (selectedUserId) {
      fetchAutomations(selectedUserId, setAutomations, setLoading);
    }
  }, []);

  return (
    <Container style={{ maxHeight: "calc(100vh - 78px)", overflowY: "auto" }}>
      <Header>
        <Title>Automations</Title>
      </Header>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", }}>
          <CircularProgress />
        </div>
      ) : (
        <AgentsDiv>
          <GridContainer>
            {automations.map((automation, index) => (
              <AutomationCard
                key={index}
                workflow_id={automation.type} // Use the type as workflow_id
                cardTitle={automation.cardTitle}
                cardText={automation.cardText}
                image={automation.image}
                type={automation.type}
                tag={automation.tag}
                tagImage={automation.tagImage}
              />
            ))}
          </GridContainer>
        </AgentsDiv>
      )}
    </Container>
  );
};

export default Automations;
