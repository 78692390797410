import React, { useState, useEffect } from 'react';
import {
  AddNotesBtn,
  Container,
  Header,
  Title,
} from "../../components/Agents/Style";
import { GridContainer } from './Style';
import NotesCard from './NotesCard';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress";
import EmptyIcon from '../../assets/images/EmptyState.svg'

const List = () => {
  const navigate = useNavigate();
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNotes = async () => {
      const user_id = localStorage.getItem("selectedUserId"); // Assuming user_id is stored in localStorage

      try {
        const response = await axios.post('https://admin.dialect-ai.com/api/labelgetnotes', {
          user_id: user_id,
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,  // Authorization token
          },
        });
        setNotes(response.data.usernotes); // Assuming the response data contains the notes
      } catch (error) {
        console.error('Error fetching notes:', error);
      } finally {
        setLoading(false); // Stop loading when data is fetched
      }
    };

    fetchNotes();
  }, []);

  return (
    <Container style={{ maxHeight: "calc(100vh - 78px)", overflowY: "auto" }}>
      <Header style={{ justifyContent: 'space-between' }}>
        <Title>Client Notes</Title>
        <AddNotesBtn onClick={() => navigate('/add-notes')}>
          + Add New Note
        </AddNotesBtn>
      </Header>
      
      <Header>
        <GridContainer style={{justifyContent:loading || notes.length<1?'center':''}}>
          {loading ? (
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%', marginTop:'5rem'}}>
              <CircularProgress /> 
            </div>
          ) : (
            notes.length > 0 ? (
              notes.map((note, index) => (
                <NotesCard 
                  key={index}
                  id={note.id}
                  heading={note.title}  
                  desc={note.data}      
                />
              ))
            ) : (
              <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', marginTop:"50%"}}>
                <img src={EmptyIcon} alt='' />
                <Title style={{width:'163px', fontSize:'1.25rem'}}>No Notes Added</Title>
              </div>
            )
          )}
        </GridContainer>
      </Header>
    </Container>
  );
};

export default List;
